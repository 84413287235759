import { html } from "lit";
import { customElement, queryAssignedElements, state } from "lit/decorators.js";

import FormControl, { FormControlProps } from "@/components/form/form-control";
import AtlasToggleItem from "@/components/form/atlas-toggle-item/atlas-toggle-item";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";

import styles from "./atlas-toggle.scss";

export type ToggleProps = FormControlProps;

/**
 * @tag atlas-toggle
 */
@customElement("atlas-toggle")
export default class AtlasToggle extends FormControl {
    static styles = styles;

    @state() selectedItem: AtlasToggleItem;

    @queryAssignedElements({ selector: "atlas-toggle-item", flatten: true })
    public toggleItems!: Array<AtlasToggleItem>;

    constructor() {
        super();

        this.toggleActiveItem = this.toggleActiveItem.bind(this);
    }

    connectedCallback() {
        super.connectedCallback?.();

        this.addEventListener("atlas-toggle-item-check", this.toggleActiveItem);

        this.updateComplete.then(() => {
            if (this.value) this.syncValue();
            else this.findSelectedItem();

            if (this.disabled) this.syncDisabled();
        });
    }

    disconnectedCallback() {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-toggle-item-check", this.toggleActiveItem);
    }

    async getUpdateComplete() {
        await super.getUpdateComplete();
        await Promise.all(this.toggleItems.map((element) => element.updateComplete));
        return true;
    }

    toggleActiveItem(event: CustomEvent) {
        this.setSelectedItem(event.target as AtlasToggleItem);
    }

    setSelectedItem(selectedItem: AtlasToggleItem) {
        this.selectedItem = selectedItem;
        this.value = selectedItem.value;

        if (selectedItem.helperText) this.helperText = selectedItem.helperText;
    }

    findSelectedItem() {
        this.toggleItems.forEach((item) => {
            if (item.checked) this.setSelectedItem(item);
        });
    }

    @Watch("value", true)
    syncValue() {
        this.toggleItems.forEach((item) => {
            if (this.value === item.value) {
                item.check();
            } else {
                item.uncheck();
            }
        });

        emit(this, "atlas-toggle-change");
        emit(this, "atlas-form-element-touch");
    }

    @Watch("disabled", true)
    syncDisabled() {
        this.toggleItems.forEach((item) => {
            item.toggleAttribute("disabled", this.disabled);
        });
    }

    render() {
        return html`
            <div class="atlas-toggle">
                <div class="btn-group">
                    <slot></slot>
                </div>
                ${this.renderHelperText(true)}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-toggle": AtlasToggle;
    }
}
