import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import { emit } from "@/internals/events";
import { SelectOption } from "./types";

import styles from "./atlas-select-tags.scss";
import "@/components/display/atlas-tag/atlas-tag";

/**
 * @dependency atlas-tag
 *
 * @prop {boolean} multiple - Indica se o select permite vários valores
 * @prop {SelectOption[]} selectedOptions - As opções que já foram selecionadas
 *
 * @event {CustomEvent} atlas-select-tags-remove - Evento disparado ao clicar no remover de uma tag
 *
 * @tag atlas-select-tags
 */
@customElement("atlas-select-tags")
export default class AtlasSelectTags extends LitElement {
    static styles = styles;

    @property({ type: Boolean }) multiple: boolean;

    @property({ type: Array }) selectedOptions: SelectOption[] = [];

    onTagRemoveClick(event: CustomEvent) {
        const { optionValue } = (event.target as HTMLElement).dataset;

        emit(this, "atlas-select-tags-remove", {
            detail: { option: optionValue }
        });
    }

    renderTags() {
        return this.selectedOptions.map(
            (option) => html`
                <atlas-tag
                    text=${option.label}
                    data-option-value=${option.value}
                    @atlas-tag-close-click=${this.onTagRemoveClick}
                ></atlas-tag>
            `
        );
    }

    render() {
        return when(
            this.multiple && this.selectedOptions.length > 0,
            () => html`<div class="tags-container">${this.renderTags()}</div>`
        );
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-select-tags": AtlasSelectTags;
    }
}
