import { html } from "lit";
import { property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";

import FieldValidator from "@/internals/validators/field-validator";
import RequiredValidator from "@/internals/validators/required-validator";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";

type FormElementStatus = "none" | "info" | "success" | "warning" | "error";

export type FormElementProps = AtlasElementProps & {
    "id": string;
    "name": string;
    "value": string;
    "disabled": boolean;
    "required": boolean;
    "default-value": string;
    "default": boolean;
    "required-error-message": string;
};

/**
 * Classe base para definir elementos de um formulário
 *
 * @prop {string} name - O nome do elemento
 * @prop {string} value - O valor do elemento
 * @prop {string} required-error-message - Mensagem que é exibida quando o campo é requerido mas não foi preenchido
 * @prop {boolean} disabled - Indica se o elemento está desabilitado
 * @prop {boolean} required - Indica se o elemento é obrigatório
 * @prop {string} default-value - É o valor padrão do elemento no contexto de filtro
 * @prop {boolean} default - Coloca o elemento como seleção padrão no contexto de filtro
 *
 */
export default class FormElement extends AtlasElement {
    @property({ type: String }) name = "";

    @property({ type: String }) value = "";

    @property({ type: String, attribute: "required-error-message" }) requiredErrorMessage = "";

    @property({ type: Boolean, reflect: true }) required = false;

    @property({ type: Boolean, reflect: true }) disabled = false;

    @property({ type: Boolean }) default: boolean;

    @property({ type: String, attribute: "default-value" }) defaultValue = "";

    @property({ type: Boolean, attribute: "ignore-validations" }) ignoreValidations = false;

    @state() _status: FormElementStatus = "none";

    @state() _showStatusMessage = false;

    @state() _statusMessage = "";

    @state() _valid = true;

    @state() _validationObject: { [key: string]: boolean } = {};

    @state() _validationMessage = "";

    @state() _showValidationState = false;

    private _validators: FieldValidator[] = [];

    connectedCallback(): void {
        super.connectedCallback?.();

        this.addValidator(new RequiredValidator(this.requiredErrorMessage));

        this.updateComplete.then(() => {
            this.checkValidity();
        });
    }

    checkValidity(): boolean {
        if (this.ignoreValidations) return true;

        let hasError = false;

        if (this._status === "error") {
            this.removeStatusMessage();
        }

        if (!this.disabled) {
            for (const validator of this._validators) {
                const isValid = validator.validate(this);

                if (!isValid) {
                    this._validationObject[validator.name] = !isValid;

                    if (!hasError) {
                        hasError = true;
                        this._valid = validator.status !== "error";
                        this._statusMessage = validator.getInvalidMessage();
                        this._status = validator.status;
                    }

                    if (validator.reportOnChange) {
                        this._showStatusMessage = true;
                    }
                }
            }
        }

        return this._valid;
    }

    reportValidity(): boolean {
        if (this.ignoreValidations) return true;

        this.checkValidity();

        this._showStatusMessage = this._status !== "none";

        return this._valid;
    }

    getValidationsReport() {
        return this._validationObject;
    }

    setErrorMessage(message: string) {
        this.setStatusWithMessage("error", message);
    }

    setWarningMessage(message: string) {
        this.setStatusWithMessage("warning", message);
    }

    setSuccessMessage(message: string) {
        this.setStatusWithMessage("success", message);
    }

    setInfoMessage(message: string) {
        this.setStatusWithMessage("info", message);
    }

    removeStatusMessage() {
        this._status = "none";
        this._statusMessage = "";
        this._validationObject = {};
        this._showStatusMessage = false;
        this._valid = true;
    }

    setStatusWithMessage(status: FormElementStatus, message: string) {
        this._status = status;
        this._statusMessage = message;
        this._showStatusMessage = true;
        this._valid = status !== "error";
    }

    setValidationState(validationState: boolean, status?: FormElementStatus) {
        if (!status) {
            this._status = validationState ? "none" : "error";
        } else {
            this._status = status;
        }

        this._valid = validationState;
        this._showStatusMessage = !validationState;
    }

    addValidator(validator: FieldValidator): void {
        this._validators.push(validator);
    }

    getValidator(name: string) {
        return this._validators.find((validator) => validator.name === name);
    }

    removeValidator(name: string): void {
        this._validators = this._validators.filter((validator) => validator.name !== name);
    }

    getShowStatus() {
        return this._status && this._status !== "none" && this._showStatusMessage;
    }

    enable(): void {
        this.disabled = false;
    }

    disable(): void {
        this.disabled = true;
    }

    toggleDisabled(forceState?: boolean): void {
        if (typeof forceState === "boolean") {
            this.disabled = forceState;
        } else {
            this.disabled = !this.disabled;
        }
    }

    getElementValue(): any {
        return this.value;
    }

    @Watch("value", true)
    onChange() {
        this.checkValidity();

        emit(this, "atlas-form-element-value-change");
        emit(this, "atlas-form-element-touch");
    }

    @Watch("disabled", true)
    onChangeDisabled() {
        if (this.disabled) this.removeStatusMessage();

        emit(this, "atlas-form-element-disabled-change");
    }

    @Watch("required", true)
    onChangeRequired() {
        emit(this, "atlas-form-element-required-change");
    }

    renderValidationMessage() {
        return when(
            this._showValidationState && this._validationMessage !== "",
            () => html`<div class="invalid-feedback">${this._validationMessage}</div>`
        );
    }

    renderStatusMessage() {
        const statusClass = {
            "status-feedback": true,
            [`status-${this._status}`]: !!this._status
        };

        return when(
            this.getShowStatus() && this._statusMessage !== "",
            () => html`<div class=${classMap(statusClass)}>${this._statusMessage}</div>`
        );
    }
}
