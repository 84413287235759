import { html } from "lit";
import { customElement, property, queryAsync } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import { Theme } from "@/internals/theme";
import { emit } from "@/internals/events";
import DeviceController from "@/controllers/device-controller";
import type AtlasButtonGroup from "@/components/display/atlas-button-group/atlas-button-group";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-table-row.scss";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/form/atlas-checkbox/atlas-checkbox";
import "@/components/table/atlas-table-col/atlas-table-col";

export type TableRowProps = AtlasElementProps & {
    "href": string;
    "has-actions": boolean;
    "selectable": boolean;
    "selected": boolean;
};

/**
 * @dependency atlas-icon
 * @dependency atlas-checkbox
 * @dependency atlas-table-col
 *
 * @prop {string} href - Link da página ao qual o usuário será redirecionado ao clicar sobre a linha
 * @prop {boolean} has-actions - Indica se a linha tem ações
 * @prop {boolean} selectable - Indica se a linha permite múltipla seleção
 * @prop {boolean} selected - Indica se a linha está selecionada
 *
 * @tag atlas-table-row
 */
@customElement("atlas-table-row")
export default class AtlasTableRow extends AtlasElement {
    static styles = styles;

    @property({ type: String }) theme: Theme = "primary";

    @property({ type: String }) href: string;

    @property({ type: Boolean, attribute: "has-actions" }) hasActions = false;

    @property({ type: Boolean }) selectable = false;

    @property({ type: Boolean }) selected = false;

    @queryAsync("slot:not([name])") private _defaultSlot: HTMLSlotElement;

    private _deviceController = new DeviceController(this);

    connectedCallback(): void {
        super.connectedCallback?.();

        this.setPropsOnButtonGroup = this.setPropsOnButtonGroup.bind(this);
        this.onActionClick = this.onActionClick.bind(this);

        this._deviceController.setScreenChangeCallback(this.setPropsOnButtonGroup);
        this.addEventListener("atlas-button-group-button-click", this.onActionClick);
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-button-group-button-click", this.onActionClick);
    }

    async getSlottedCols() {
        return (await this._defaultSlot)?.assignedElements({ flatten: true }) || [];
    }

    getSlottedButtonGroup() {
        const actionsSlot = this.shadowRoot.querySelector("slot[name=actions]") as HTMLSlotElement;
        const slottedButtonGroup = actionsSlot?.assignedElements()[0] as AtlasButtonGroup;

        return slottedButtonGroup;
    }

    toggleSelection(selected: boolean) {
        this.selected = selected;
    }

    onClickSelect() {
        setTimeout(() => {
            this.selected = !this.selected;
            emit(this, "atlas-table-row-select");
        }, 0);
    }

    onActionClick(event: CustomEvent) {
        emit(this, "atlas-table-action-click", {
            detail: {
                row: this,
                ...event.detail
            }
        });
    }

    async setPropsOnButtonGroup() {
        await this.updateComplete;
        const slottedButtonGroup = this.getSlottedButtonGroup();

        if (!slottedButtonGroup) return;

        slottedButtonGroup.setAttribute("more-button-type", "icon");
        slottedButtonGroup.setAttribute("more-button-theme", this.theme);
        slottedButtonGroup.setAttribute("more-button-size", this._deviceController.isMobile ? "3x" : "2x");
    }

    renderSelectColumn() {
        return when(
            this.selectable,
            () => html`
                <atlas-table-col is-selection-column @atlas-table-col-click=${this.onClickSelect}>
                    <atlas-checkbox ?checked=${this.selected}></atlas-checkbox>
                </atlas-table-col>
            `
        );
    }

    renderActionsColumn() {
        return when(
            this.hasActions,
            () => html`
                <atlas-table-col is-action-column>
                    <slot name="actions" @slotchange=${this.setPropsOnButtonGroup}></slot>
                </atlas-table-col>
            `
        );
    }

    render() {
        const rowClass = {
            "atlas-table-row": true,
            "selected": this.selected,
            "selectable": this.selectable,
            "has-actions": this.hasActions,
            "has-link": this.href,
            [`theme-${this.theme}`]: !!this.theme
        };

        return html`
            <a class=${classMap(rowClass)} href=${ifDefined(this.href)}>
                ${this.renderSelectColumn()}
                <slot></slot>
                ${this.renderActionsColumn()}
            </a>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-table-row": AtlasTableRow;
    }
}
