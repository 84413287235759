export const stringToNumber = (value: any) => {
    if (typeof value === "number") return value;
    if (typeof value !== "string") return 0;
    if (value === undefined || value === null) return 0;

    let stringValue = value;

    if (stringValue.indexOf("R$ ") >= 0) {
        stringValue = stringValue.replace("R$ ", "");
    }
    if (stringValue.indexOf(",") >= 0) {
        stringValue = stringValue.replace(/\./g, "").replace(/,/g, ".");
    }

    const valueNumber = Number.parseFloat(stringValue);

    return Number.isNaN(valueNumber) ? 0 : valueNumber;
};

export const removeNonNumeric = (value: string): string => value.replace(/\D/g, "");

export const formatMoney = (value: string | number): string => {
    const decimalPlace = 2;

    const valueAsString = value.toString();
    const valueAsNumber = Number(value);

    const fullNumber = parseInt(valueAsString, 10);

    let fixedNumber: string;

    if (Number.isNaN(valueAsNumber)) {
        fixedNumber = (0).toFixed(decimalPlace);
    } else {
        fixedNumber = valueAsNumber.toFixed(decimalPlace);
    }

    const decimalPoint = ",";
    const numberDivisor = ".";
    const prefix = fullNumber < 0 ? "-" : "";
    const numberWithoutDecimalPlace = parseInt(fixedNumber, 10).toString();

    const firstPartDecimalPlaces = numberWithoutDecimalPlace.length > 3 ? numberWithoutDecimalPlace.length % 3 : 0;

    let wholeNumberPart = "";

    if (firstPartDecimalPlaces) {
        wholeNumberPart += numberWithoutDecimalPlace.substring(0, firstPartDecimalPlaces) + numberDivisor;
    }

    wholeNumberPart += numberWithoutDecimalPlace
        .substring(firstPartDecimalPlaces)
        .replace(/(\d{3})(?=\d)/g, `$1${numberDivisor}`);

    const decimalPlacePart =
        decimalPoint +
        Math.abs(valueAsNumber - parseInt(numberWithoutDecimalPlace, 10))
            .toFixed(decimalPlace)
            .slice(2);

    return prefix + wholeNumberPart + decimalPlacePart;
};
