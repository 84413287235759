import { customElement, property, state } from "lit/decorators.js";

import Inputmask from "@/vendors/inputmask-utils";
import { Watch } from "@/decorators/watch";
import { CardBrandEnum, detectCardBrand, getCardBrandInfo } from "@/helpers/card";

import AtlasInput, { InputProps } from "@/components/form/atlas-input/atlas-input";
import CardValidator from "@/internals/validators/card-validator";

export type CardNumberProps = InputProps & {
    "card-type": "credit" | "debit";
    "accepted-brands": Array<string>;
};

/**
 * @attr {"credit" | "debit"} card-type - Tipo de cartão, se é crédito ou débito
 * @attr {Array<string>} accepted-brands - Lista de bandeiras de cartão que serão aceitas
 *
 * @tag atlas-card-number
 */
@customElement("atlas-card-number")
export default class AtlasCardNumber extends AtlasInput {
    @property({ type: String, attribute: "card-type" }) cardType: "credit" | "debit" = "credit";

    @property({ type: Array, attribute: "accepted-brands" }) acceptedBrands: Array<CardBrandEnum> = [];

    @state() private _cardBrand: CardBrandEnum = CardBrandEnum.UNKNOWN;

    private _maskInstance: Inputmask.Instance;

    private _mask: object = {
        mask: ["9999 9999 9999 9999"],
        jitMasking: true,
        keepStatic: true,
        showMaskOnHover: false
    };

    connectedCallback() {
        super.connectedCallback?.();

        this.updateComplete.then(() => {
            this.buildMask();
        });
    }

    buildMask() {
        this.placeholder = this.placeholder || "0000 0000 0000 0000";
        this.inputMode = "numeric";

        this._maskInstance = Inputmask({
            ...this._mask,
            onKeyDown: () => {
                setTimeout(() => {
                    this._cardBrand = detectCardBrand(
                        this._input.value,
                        this.cardType === "debit",
                        this.acceptedBrands
                    );
                }, 100);
            }
        }).mask(this._input);

        // @ts-expect-error
        this._maskInstance.shadowRoot = this.shadowRoot;

        this.addValidator(new CardValidator());
    }

    @Watch("_cardBrand", true)
    onChangeCardBrand() {
        const brandInfo = getCardBrandInfo(this._cardBrand);

        this._maskInstance.option({
            mask: brandInfo.mask
        });

        this.sideImage = brandInfo.brandFlag;
    }

    getUnmaskedValue() {
        return this._maskInstance.unmaskedvalue();
    }

    getCardBrand() {
        return this._cardBrand;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-card-number": AtlasCardNumber;
    }
}
