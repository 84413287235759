import { customElement, property, state } from "lit/decorators.js";
import { emit } from "@/internals/events";
import { getPostalCodeData } from "@/internals/api/postal-code-api";
import PostalCodeValidator from "@/internals/validators/postal-code-validator";
import Inputmask from "@/vendors/inputmask-utils";

import AtlasInput, { InputProps } from "@/components/form/atlas-input/atlas-input";

export type PostalCodeProps = InputProps & {
    "disable-search": string;
};

/**
 * @extends atlas-input
 *
 * @prop {boolean} disable-search - Indica se a pesquisa pelo cep, que é feita através do asaas, deve ser desabilitada
 *
 * @event {CustomEvent} atlas-postal-code-change - Evento lançado quando é feita uma pesquisa pelo CEP informado no campo
 *
 * @tag atlas-postal-code
 */
@customElement("atlas-postal-code")
export default class AtlasPostalCode extends AtlasInput {
    @property({ type: Boolean, attribute: "disable-search" }) disableSearch: boolean;

    @state() private _postalCodeData: { [key: string]: any } = {};

    private _maskInstance: Inputmask.Instance;

    private mask: object = {
        mask: ["99999-999"],
        jitMasking: true,
        keepStatic: true,
        showMaskOnHover: false
    };

    connectedCallback() {
        super.connectedCallback?.();

        this.updateComplete.then(() => {
            this.buildMask();
            this.fillPostalCodeData();
        });
    }

    buildMask() {
        this.placeholder = "00000-000";
        this.inputMode = "numeric";

        this._maskInstance = Inputmask(this.mask).mask(this._input);

        if (this.value) this.value = Inputmask.format(this.value, this.mask);

        this.addValidator(new PostalCodeValidator());
    }

    getUnmaskedValue() {
        return this._maskInstance.unmaskedvalue();
    }

    getPostalCodeData() {
        return this._postalCodeData;
    }

    async handleInput() {
        await super.handleInput();

        if (Inputmask.isValid(this.value, this.mask)) {
            this.fillPostalCodeData();
        }
    }

    onChangeValue() {
        if (!Inputmask.isValid(this.value, this.mask)) {
            this.value = Inputmask.format(this.value, this.mask);
        }

        super.onChangeValue();
    }

    handleBlur() {
        super.handleBlur();
        this.fillPostalCodeData();
    }

    async fillPostalCodeData() {
        if (!this._valid || !this.value || this.disableSearch) {
            return;
        }

        let result: { [key: string]: any } = {};

        this.loading = true;

        try {
            result = await getPostalCodeData(this.value);
        } catch (e) {
            result = { success: false };
            /* eslint-disable no-console */
            console.error(e);
            /* eslint-enable no-console */
        }

        this._postalCodeData = result.success ? result.postalCode : {};
        this.loading = false;

        emit(this, "atlas-postal-code-change");
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-postal-code": AtlasPostalCode;
    }
}
