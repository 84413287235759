import { LitElement, html } from "lit";
import { customElement, property, queryAssignedElements, state } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";
import DeviceController from "@/controllers/device-controller";

import ProductInfo, { Product } from "@/internals/product-info";
import sidebarStyles from "./atlas-sidebar.scss";

import "@/components/display/atlas-icon/atlas-icon";

export type SidebarProps = {
    "product": Product;
    "product-logo": string;
    "product-logo-small": string;
    "home-path": string;
    "default-collapsed": boolean;
    "collapsed": boolean;
};

/**
 * @dependency atlas-icon
 * @dependency atlas-icon-button
 *
 * @slot header - Usado para incluir elementos logo após a logo que é exibida na sidebar
 * @slot body - Usado para incluir elementos logo após o `header`
 *
 * @prop {Product} product - O produto do Asaas que está renderizando a sidebar
 * @prop {string} product-logo - Logo que será exibida na versão expandida da sidebar (Caso não seja definida, usa a logo padrão do produto)
 * @prop {string} product-logo-small - Logo que será exibida na versão colapsada da sidebar (Caso não seja definida, usa a logo padrão do produto)
 * @prop {string} home-path - Link da página inicial da aplicação, é aberto ao clicar na logo presente na sidebar
 * @prop {boolean} default-collapsed - Booleano que informa se a sidebar vem colapsada por padrão
 * @prop {boolean} collapsed - Booleano que informa se a sidebar está colapsada
 *
 * @event {CustomEvent} atlas-sidebar-toggle - Evento disparado quando a sidebar é colapsada ou expandida
 *
 */
@customElement("atlas-sidebar")
export default class AtlasSidebar extends LitElement {
    static styles = sidebarStyles;

    @property({ type: String }) product: Product = "asaas";

    @property({ type: String, attribute: "product-logo" }) productLogo: string;

    @property({ type: String, attribute: "product-logo-small" }) productLogoSmall: string;

    @property({ type: String, attribute: "home-path" }) homePath: string;

    @property({ type: Boolean, attribute: "default-collapsed" }) defaultCollapsed = false;

    @property({ type: Boolean, reflect: true }) collapsed = false;

    @state() private hasChangedCollapse = false;

    @queryAssignedElements({ slot: "body", selector: "atlas-sidebar-menu" })
    private slottedMenus: Array<HTMLElement>;

    private deviceController = new DeviceController(this, this.onChangeScreenType.bind(this));

    connectedCallback() {
        super.connectedCallback?.();

        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onChangeScreenType();
    }

    disconnectedCallback() {
        super.disconnectedCallback?.();
    }

    getNavbarTitle() {
        return document.querySelector("atlas-navbar")?.getAttribute("page-title") || "";
    }

    onDocumentClick(event: PointerEvent) {
        const sidebarElement = this.renderRoot.querySelector(".sidebar");

        if (this.collapsed || event.composedPath().includes(sidebarElement)) {
            return;
        }

        this.onToggleCollapse();
    }

    onChangeScreenType() {
        if (this.deviceController.isMobile) {
            this.collapsed = true;
        } else if (this.hasChangedCollapse) {
            this.collapsed = true;
            this.onToggleCollapse();
        } else {
            this.collapsed = this.defaultCollapsed;
        }
    }

    onToggleCollapse() {
        this.collapsed = !this.collapsed;
        this.hasChangedCollapse = true;

        emit(this, "atlas-sidebar-toggle", {
            detail: {
                collapsed: this.collapsed
            }
        });

        if (this.deviceController.isMobile) {
            if (this.collapsed) {
                document.removeEventListener("click", this.onDocumentClick);
            } else {
                document.addEventListener("click", this.onDocumentClick);
            }
        }
    }

    @Watch("collapsed", true)
    shrinkSlottedMenus() {
        this.slottedMenus.forEach((element) => {
            element.toggleAttribute("shrinked", this.collapsed);
        });
    }

    renderProductLogo() {
        const product = ProductInfo.getProductInfo(this.product);

        return html`
            <img
                class="logo-default"
                src="${this.productLogo || product.logoDefault}"
                title="${product.name}"
                alt="${product.alt}"
            />
            <img
                class="logo-collapsed"
                src="${this.productLogoSmall || product.logoMini}"
                title="${product.name}"
                alt="${product.alt}"
            />
        `;
    }

    renderCollapseButton() {
        return html`
            <button class="collapse-button" @click=${this.onToggleCollapse}>
                <atlas-icon name="chevron-left" size="3x" theme="primary"></atlas-icon>
            </button>
        `;
    }

    renderMobileHeader() {
        return when(
            this.deviceController.isMobile,
            () => html`
                <div class="sidebar-mobile-header">
                    <button class="close-mobile" @click=${this.onToggleCollapse}>
                        <atlas-icon name="x" size="3x" theme="primary"></atlas-icon>
                    </button>
                    <span class="title">${this.getNavbarTitle()}</span>
                </div>
            `
        );
    }

    render() {
        const sidebarClass = {
            sidebar: true,
            collapsed: this.collapsed
        };

        return html`
            <nav class="${classMap(sidebarClass)}">
                ${this.renderCollapseButton()} ${this.renderMobileHeader()}
                <div class="sidebar-content">
                    <a class="product-logo" href="${this.homePath || ProductInfo.getHomePage(this.product)}">
                        ${this.renderProductLogo()}
                    </a>
                    <div class="sidebar-header">
                        <slot name="header"></slot>
                    </div>
                    <div class="sidebar-body">
                        <slot name="body" @slotchange=${this.shrinkSlottedMenus}></slot>
                    </div>
                </div>
            </nav>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-sidebar": AtlasSidebar;
    }
}
