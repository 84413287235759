import { html, nothing } from "lit";
import { customElement, property, query, queryAsync } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import DeviceController from "@/controllers/device-controller";
import { Watch } from "@/decorators/watch";
import { getAllFormElements } from "@/helpers/form";
import { closeAllOverlays } from "@/helpers/overlay";
import { emit } from "@/internals/events";

import type AtlasButton from "@/components/display/atlas-button/atlas-button";
import type AtlasForm from "@/components/form/atlas-form/atlas-form";
import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-form-panel.scss";
import "@/components/display/atlas-button/atlas-button";
import "@/components/form/atlas-form/atlas-form";
import "@/components/layout/atlas-layout/atlas-layout";
import "@/components/layout/atlas-panel/atlas-panel";

export type FormPanelProps = AtlasElementProps & {
    "header": string;
    "description": string;
    "submit-button-label": string;
    "action": string;
    "method": "get" | "post";
    "target": string;
};

/**
 * @prop {string} header - Título do painel
 * @prop {string} description - Descrição do painel
 * @prop {string} submit-button-label - Label do botão que faz o submit do formulário do painel
 * @prop {string} action - URL que será alvo do formulário
 * @prop {"get" | "post"} method - Método HTTP que será usado para o envio do formulário (get | post)
 * @prop {string} target - Onde será exibido o corpo da resposta do formulário
 * @prop {boolean} editing - Indica se o panel está em modo de edição, se passado ao renderizar o elemento, o painel já vem em modo de edição
 * @prop {boolean} hide-badge - Indica se a badge "Em edição" deve ser oculta do painel
 *
 * @event {CustomEvent} atlas-form-panel-start-editing - Evento lançado quando a edição do formulário é iniciada
 * @event {CustomEvent} atlas-form-panel-end-editing - Evento lançado quando a edição do formulário é finalizada
 * @event {CustomEvent} atlas-form-panel-submit - Evento lançado quando o submit do formulário será executado
 *
 * @slot - Conteúdo do painel
 * @slot actions - Ações do painel, aparecem ao lado do cabeçalho
 *
 * @tag atlas-form-panel
 */
@customElement("atlas-form-panel")
export default class AtlasFormPanel extends AtlasElement {
    static styles = styles;

    @property({ type: String }) header: string;

    @property({ type: String }) description: string;

    @property({ type: String, attribute: "submit-button-label" }) submitButtonLabel: string;

    @property({ type: String }) action: string;

    @property({ type: String }) method: "get" | "post" = "get";

    @property({ type: String }) target: string;

    @property({ type: Boolean }) editing: boolean = false;

    @property({ type: Boolean, attribute: "hide-badge" }) hideBadge: boolean = false;

    @query(".action-button-submit") private _submitButton: AtlasButton;

    @query(".action-button-cancel") private _cancelButton: AtlasButton;

    @queryAsync("atlas-form") private _panelForm: AtlasForm;

    private _deviceController: DeviceController = new DeviceController(this);

    connectedCallback(): void {
        super.connectedCallback?.();

        this.onActionButtonClick = this.onActionButtonClick.bind(this);
        this.onChangeEditing = this.onChangeEditing.bind(this);
        this.onStartEditing = this.onStartEditing.bind(this);
        this.onEndEditing = this.onEndEditing.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);

        this.addEventListener("atlas-button-click", this.onActionButtonClick);
        this.addEventListener("atlas-dropdown-item-click", this.onActionButtonClick);
        this.addEventListener("atlas-form-submit", this.onFormSubmit);
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-button-click", this.onActionButtonClick);
        this.removeEventListener("atlas-dropdown-item-click", this.onActionButtonClick);
        this.removeEventListener("atlas-form-submit", this.onFormSubmit);
    }

    onActionButtonClick(event: CustomEvent) {
        const target = event.target as HTMLElement;

        if (target.hasAttribute("data-panel-start-editing")) {
            this.editing = true;
        }
    }

    onStartEditing() {
        this.togglePanelFormElements(true);
        this.enablePanelButtons();

        emit(this, "atlas-form-panel-start-editing");
    }

    onEndEditing() {
        this.togglePanelFormElements(false);

        emit(this, "atlas-form-panel-end-editing");
    }

    onFormSubmit(event: CustomEvent) {
        event.stopPropagation();

        this._submitButton.loading = true;
        this._cancelButton.disable();

        const submitEvent = emit(this, "atlas-form-panel-submit");

        if (submitEvent.defaultPrevented) {
            event.preventDefault();
        }
    }

    @Watch("editing")
    async onChangeEditing() {
        await this.updateComplete;
        closeAllOverlays();

        if (this.editing) {
            this.onStartEditing();
        } else {
            this.onEndEditing();
        }
    }

    onClickCancelButton() {
        this.editing = false;
    }

    async onClickSubmitButton() {
        (await this._panelForm).submit();
    }

    finishEditing() {
        this.editing = false;
    }

    enablePanelButtons() {
        this._submitButton.loading = false;
        this._cancelButton.enable();
    }

    togglePanelFormElements(enable: boolean) {
        getAllFormElements(this).forEach((element) => element.toggleDisabled(!enable));
    }

    renderEditActions() {
        return html`
            <atlas-layout
                gap="4"
                inline
                mobile-inline
                slot=${ifDefined(!this._deviceController.isMobile ? "actions" : undefined)}
            >
                <atlas-button
                    description="Cancelar"
                    theme="secondary"
                    @atlas-button-click=${this.onClickCancelButton}
                    class="action-button-cancel"
                ></atlas-button>
                <atlas-button
                    description=${this.submitButtonLabel || "Salvar"}
                    theme="success"
                    @atlas-button-click=${this.onClickSubmitButton}
                    class="action-button-submit"
                ></atlas-button>
            </atlas-layout>
        `;
    }

    renderPanelActions() {
        if (this._deviceController.isMobile && this.editing) return nothing;

        return when(
            this.editing,
            () => this.renderEditActions(),
            () => html`<slot name="actions" slot="actions"></slot>`
        );
    }

    renderFooterActions() {
        return when(this._deviceController.isMobile && this.editing, () => this.renderEditActions());
    }

    render() {
        return html`
            <atlas-form action=${this.action} method=${this.method} target=${this.target}>
                <atlas-panel
                    header=${this.header}
                    description=${this.description}
                    badge-type=${this.editing && !this.hideBadge ? "outlined" : null}
                    badge-text=${this.editing && !this.hideBadge ? "Em edição" : null}
                    badge-icon=${this.editing && !this.hideBadge ? "pencil" : null}
                    badge-theme=${this.editing && !this.hideBadge ? "primary" : null}
                >
                    ${this.renderPanelActions()}
                    <atlas-layout gap="6">
                        <slot></slot>
                        ${this.renderFooterActions()}
                    </atlas-layout>
                </atlas-panel>
            </atlas-form>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-form-panel": AtlasFormPanel;
    }
}
