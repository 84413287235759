import { LitElement, html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { styleMap } from "lit/directives/style-map.js";
import { when } from "lit/directives/when.js";
import { emit } from "@/internals/events";
import { Watch } from "@/decorators/watch";

import styles from "./atlas-bottom-sheet.scss";
import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-icon-button/atlas-icon-button";
import "@/components/display/atlas-text/atlas-text";

export type BottomSheetProps = {
    "header": string;
    "open": boolean;
    "fullscreen": boolean;
    "atlas-bottom-sheet-close": Function;
};

/**
 * @dependency atlas-heading
 * @dependency atlas-icon-button
 * @dependency atlas-text
 *
 * @prop {string} header - Título do bottom-sheet.
 * @prop {boolean} open - Booleano que indica se o bottom-sheet está aberto
 * @prop {boolean} fullscreen - Booleano que indica se o bottom-sheet será aberto ocupando todo o espaço da tela
 *
 * @slot - Slot padrão usado para definir o conteúdo do bottom-sheet
 * @slot subheading - Slot para adicionar um conteúdo extra no cabeçalho do bottom sheet
 *
 * @event {CustomEvent} atlas-bottom-sheet-close - Evento disparado para indicar que o bottom-sheet deve ser fechado
 *
 * @tag atlas-bottom-sheet
 */
@customElement("atlas-bottom-sheet")
export default class AtlasBottomSheet extends LitElement {
    static styles = styles;

    @property({ type: String }) header: string;

    @property({ type: Boolean }) open: boolean;

    @property({ type: Boolean }) fullscreen: boolean;

    @state() private _bottomSheetHeight: number;

    @state() private _currentTranslate: number;

    @state() private _startPosition: number;

    @state() private _dragging: boolean;

    @query(".bottom-sheet")
    private _bottomSheet: HTMLElement;

    connectedCallback() {
        super.connectedCallback?.();

        this.onAnchorDragStart = this.onAnchorDragStart.bind(this);
        this.onAnchorDrag = this.onAnchorDrag.bind(this);
        this.onAnchorDragEnd = this.onAnchorDragEnd.bind(this);
        this.onClickBottomSheet = this.onClickBottomSheet.bind(this);

        this.updateComplete.then(() => {
            this._bottomSheet.addEventListener("touchstart", this.onAnchorDragStart);
            this._bottomSheet.addEventListener("touchmove", this.onAnchorDrag);
            this._bottomSheet.addEventListener("touchend", this.onAnchorDragEnd);
        });
    }

    disconnectedCallback() {
        super.disconnectedCallback?.();

        this._bottomSheet.removeEventListener("touchstart", this.onAnchorDragStart);
        this._bottomSheet.removeEventListener("touchmove", this.onAnchorDrag);
        this._bottomSheet.removeEventListener("touchend", this.onAnchorDragEnd);
    }

    canResizeOnDrag(event: TouchEvent) {
        const bottomSheetBody = this.shadowRoot.querySelector(".bottom-sheet-body");
        const composedPath = event.composedPath();
        const isBodyTarget = composedPath.includes(bottomSheetBody);

        if (!isBodyTarget) {
            return true;
        }

        return bottomSheetBody.scrollTop === 0;
    }

    onAnchorDragStart(event: TouchEvent) {
        if (this.canResizeOnDrag(event)) {
            this._dragging = true;
            this._startPosition = event.changedTouches[0].clientY;
            this._bottomSheetHeight = this._bottomSheet.getBoundingClientRect().height;
        }
    }

    onAnchorDrag(event: TouchEvent) {
        if (!this._dragging) return;

        const positionY = event.touches[0].clientY;
        const heightDiff = positionY - this._startPosition;

        if (heightDiff > 0) {
            this._currentTranslate = (heightDiff * 100) / this._bottomSheetHeight;
        }
    }

    onAnchorDragEnd() {
        if (!this._dragging) return;

        this._dragging = false;

        if (this._currentTranslate > 30) {
            this._currentTranslate = 100;

            setTimeout(() => {
                this.closeBottomSheet();
            }, 200);
        } else {
            this._currentTranslate = null;
            this._startPosition = null;
        }
    }

    onClickBottomSheet(event: PointerEvent) {
        if (!this.open) {
            return;
        }

        const composedPath = event.composedPath();
        const isBottomSheetTarget = composedPath.includes(this._bottomSheet);

        if (!isBottomSheetTarget) {
            this.closeBottomSheet();
        }
    }

    closeBottomSheet() {
        emit(this, "atlas-bottom-sheet-close");
    }

    @Watch("open")
    onOpenChange() {
        if (this.open) {
            this._currentTranslate = null;
            this._startPosition = null;
        }
    }

    renderTitle() {
        if (!this.header) return null;

        return when(
            this.fullscreen,
            () => html`
                <atlas-heading size="h5" muted>${this.header}</atlas-heading>
                <atlas-icon-button
                    icon="x"
                    size="3x"
                    theme="secondary"
                    @click=${this.closeBottomSheet}
                ></atlas-icon-button>
            `,
            () => html`<atlas-text size="xsm" muted>${this.header}</atlas-text>`
        );
    }

    render() {
        const bottomSheetWrapperClass = {
            "bottom-sheet-wrapper": true,
            "fade": true,
            "show": this.open
        };

        const bottomSheetClass = {
            "bottom-sheet": true,
            "fullscreen": this.fullscreen,
            "dragging": this._dragging
        };

        const bottomSheetStyle = {
            transform: this._currentTranslate ? `translateY(${this._currentTranslate}%)` : null
        };

        /* eslint-disable lit-a11y/click-events-have-key-events */
        return html`
            <div class=${classMap(bottomSheetWrapperClass)} @click=${this.onClickBottomSheet}>
                <div class=${classMap(bottomSheetClass)} style=${styleMap(bottomSheetStyle)} draggable="true">
                    <div class="bottom-sheet-header">
                        <div class="drag-anchor"></div>
                        <div class="bottom-sheet-title">${this.renderTitle()}</div>
                        <slot name="subheading"></slot>
                    </div>
                    <div class="bottom-sheet-body">
                        <slot></slot>
                    </div>
                </div>
            </div>
        `;
        /* eslint-enable lit-a11y/click-events-have-key-events */
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-bottom-sheet": AtlasBottomSheet;
    }
}
