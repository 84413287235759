import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import styles from "./atlas-list.scss";

export type ListProps = {
    gap: number;
};

/**
 * @slot - Usado para colocar os ListItems
 *
 * @prop {number} gap - Propriedade que define o gap entre os itens da lista
 *
 * @tag atlas-list
 */
@customElement("atlas-list")
export default class AtlasList extends LitElement {
    static styles = styles;

    @property({ type: Number }) gap: number;

    render() {
        const listClass = {
            "atlas-list": true,
            [`gap-${this.gap}`]: this.gap
        };

        return html`
            <div class="${classMap(listClass)}" role="list">
                <slot></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-list": AtlasList;
    }
}
