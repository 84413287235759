import AtlasAlert from "./atlas-alert";
import { AlertType } from "./types";

const MESSAGE_CONTAINER_CLASS = "atlas-alert-container";
const MESSAGE_CONTAINER_SELECTOR = `.${MESSAGE_CONTAINER_CLASS}`;
const MAX_ALERTS_IN_QUEUE = 4;
const CLOSE_TIMEOUT_TIME = 5000;
const TIMEOUT_KEY = "atlas-alert-timeout-id";

/**
 * Return a list of all alerts in the document
 */
const getAllAlerts = function (): Array<AtlasAlert> {
    const container = document.querySelector(MESSAGE_CONTAINER_SELECTOR);
    const alertList = container?.querySelectorAll("atlas-alert");

    return [].concat(...(alertList || []));
};

/**
 * Createas a floating invisible container where alerts will be placed
 */
const createContainerIfNeeded = function () {
    if (document.querySelector(MESSAGE_CONTAINER_SELECTOR)) {
        return;
    }

    const container = document.createElement("div");

    container.classList.add(MESSAGE_CONTAINER_CLASS);
    document.body.append(container);
};

/**
 * Close alerts with the same message
 */
const removeEqualAlerts = function (message: string) {
    getAllAlerts().forEach((element) => {
        if (element.message === message) {
            element.destroy();
        }
    });
};

/**
 * Create the alert element in the document
 */
const createAlertElement = function (message: string, type: AlertType): HTMLElement {
    const element = document.createElement("atlas-alert");

    element.setAttribute("message", message);
    element.setAttribute("type", type);

    return element;
};

/**
 * Remove the last visible alert in the queue
 */
const removeLastVisibleAlert = function () {
    getAllAlerts().forEach((element, index) => {
        if (index >= MAX_ALERTS_IN_QUEUE - 1) {
            if (element.getAttribute("type") === "error") {
                return;
            }

            element.destroy();
        }
    });
};

/**
 * Add the alert in the queue
 */
const addInQueue = function (alertElement: HTMLElement) {
    if (alertElement.getAttribute("type") === "error") {
        document.querySelector(MESSAGE_CONTAINER_SELECTOR).prepend(alertElement);
        return;
    }

    const alertsWithErrorList = getAllAlerts().filter((element) => element.getAttribute("type") === "error");

    if (alertsWithErrorList.length > 0) {
        const nextElementAfterLastAlertWithError = alertsWithErrorList[alertsWithErrorList.length - 1].nextSibling;

        document
            .querySelector(MESSAGE_CONTAINER_SELECTOR)
            .insertBefore(alertElement, nextElementAfterLastAlertWithError);
    } else {
        document.querySelector(MESSAGE_CONTAINER_SELECTOR).prepend(alertElement);
    }
};

/**
 * Set the close timer for the alerts
 */
const closeAlertsTimeout = function () {
    const alertTriggerList = getAllAlerts();

    if (!alertTriggerList) {
        return;
    }

    clearTimeout(parseInt(localStorage.getItem(TIMEOUT_KEY), 10));

    alertTriggerList.forEach((element) => {
        if (element.getAttribute("type") === "error") {
            return;
        }

        const timeoutId = setTimeout(() => {
            element.destroy();
            localStorage.removeItem(TIMEOUT_KEY);
        }, CLOSE_TIMEOUT_TIME);

        localStorage.setItem(TIMEOUT_KEY, String(timeoutId));
    });
};

/**
 * Show a toaster alert for the user
 * @param message Message that will be visible
 * @param type The type of the message. Must be one of this strings ("success" | "error" | "warning" | "info")
 */
export function showAlert(message: string, type: string) {
    createContainerIfNeeded();
    removeEqualAlerts(message);

    const alertType = type?.toLowerCase() as AlertType;
    const element = createAlertElement(message, alertType);
    const visibleAlertsCount = getAllAlerts().length;

    if (visibleAlertsCount >= MAX_ALERTS_IN_QUEUE) {
        removeLastVisibleAlert();
    }

    addInQueue(element);
    closeAlertsTimeout();
}

export function closeAllAlerts() {
    getAllAlerts().forEach((element) => {
        element.destroy();
    });
}
