import FormElement from "@/components/form/form-element";
import FieldValidator, { FieldValidatorStatus } from "./field-validator";

export default class RequiredValidator implements FieldValidator {
    name: string = "required";

    status: FieldValidatorStatus = "error";

    requiredErrorMessage: string;

    constructor(requiredErrorMessage?: string) {
        this.requiredErrorMessage = requiredErrorMessage;
    }

    getInvalidMessage(): string {
        return this.requiredErrorMessage || "Esse campo é obrigatório";
    }

    validate(element: FormElement): boolean {
        if (!element.required) {
            return true;
        }

        const elementValue = element.getElementValue();

        if (Array.isArray(elementValue)) {
            return elementValue.length > 0;
        }

        return Boolean(elementValue);
    }
}
