import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";
import { WithTooltipMixin, WithTooltipProps } from "@/internals/mixins/with-tooltip-mixin";

import { Theme } from "@/internals/theme";
import styles from "./atlas-dropdown-item.scss";

import "@/components/display/atlas-badge/atlas-badge";
import "@/components/display/atlas-icon/atlas-icon";

export type DropdownItemProps = WithTooltipProps & {
    "href": string;
    "icon": string;
    "theme": Theme;
    "external": boolean;
    "active": boolean;
    "disabled": boolean;
    "is-new": boolean;
    "loading": boolean;
};

/**
 * Dropdown Item serve para ser utilizado dentro de um dropdown, ele é exibido como se fosse o item de uma lista de opções
 *
 * @dependency atlas-icon
 * @dependency atlas-badge
 *
 * @prop {string} href - Link que será aberto ao clicar no item
 * @prop {string} icon - Ícone que irá aparecer ao lado esquerdo do texto
 * @prop {Theme} theme - Tema que será aplicado no item, serve para customizar suas cores, baseado no tema passado
 * @prop {boolean} external - Indica se o link que vai ser aberto ao clicar sobre o item é um link externo
 * @prop {boolean} active - Indica se o item está ativo
 * @prop {boolean} disabled - Indica se o item está desabilitado
 * @prop {boolean} is-new - Indica se deve ser exibido a tag "Novo" ao lado do item
 * @prop {boolean} loading - Indica se o item está em estado de loading
 *
 * @slot default - Slot padrão, usado para definir o texto do item
 *
 * @event {CustomEvent} atlas-dropdown-item-click - Evento lançado ao clicar sobre o item
 * @event {CustomEvent} atlas-changed-is-new - Evento lançado quando o atributo novo é alterado (Usado para integração com outros componentes)
 *
 * @tag atlas-dropdown-item
 */
@customElement("atlas-dropdown-item")
export default class AtlasDropdownItem extends WithTooltipMixin(LitElement) {
    static styles = styles;

    @property({ type: String }) href: string;

    @property({ type: String }) icon: string;

    @property({ type: String }) theme: Theme;

    @property({ type: Boolean }) external = false;

    @property({ type: Boolean }) active = false;

    @property({ type: Boolean }) disabled = false;

    @property({ type: Boolean, attribute: "is-new", reflect: true }) isNew = false;

    @property({ type: Boolean }) loading: boolean;

    handleItemClick() {
        if (this.disabled || this.loading) {
            return;
        }

        emit(this, "atlas-dropdown-item-click");
    }

    getThemeClass(): string {
        return this.theme ? `dropdown-item-${this.theme}` : "";
    }

    @Watch("isNew", true)
    onChangeTagNew() {
        emit(this, "atlas-changed-is-new");
    }

    renderIcon() {
        return when(
            !!this.icon || this.loading,
            () => html`<atlas-icon name=${this.loading ? "loader" : this.icon} size="2x"></atlas-icon>`
        );
    }

    render() {
        const itemClass = {
            "dropdown-item": true,
            [this.getThemeClass()]: true,
            "active": this.active,
            "disabled": this.disabled || this.loading,
            "loading": this.loading
        };

        return html`
            <div class="dropdown-item-wrapper" data-atlas-tooltip="dropdown-item-tooltip">
                <a
                    class=${classMap(itemClass)}
                    href="${ifDefined(this.href || undefined)}"
                    rel="${ifDefined(this.external ? "noreferrer noopener" : undefined)}"
                    target="${ifDefined(this.external ? "_blank" : undefined)}"
                    @click=${this.handleItemClick}
                >
                    ${this.renderIcon()}
                    <span>
                        <slot></slot>
                    </span>
                    ${when(this.isNew, () => html`<atlas-badge text="Novo"></atlas-badge>`)}
                </a>
            </div>
            ${this.renderTooltip("dropdown-item-tooltip")}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-dropdown-item": AtlasDropdownItem;
    }
}
