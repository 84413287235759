import { LitElement, html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";

import { styleMap } from "lit/directives/style-map.js";

import { Watch } from "@/decorators/watch";

import styles from "./atlas-collapse.scss";

/**
 * O Collapse serve para adicionar conteúdo dentro de um bloco colapsável, que é controlado pela propriedade `show`
 *
 * @prop {boolean} show - Boleano que indica se o conteúdo está sendo exibido ou não
 *
 * @slot - Slot padrão para adicionar o conteúdo do Collapse
 *
 * @tag atlas-collapse
 */
@customElement("atlas-collapse")
export default class AtlasCollapse extends LitElement {
    static styles = styles;

    @property({ type: Boolean }) show = false;

    @state() private _maxHeight: string = "0px";

    @state() private _overflow: string = "hidden";

    @query(".collapse-wrapper")
    private _wrapperRef?: HTMLElement;

    connectedCallback() {
        super.connectedCallback?.();

        this.updateComplete.then(() => {
            if (this.show) {
                this._overflow = "visible";
                this._maxHeight = "unset";
            } else {
                this._overflow = "hidden";
                this._maxHeight = `0px`;
            }
        });
    }

    @Watch("show", true)
    applyMaxHeight() {
        this._overflow = "hidden";
        this._maxHeight = `${this._wrapperRef.scrollHeight}px`;

        if (!this.show) {
            setTimeout(() => {
                this._maxHeight = `0px`;
            }, 0);
        } else {
            this._maxHeight = `${this._wrapperRef.scrollHeight}px`;

            setTimeout(() => {
                this._overflow = "visible";
                this._maxHeight = "unset";
            }, 350);
        }
    }

    render() {
        const wrapperStyles = {
            maxHeight: this._maxHeight,
            overflow: this._overflow
        };

        return html`
            <div class="collapse-wrapper" style=${styleMap(wrapperStyles)}>
                <slot></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-collapse": AtlasCollapse;
    }
}
