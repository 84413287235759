import qs from "qs";

function buildFormData(formData: FormData, params: any, parentKey?: string) {
    if (params && typeof params === "object" && !(params instanceof Date) && !(params instanceof File)) {
        Object.keys(params).forEach((key) => {
            buildFormData(formData, params[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = params == null ? "" : params;

        formData.append(parentKey, value);
    }
}

function objectToFormData(params: { [key: string]: any }) {
    const formData = new FormData();

    buildFormData(formData, params);
    return formData;
}

function getRequestHeaders(jsonRequest?: boolean) {
    const headers = new Headers();

    headers.append("X-Requested-With", "XMLHttpRequest");

    if (jsonRequest) {
        headers.append("content-type", "application/json");
    }

    return headers;
}

export const post = async (url: string, params: object, jsonRequest?: boolean): Promise<any> => {
    const headers = getRequestHeaders(jsonRequest);
    const body = jsonRequest ? JSON.stringify(params) : objectToFormData(params);

    const response = await fetch(url, {
        method: "POST",
        headers,
        body
    });

    const responseData = await response.json();
    return responseData;
};

export const get = async (url: string, params: object): Promise<any> => {
    let fullPath = url;

    if (params && Object.keys(params).length > 0) {
        fullPath += `?${qs.stringify(params, { indices: false })}`;
    }

    const response = await fetch(fullPath, {
        method: "GET",
        headers: getRequestHeaders(false)
    });

    const responseData = await response.json();
    return responseData;
};
