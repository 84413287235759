import { html } from "lit";
import { customElement, property } from "lit/decorators.js";

/* eslint-disable import/no-extraneous-dependencies */
import Chart from "chart.js/auto";
/* eslint-enable import/no-extraneous-dependencies */

import { Watch } from "@/decorators/watch";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";

import styles from "./atlas-comparison-chart.scss";
import colorStyles from "../chart-colors.scss";

import { chartBaseOptions, externalLegendHandler, externalTooltipHandler } from "../chart-config";

export type ComparisonChartProps = AtlasElementProps & {
    "x-axis-key": string;
    "y-axis-key": string;
    "goal-label": string;
    "real-label": string;
    "goal-data": string;
    "real-data": string;
    "max-y-value": number;
};

/**
 * @prop {string} x-axis-key - Propriedade que define a key utilizada para os valores do eixo X
 * @prop {string} y-axis-key - Propriedade que define a key utilizada para os valores do eixo Y
 * @prop {string} goal-label - Propriedade que define a legenda que se refere aos dados usado para comparação
 * @prop {string} real-label - Propriedade que define a legenda que se refere aos dados reais a serem comparados
 * @prop {number} max-y-value - Propriedade que define qual o valor máximo no eixo Y
 * @prop {Array} goal-data - Propriedade que contém os dados dos valores comparativos
 * @prop {Array} real-data - Propriedade que contém os dados dos valores reais a serem comparados
 *
 * @tag atlas-comparison-chart
 */
@customElement("atlas-comparison-chart")
export default class AtlasComparisonChart extends AtlasElement {
    static styles = styles;

    @property({ type: String, attribute: "x-axis-key" }) xAxisKey: string = "key";

    @property({ type: String, attribute: "y-axis-key" }) yAxisKey: string = "value";

    @property({ type: String, attribute: "goal-label" }) goalLabel: string = "Meta";

    @property({ type: String, attribute: "real-label" }) realLabel: string = "Vendas";

    @property({ type: Number, attribute: "max-y-value" }) maxYValue: number;

    @property({ type: Array, attribute: "goal-data" }) goalData: Array<object>;

    @property({ type: Array, attribute: "real-data" }) realData: Array<object>;

    public legendList: HTMLElement;

    public legendItemTemplate: HTMLElement;

    public chartElement: HTMLCanvasElement;

    private _chartObject: Chart;

    private _chartColors: any;

    connectedCallback(): void {
        super.connectedCallback?.();

        this.buildColorsFromCSS();

        this.updateComplete.then(() => {
            this.legendList = this.shadowRoot.querySelector(".legend-list") as HTMLElement;
            this.legendItemTemplate = this.shadowRoot.querySelector(".legend-item") as HTMLElement;
            this.chartElement = this.shadowRoot.querySelector(".chart") as HTMLCanvasElement;

            this.buildChart();
        });
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();
    }

    buildColorsFromCSS() {
        const { cssRules } = colorStyles.styleSheet;

        const colors = {
            amount: "",
            goal: ""
        };

        for (const rule of cssRules) {
            const key = (rule as CSSStyleRule).selectorText;
            const value = (rule as CSSStyleRule).style.getPropertyValue("color");

            Object.assign(colors, { [`${key}`]: value });
        }

        this._chartColors = colors;
    }

    getDataColor(dataType: any) {
        return this._chartColors[dataType];
    }

    buildRealLine() {
        const line = {
            type: "line",
            label: this.realLabel,
            backgroundColor: this.getDataColor("real"),
            borderColor: this.getDataColor("real"),
            borderWidth: 2,
            order: 1,
            data: this.realData
        };

        return line;
    }

    buildGoalLine() {
        const line = {
            type: "line",
            label: this.goalLabel,
            backgroundColor: this.getDataColor("goal"),
            borderColor: this.getDataColor("goal"),
            borderDash: [5, 5],
            borderWidth: 2,
            order: 2,
            data: this.goalData
        };

        return line;
    }

    buildData() {
        const realLine = this.buildRealLine();
        const goalLine = this.buildGoalLine();

        return {
            datasets: [realLine, goalLine]
        };
    }

    buildOptions() {
        const options = chartBaseOptions;

        options.parsing = {
            xAxisKey: this.xAxisKey,
            yAxisKey: this.yAxisKey
        };

        options.plugins = {
            // @ts-expect-error
            externalLegendHandler: {
                element: {
                    legendList: this.legendList,
                    legendItemTemplate: this.legendItemTemplate
                },
                isFlowChart: false
            },
            legend: {
                display: false
            },
            tooltip: {
                enabled: false,
                position: "average",
                external: externalTooltipHandler
            }
        };

        options.scales.y.max = this.maxYValue;

        return options;
    }

    buildChart() {
        if (this._chartObject) this._chartObject.destroy();

        const config: any = {
            data: this.buildData(),
            options: this.buildOptions(),
            plugins: [externalLegendHandler]
        };

        this._chartObject = new Chart(this.chartElement, config);
    }

    @Watch("maxYValue", true)
    updateMaxValue() {
        if (!this._chartObject) return;

        this._chartObject.options.scales.y.max = this.maxYValue;
        this._chartObject.update();
    }

    renderLegendItemTemplate() {
        return html`
            <li class="legend-item" hidden>
                <div class="legend-color"></div>
                <span class="legend-text"></span>
            </li>
        `;
    }

    renderLegend() {
        return html`
            <ul class="legend-list"></ul>

            ${this.renderLegendItemTemplate()}
        `;
    }

    renderTooltip() {
        return html`
            <div class="atlas-chart-tooltip" role="tooltip" aria-labelledby="tooltip-slot">
                <div class="tooltip-inner"></div>
            </div>
        `;
    }

    render() {
        return html`
            ${this.renderLegend()}
            <div class="chart-container">
                <canvas class="chart"></canvas>
                ${this.renderTooltip()}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-comparison-chart": AtlasComparisonChart;
    }
}
