import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { emit } from "@/internals/events";

import styles from "./atlas-navbar-dropdown.scss";

import "@/components/display/atlas-icon/atlas-icon";

export type NavbarDropdownProps = {
    "header": string;
    "open": boolean;
    "spaced-content": boolean;
    "no-gap": boolean;
    "atlas-dropdown-trigger-close": Function;
};

/**
 * @dependency atlas-icon
 *
 * @prop {string} header - Título do dropdown.
 * @prop {boolean} open - Booleano que indica se o dropdown está aberto
 * @prop {boolean} spaced-content - Indica se o conteúdo deve possui um espaçamento interno, alinhando com o título.
 * @prop {boolean} no-gap - Indica se os conteúdos do dropdown não devem possuir espaçamento entre eles, ex: lista de itens.
 *
 * @slot - Slot padrão usado para definir o conteúdo do dropdown
 *
 * @event {CustomEvent} atlas-dropdown-trigger-close - Evento disparado para indicar que o dropdown deve ser fechado
 *
 * @tag atlas-navbar-dropdown
 */
@customElement("atlas-navbar-dropdown")
export default class AtlasNavbarDropdown extends LitElement {
    static styles = styles;

    @property({ type: String }) header: string;

    @property({ type: Boolean }) open: boolean;

    @property({ type: Boolean, attribute: "spaced-content" }) spacedContent = false;

    @property({ type: Boolean, attribute: "no-gap" }) noGap = false;

    handleClickClose(event: MouseEvent) {
        event.stopPropagation();
        emit(this, "atlas-dropdown-trigger-close");
    }

    renderHeader() {
        return html`
            <div class="dropdown-header">
                <div class="dropdown-title">
                    <atlas-heading ellipsis size="h5" title=${this.header}>${this.header}</atlas-heading>
                    <slot name="subheading"></slot>
                </div>
                <button class="close-mobile" @click=${this.handleClickClose}>
                    <atlas-icon name="x" size="3x" theme="primary"></atlas-icon>
                </button>
            </div>
        `;
    }

    render() {
        const dropdownClass = {
            "dropdown": true,
            "fade": true,
            "show": this.open,
            "spaced-content": this.spacedContent,
            "no-gap": this.noGap
        };

        return html`
            <div class=${classMap(dropdownClass)}>
                ${this.renderHeader()}
                <div class="dropdown-content">
                    <slot></slot>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-navbar-dropdown": AtlasNavbarDropdown;
    }
}
