import { html } from "lit";
import { customElement, property, state, queryAssignedElements } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import type AtlasFilterForm from "@/components/form/atlas-filter-form/atlas-filter-form";
import type AtlasRadio from "@/components/form/atlas-radio/atlas-radio";

import DeviceController from "@/controllers/device-controller";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import "@/components/display/atlas-dropdown-button/atlas-dropdown-button";
import "@/components/display/atlas-button/atlas-button";
import "@/components/display/atlas-icon-button/atlas-icon-button";
import "@/components/display/atlas-offcanvas/atlas-offcanvas";

export type FilterProps = AtlasElementProps & {
    description: string;
    header: string;
    date: boolean;
};

/**
 * @dependency atlas-dropdown-button
 * @dependency atlas-button
 * @dependency atlas-icon-button
 * @dependency atlas-offcanvas
 *
 * @prop {string} header - Define o header do filtro, será mostrado na versão mobile e no filtros avançados.
 * @prop {string} description - Define o texto que será apresentado no botão
 * @prop {boolean} date - Altera o filtro para o comportamento específico de filtro de data.
 * @prop {boolean} block - Indica se o botão de filtros aparece com o estilo block
 *
 * @tag atlas-filter
 */
@customElement("atlas-filter")
export default class AtlasFilter extends AtlasElement {
    @property({ type: String }) header: string;

    @property({ type: String }) description = "Filtros";

    @property({ type: Boolean }) date = false;

    @property({ type: Boolean }) block = false;

    @queryAssignedElements({ selector: "atlas-filter-form", slot: "simple-filter" })
    public simpleFilterForm!: Array<AtlasFilterForm>;

    @queryAssignedElements({ selector: "atlas-filter-form", slot: "advanced-filter" })
    public advancedFilterForm!: Array<AtlasFilterForm>;

    @state() private _openAdvanced = false;

    @state() private _counter = 0;

    private _advancedFilterCounter: number = 0;

    private _simpleFilterCounter: number = 0;

    private _startDate: string;

    private _finalDate: string;

    private _deviceController = new DeviceController(this);

    private _defaultValues: object;

    connectedCallback(): void {
        super.connectedCallback?.();

        this.addEventListener("atlas-open-advanced-filter", this.openAdvancedFilter);

        this.addEventListener("atlas-clean-filter", this.cleanFilter);
        this.addEventListener("atlas-apply-filter", this.applyFilter);

        this.addEventListener("atlas-offcanvas-close", this.hideAdvancedFilter);
        this.addEventListener("atlas-filter-close", this.hideAdvancedFilter);

        this.addEventListener("atlas-filter-form-count", this.updateCounter);

        this.updateComplete.then(() => {
            this.buildDescription();
            this.getDefaultValues();
        });
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-open-advanced-filter", this.openAdvancedFilter);

        this.removeEventListener("atlas-clean-filter", this.cleanFilter);
        this.removeEventListener("atlas-apply-filter", this.applyFilter);

        this.removeEventListener("atlas-offcanvas-close", this.hideAdvancedFilter);
        this.removeEventListener("atlas-filter-close", this.hideAdvancedFilter);

        this.removeEventListener("atlas-filter-form-count", this.updateCounter);
    }

    async getUpdateComplete() {
        await super.getUpdateComplete();
        await Promise.all([this.simpleFilterForm[0]?.updateComplete, this.advancedFilterForm[0]?.updateComplete]);
        return true;
    }

    cleanFilter() {
        this.setDefaultValues();

        this.simpleFilterForm[0]?.countFilters();
        this.advancedFilterForm[0]?.countFilters();
    }

    applyFilter(event: CustomEvent) {
        const filterForm = event.target as AtlasFilterForm;

        const { filterData } = event.detail;

        if (filterForm.type === "advanced") {
            this.simpleFilterForm[0]?.mergeFilterData(filterData);
        }

        if (filterForm.type === "simple") {
            this.advancedFilterForm[0]?.mergeFilterData(filterData);
        }

        this.simpleFilterForm[0]?.countFilters();
        this.advancedFilterForm[0]?.countFilters();
    }

    openAdvancedFilter(event: CustomEvent) {
        this._openAdvanced = true;
        const { filterData } = event.detail;

        this.advancedFilterForm[0]?.mergeFilterData(filterData, false);
    }

    updateDescription() {
        this.simpleFilterForm[0]?.countFilters();
        this.advancedFilterForm[0]?.countFilters();
    }

    updateCounter(event: CustomEvent) {
        const filterForm = event.target as AtlasFilterForm;

        if (filterForm.type === "advanced") {
            this._advancedFilterCounter = event.detail.counter;
        }

        if (filterForm.type === "simple") {
            this._simpleFilterCounter = event.detail.counter;
        }

        this._counter =
            this._advancedFilterCounter > this._simpleFilterCounter
                ? this._advancedFilterCounter
                : this._simpleFilterCounter;

        this.buildDescription();
    }

    hideAdvancedFilter() {
        this._openAdvanced = false;
    }

    modifySimpleFilterForm() {
        this.simpleFilterForm[0].type = "simple";
    }

    modifyAdvancedFilterForm() {
        this.advancedFilterForm[0].type = "advanced";

        if (this._deviceController.isMobile) this.simpleFilterForm[0].hidden = true;

        this.simpleFilterForm[0].type = "simple";
        this.simpleFilterForm[0].hasAdvancedFilter = true;
    }

    buildDescription() {
        if (this.date) {
            this.buildDescriptionForDateFilter();
            return;
        }

        this.description = `Filtros ${this._counter > 0 ? `(${this._counter})` : ``}`;
    }

    buildDescriptionForDateFilter() {
        const selectedRadioElement = Array.from(this.simpleFilterForm[0].querySelectorAll("atlas-radio")).find(
            (radioElement: AtlasRadio) => radioElement.checked
        );

        if (!selectedRadioElement) {
            this.description = `Filtros`;
            return;
        }

        if (selectedRadioElement.value === "custom") {
            const filterData = this.getSimpleFilterData() as any;

            this._startDate = filterData.startDate;
            this._finalDate = filterData.finishDate;

            this.description = `${this._startDate} - ${this._finalDate}`;
        } else {
            this.description = selectedRadioElement.shadowRoot.querySelector("slot").assignedNodes()[0].textContent;
        }
    }

    getDefaultValues() {
        this._defaultValues = this.getSimpleFilterData();
    }

    setDefaultValues() {
        this.simpleFilterForm[0]?.mergeFilterData(this._defaultValues);
        this.advancedFilterForm[0]?.mergeFilterData(this._defaultValues);
    }

    getSimpleFilterData(asFormData: boolean = false) {
        return this.simpleFilterForm[0]?.getFilterData(asFormData);
    }

    getAdvancedFilterData(asFormData: boolean = false) {
        return this.advancedFilterForm[0]?.getFilterData(asFormData);
    }

    getGroupedSimpleFilterData() {
        return this.simpleFilterForm[0]?.getGroupedFilterData();
    }

    getGroupedAdvancedFilterData() {
        return this.advancedFilterForm[0]?.getGroupedFilterData();
    }

    enableButtons() {
        this.simpleFilterForm[0]?.enableButtons();
        this.advancedFilterForm[0]?.enableButtons();
    }

    disableButtons() {
        this.simpleFilterForm[0]?.disableButtons();
        this.advancedFilterForm[0]?.disableButtons();
    }

    getIcon() {
        return this.date ? "calendar" : "filter";
    }

    renderMobileButton() {
        return html`
            <atlas-button
                description="${this.description}"
                icon="${this.getIcon()}"
                type="outlined"
                @atlas-button-click=${this.openAdvancedFilter}
                ?block=${this.block}
            ></atlas-button>

            <atlas-offcanvas class="dropdown-wrapper" header=${this.header} ?open=${this._openAdvanced}>
                <slot name="advanced-filter" @slotchange=${this.modifyAdvancedFilterForm}></slot>
                <slot name="simple-filter" @slotchange=${this.modifySimpleFilterForm}></slot>
            </atlas-offcanvas>
        `;
    }

    renderButton() {
        return html`
            <atlas-dropdown-button
                description="${this.description}"
                icon="${this.getIcon()}"
                type="outlined"
                ?block=${this.block}
                filter
            >
                <slot name="simple-filter" @slotchange=${this.modifySimpleFilterForm}></slot>
            </atlas-dropdown-button>

            <atlas-offcanvas header=${this.header} ?open=${this._openAdvanced}>
                <slot name="advanced-filter" @slotchange=${this.modifyAdvancedFilterForm}></slot>
            </atlas-offcanvas>
        `;
    }

    render() {
        const button = when(
            !this._deviceController.isMobile,
            () => this.renderButton(),
            () => this.renderMobileButton()
        );

        return html` <div class="atlas-filter">${button}</div> `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-filter": AtlasFilter;
    }
}
