import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { map } from "lit/directives/map.js";
import { range } from "lit/directives/range.js";
import { when } from "lit/directives/when.js";

import { emit } from "@/internals/events";
import DeviceController from "@/controllers/device-controller";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-table-footer.scss";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-text/atlas-text";

export type TableFooterProps = AtlasElementProps & {
    "total-items": number;
    "items-per-page": number;
    "current-page": number;
    "footer-text": string;
};

/**
 * @dependency atlas-icon
 * @dependency atlas-text
 *
 * @prop {number} total-items - Número total de registros na tabela
 * @prop {number} items-per-page - Quantidade de registros que são exibidos por página
 * @prop {number} current-page - Página atual que está sendo visualizada
 * @prop {string} footer-text - Texto que aparece no rodapé, no lugar da informação do número de registros da página
 *
 * @tag atlas-table-footer
 */
@customElement("atlas-table-footer")
export default class AtlasTableFooter extends AtlasElement {
    static styles = styles;

    @property({ type: Number, attribute: "total-items" }) totalItems = 0;

    @property({ type: Number, attribute: "items-per-page" }) itemsPerPage = 10;

    @property({ type: Number, attribute: "current-page" }) currentPage = 1;

    @property({ type: String, attribute: "footer-text" }) footerText: string;

    private _deviceController = new DeviceController(this);

    getFirstIndexOfPage() {
        return (this.currentPage - 1) * this.itemsPerPage + 1;
    }

    getLastIndexOfPage() {
        const lastIndexOfPage = this.currentPage * this.itemsPerPage;

        return lastIndexOfPage > this.totalItems ? this.totalItems : lastIndexOfPage;
    }

    getTotalPages() {
        return Math.ceil(this.totalItems / this.itemsPerPage);
    }

    goToPage(event: PointerEvent) {
        const target = event.currentTarget as HTMLButtonElement;
        const { page } = target.dataset;

        if (page === "next") {
            this.currentPage += 1;
        } else if (page === "previous") {
            this.currentPage -= 1;
        } else {
            this.currentPage = parseInt(page, 10);
        }

        emit(this, "atlas-table-page-change", {
            detail: {
                page: this.currentPage
            }
        });
    }

    getVisiblePagesRange() {
        const totalPages = this.getTotalPages();
        let pagesRangeStart: number;
        let pagesRangeEnd: number;

        if (totalPages < 5) {
            pagesRangeStart = this._deviceController.isMobile ? 1 : 2;
            pagesRangeEnd = this._deviceController.isMobile ? totalPages : totalPages - 1;
        } else if (this._deviceController.isMobile) {
            pagesRangeStart = 1;
            pagesRangeEnd = 4;

            if (this.currentPage > 3 && this.currentPage < totalPages - 1) {
                pagesRangeStart = this.currentPage - 2;
                pagesRangeEnd = this.currentPage + 1;
            } else if (this.currentPage >= totalPages - 1) {
                pagesRangeStart = totalPages - 3;
                pagesRangeEnd = totalPages;
            }
        } else {
            pagesRangeStart = 2;
            pagesRangeEnd = 4;

            if (this.currentPage > 2 && this.currentPage < totalPages - 2) {
                pagesRangeStart = this.currentPage - 1;
                pagesRangeEnd = this.currentPage + 1;
            } else if (this.currentPage >= totalPages - 2) {
                pagesRangeStart = totalPages - 3;
                pagesRangeEnd = totalPages - 1;
            }
        }

        return {
            rangeStart: pagesRangeStart,
            rangeEnd: pagesRangeEnd
        };
    }

    renderPreviousButton() {
        return when(
            this.currentPage > 1,
            () => html`
                <button
                    class="pagination-item"
                    data-page="previous"
                    @click=${this.goToPage}
                    aria-label="Página anterior"
                >
                    <atlas-icon name="chevron-left" size=${this._deviceController.isMobile ? "3x" : "2x"}></atlas-icon>
                </button>
            `
        );
    }

    renderNextButton() {
        return when(
            this.currentPage < this.getTotalPages(),
            () => html`
                <button class="pagination-item" data-page="next" @click=${this.goToPage} aria-label="Próxima página">
                    <atlas-icon name="chevron-right" size=${this._deviceController.isMobile ? "3x" : "2x"}></atlas-icon>
                </button>
            `
        );
    }

    renderDotsButton(side: "left" | "right") {
        const totalPages = this.getTotalPages();
        let showButton = false;

        if (totalPages > 5) {
            if (side === "left") {
                showButton = this.currentPage > 3;
            } else {
                showButton = this.currentPage < totalPages - 2;
            }
        }

        return when(
            showButton && !this._deviceController.isMobile,
            () => html`
                <button class="pagination-item" disabled>
                    <atlas-icon name="three-dots-horinzontal" size="2x"></atlas-icon>
                </button>
            `
        );
    }

    renderFirstPageButton() {
        return when(
            !this._deviceController.isMobile,
            () => html`
                <button
                    class="pagination-item ${this.currentPage === 1 ? "active" : ""}"
                    data-page="1"
                    @click=${this.goToPage}
                    ?disabled=${this.currentPage === 1}
                    aria-label="Página 1"
                >
                    1
                </button>
            `
        );
    }

    renderLastPageButton() {
        const totalPages = this.getTotalPages();

        return when(
            !this._deviceController.isMobile,
            () => html`
                <button
                    class="pagination-item ${this.currentPage === totalPages ? "active" : ""}"
                    data-page="${totalPages}"
                    @click=${this.goToPage}
                    ?disabled=${this.currentPage === totalPages}
                    aria-label="Página ${totalPages}"
                >
                    ${totalPages}
                </button>
            `
        );
    }

    renderVisiblePages() {
        if (this.getTotalPages() < 3 && !this._deviceController.isMobile) {
            return null;
        }

        const { rangeStart, rangeEnd } = this.getVisiblePagesRange();

        return map(
            range(rangeStart, rangeEnd + 1),
            (page) => html`
                <button
                    class="pagination-item ${this.currentPage === page ? "active" : ""}"
                    data-page="${page}"
                    @click=${this.goToPage}
                    ?disabled=${this.currentPage === page}
                    aria-label="Página ${page}"
                >
                    ${page}
                </button>
            `
        );
    }

    renderPagination() {
        if (this.skeletonLoading) {
            return html`<div class="pagination skeleton"></div>`;
        }

        return when(
            this.getTotalPages() > 1,
            () => html`
                <div class="pagination">
                    ${this.renderPreviousButton()} ${this.renderFirstPageButton()} ${this.renderDotsButton("left")}
                    ${this.renderVisiblePages()} ${this.renderDotsButton("right")} ${this.renderLastPageButton()}
                    ${this.renderNextButton()}
                </div>
            `
        );
    }

    renderFooterText() {
        return when(
            !!this.footerText,
            () => this.footerText,
            () => `${this.getFirstIndexOfPage()}-${this.getLastIndexOfPage()} de ${this.totalItems}`
        );
    }

    render() {
        const footerClass = {
            "table-footer": true,
            "has-pagination": this.getTotalPages() > 1
        };

        return html`
            <div class=${classMap(footerClass)}>
                <div class="totalizer">
                    <atlas-text muted>${this.renderFooterText()}</atlas-text>
                </div>
                ${this.renderPagination()}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-table-footer": AtlasTableFooter;
    }
}
