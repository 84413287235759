import { html } from "lit";
import { property } from "lit/decorators.js";

import { when } from "lit/directives/when.js";
import { WithPopoverMixin, WithPopoverProps } from "@/internals/mixins/with-popover-mixin";
import FormElement, { FormElementProps } from "./form-element";

import "./atlas-helper-text/atlas-helper-text";
import "@/components/display/atlas-icon-button/atlas-icon-button";

export type FormControlProps = FormElementProps &
    WithPopoverProps & {
        "label": string;
        "helper-text": string;
    };

/**
 * @prop {string} label - A label que ficará acima do input determinando o nome do mesmo
 * @prop {string} helper-text - O texto auxiliar que pode ser utilizado no input
 * @prop {boolean} hide-optional - Indica se o texto (Opcional) deve ser oculto da label
 * @prop {string} popover-title - O título que será exibido no popover
 * @prop {string} popover-content - O conteúdo do popover
 */
export default class FormControl extends WithPopoverMixin(FormElement) {
    @property({ type: String }) label = "";

    @property({ type: String, attribute: "helper-text" }) helperText = "";

    @property({ type: Boolean, attribute: "hide-optional" }) hideOptional: boolean;

    protected _hasSlottedHelperText = false;

    onHelperTextSlotChange(isToggleHelper: boolean) {
        const helperTextSlot = this.shadowRoot.querySelector("slot[name=helper-text]") as HTMLSlotElement;
        const slottedHelperTexts = helperTextSlot
            .assignedElements()
            .filter((element) => element.tagName === "ATLAS-HELPER-TEXT");

        slottedHelperTexts.forEach((element) => {
            element.setAttribute("id", this.id);
            element.toggleAttribute("is-toggle-helper", isToggleHelper);
        });

        this._hasSlottedHelperText = slottedHelperTexts.length > 0;
    }

    renderHelperText(isToggleHelper?: boolean) {
        return when(
            this.helperText,
            () => html`
                <atlas-helper-text id=${this.id} ?is-toggle-helper=${isToggleHelper}>
                    ${this.helperText}
                </atlas-helper-text>
            `,
            () => html`
                <slot
                    name="helper-text"
                    @slotchange=${() => this.onHelperTextSlotChange(isToggleHelper || false)}
                ></slot>
            `
        );
    }

    renderLabelIcon() {
        return when(
            this.hasPopover(),
            () => html`
                <atlas-icon-button
                    icon="info"
                    theme="primary"
                    size="2x"
                    popover-title=${this.popoverTitle}
                    popover-content=${this.popoverContent}
                ></atlas-icon-button>
            `
        );
    }

    renderOptional() {
        return when(
            !this.required && !this.hideOptional,
            () => html`<span class="form-label-optional">(Opcional)</span>`
        );
    }

    renderLabel() {
        return when(
            this.label !== "",
            () => html`
                <label for="${this.id}" class="form-label">
                    ${this.label} ${this.renderOptional()} ${this.renderLabelIcon()}
                </label>
            `
        );
    }
}
