import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";
import escapeStringRegexp from "escape-string-regexp";

import { emit } from "@/internals/events";

import styles from "./atlas-select-item.scss";
import "../../display/atlas-icon/atlas-icon";

export type SelectItemProps = {
    "value": string;
    "label": string;
    "disabled": boolean;
    "selected": boolean;
    "highlighted-text": string;
};

/**
 * @prop {string} value - Valor do item
 * @prop {string} label - Texto do item
 * @prop {boolean} disabled - Indica se o item está desabilitado
 * @prop {boolean} selected - Indica se o item está selecionado
 * @prop {boolean} is-group-title - Indica se a opção é o título de um grupo
 * @prop {string} highlighted-text - Trecho da label ou do conteúdo extra que irá aparecer em destaque
 *
 * @tag atlas-select-item
 */
@customElement("atlas-select-item")
export default class AtlasSelectItem extends LitElement {
    static styles = styles;

    @property({ type: String }) value: string;

    @property({ type: String }) label: string;

    @property({ type: String }) icon: string;

    @property({ type: Boolean }) disabled: boolean;

    @property({ type: Boolean }) selected: boolean;

    @property({ type: Boolean }) focused: boolean;

    @property({ type: Boolean, attribute: "is-group-title" }) isGroupTitle: boolean;

    @property({ type: String, attribute: "highlighted-text" }) highlightedText: string;

    @property({ type: Array, attribute: null }) extraContent: string[];

    onItemClick(event: PointerEvent) {
        if (this.isGroupTitle) {
            event.stopPropagation();
        } else {
            emit(this, "atlas-select-item-click");
        }
    }

    renderHighLightedText(text: string) {
        if (!this.highlightedText || !text) {
            return text;
        }

        const regex = new RegExp(escapeStringRegexp(this.highlightedText), "gi");
        const parts = text.replace(regex, (match) => `::highlight::${match}::endhighlight::`).split("::highlight::");

        return parts.map((part) => {
            const subParts = part.split("::endhighlight::");

            if (subParts.length === 1) return part;

            return html`<span class="highlighted">${subParts[0]}</span>${subParts[1]}`;
        });
    }

    renderExtraContent() {
        return this.extraContent
            ?.filter((content) => !!content)
            .map((content) => html`<span class="extra-content">${this.renderHighLightedText(`${content}`)}</span>`);
    }

    render() {
        const itemClass = {
            "select-item": true,
            "selected": this.selected,
            "disabled": this.disabled,
            "focused": this.focused,
            "has-extra-content": this.extraContent?.length > 0,
            "is-group-title": this.isGroupTitle
        };

        return html`
            <button
                class=${classMap(itemClass)}
                @click=${this.onItemClick}
                ?disabled=${this.disabled}
                tabindex=${ifDefined(this.isGroupTitle ? "-1" : undefined)}
            >
                <span class="select-item-label">
                    ${when(!!this.icon, () => html`<atlas-icon name=${this.icon} size="2x"></atlas-icon>`)}
                    <span class="select-item-text">${this.renderHighLightedText(`${this.label}`)}</span>
                </span>
                ${this.renderExtraContent()}
            </button>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-select-item": AtlasSelectItem;
    }
}
