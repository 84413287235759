import { LitElement, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { Map as ImmutableMap } from "immutable";

import { when } from "lit/directives/when.js";
import { classMap } from "lit/directives/class-map.js";

import BaseController from "@/internals/base-controller";
import { emit } from "@/internals/events";
import { Theme } from "@/internals/theme";

import styles from "./atlas-wizard.scss";

import { closeAllAlerts } from "@/components/display/atlas-alert/alert-system";
import { BaseStepController, CustomStepButton } from "./types";
import AtlasWizardStep from "@/components/wizard/atlas-wizard-step/atlas-wizard-step";
import AtlasWizardFinishStep from "@/components/wizard/atlas-wizard-finish-step/atlas-wizard-finish-step";
import AtlasDropzone from "@/components/form/atlas-dropzone/atlas-dropzone";
import "@/components/display/atlas-button/atlas-button";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/wizard/atlas-stepper/atlas-stepper";

/**
 * @dependency atlas-wizard-step
 * @dependency atlas-button
 * @dependency atlas-icon
 * @dependency atlas-stepper
 *
 * @slot - Usado para incluir o conteúdo da wizard
 *
 * @prop {string} header - Título da wizard
 * @prop {string} header-image - Imagem que irá aparecer no cabeçalho da wizard, no lugar do título
 * @prop {string} header-image-description - Texto descritivo da imagem do cabeçalho
 * @prop {boolean} hide-stepper - Indica se o stepper da wizard vai ficar escondido
 * @prop {boolean} hide-close-button - Indica se o botão de fechar a wizard, que aparece no rodapé, deve ficar oculto
 * @prop {boolean} hide-header-close-button - Indica se o botão de fechar a wizard, que aparece no cabeçalho, deve ficar oculto
 * @prop {boolean} disable-return - Indica se as ações de voltar da wizard serão desabilitadas
 * @prop {string} finish-button-label - Texto do botão de "Finalizar" da wizard
 * @prop {Theme} finish-button-theme - Tema do botão de "Finalizar" da wizard
 *
 * @event {CustomEvent} atlas-wizard-change-step - Evento disparado quando é alterado de passo
 * @event {CustomEvent} atlas-wizard-previous-step - Evento disparado quando é clicado no botão "Anterior" para voltar um passo
 * @event {CustomEvent} atlas-wizard-next-step - Evento disparado quando é clicado no botão "Próximo" para avançar um passo
 * @event {CustomEvent} atlas-wizard-close - Evento disparado quando é clicado nos botões de fechar da wizard
 * @event {CustomEvent} atlas-wizard-finish - Evento disparado quando é clicado nos botões de "Finalizar" da wizard
 *
 * @tag atlas-wizard
 */
@customElement("atlas-wizard")
export default class AtlasWizard extends LitElement {
    static styles = styles;

    @property({ type: String }) header: string;

    @property({ type: String, attribute: "header-image" }) headerImage: string;

    @property({ type: String, attribute: "header-image-description" }) headerImageDescription: string;

    @property({ type: Boolean, attribute: "hide-stepper" }) hideStepper = false;

    @property({ type: Boolean, attribute: "hide-close-button" }) hideCloseButton = false;

    @property({ type: Boolean, attribute: "hide-header-close-button" }) hideHeaderCloseButton = false;

    @property({ type: Boolean, attribute: "disable-return" }) disableReturn: boolean = false;

    @property({ type: String, attribute: "finish-button-label" }) finishButtonLabel: string = "Finalizar";

    @property({ type: String, attribute: "finish-button-theme" }) finishButtonTheme: Theme = "success";

    @state() private _steps: AtlasWizardStep[] = [];

    @state() private _currentStep: string;

    @state() private _currentStepIndex: number = 0;

    @state() private _stepControllers: Map<string, BaseController> = new Map();

    @state() private _wizardConfig: ImmutableMap<string, any> = ImmutableMap();

    connectedCallback(): void {
        super.connectedCallback?.();

        this.addEventListener("atlas-wizard-step-go", this.goToStep);
        this.addEventListener("atlas-wizard-step-changed", this.syncSteps);
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-wizard-step-go", this.goToStep);
        this.removeEventListener("atlas-wizard-step-changed", this.syncSteps);
    }

    setWizardConfig(config: object) {
        this._wizardConfig = ImmutableMap(config);
    }

    getCurrentStep() {
        return this.getStepReference(this._currentStep);
    }

    getWizardConfig() {
        return this._wizardConfig;
    }

    getStepController(stepName: string) {
        return this._stepControllers.get(stepName);
    }

    getStepControllerByIndex(stepIndex: number) {
        return this.getStepController(this._steps[stepIndex].name);
    }

    getStepReference(stepName: string) {
        return this.getStepController(stepName)?.stepReference;
    }

    setStepState(stepName: string, key: string | { [key: string]: any }, value?: any) {
        this.getStepController(stepName)?.setState(key, value);
    }

    getStepState(stepName: string) {
        return this.getStepController(stepName)?.getState();
    }

    clearStepState(stepName: string, key: string | string[]) {
        this.getStepController(stepName)?.clearState(key);
    }

    getFullState(merged?: boolean) {
        const fullState: { [key: string]: any } = {};

        this._stepControllers.forEach((stepController) => {
            fullState[stepController.stepReference.name] = stepController.stepState;
        });

        return !merged ? fullState : Object.values(fullState).reduce((acc, cur) => ({ ...acc, ...cur }), {});
    }

    async onChangeSlot() {
        await this.updateComplete;

        this.syncSteps();
        if (this._steps.length === 0) return;

        this._steps.forEach((step) => {
            let stepController = this.getStepController(step.name);

            if (stepController) {
                stepController.setStepReference(step);
            } else {
                stepController = new BaseController();
                stepController.setStepReference(step);

                this._stepControllers.set(step.name, stepController);
            }

            step.setStepController(stepController);
            stepController.init();
        });

        const activeStep = this._steps.find((step) => step.active && !step.disabled);
        this.setActiveStep(activeStep?.name);
    }

    setActiveStep(activeStepName: string) {
        const activeStepIndex = this.getStepIndex(activeStepName);
        this._currentStepIndex = activeStepIndex >= 0 ? activeStepIndex : 0;
        this.changeStep(this._currentStepIndex);
    }

    syncSteps() {
        const stepElements = ["ATLAS-WIZARD-STEP", "ATLAS-WIZARD-INTRO-STEP", "ATLAS-WIZARD-FINISH-STEP"];
        const defaultSlot = this.shadowRoot.querySelector("slot:not([name])") as HTMLSlotElement;

        this._steps = defaultSlot
            .assignedElements()
            .filter((element) => stepElements.includes(element.tagName))
            .map((step: AtlasWizardStep) => step);
    }

    getStepIndex(stepName: string) {
        return this._steps.findIndex((step) => step.name === stepName);
    }

    addStepController(stepName: string, customController: BaseStepController) {
        let stepController: BaseController;

        if (this._stepControllers.has(stepName)) {
            stepController = this.getStepController(stepName);
            stepController.setCustomController(customController);
        } else {
            stepController = new BaseController();
            stepController.setCustomController(customController);

            this._stepControllers.set(stepName, stepController);
        }

        stepController.init();
    }

    showCurrentStep() {
        this._steps.forEach((step: AtlasWizardStep) => {
            const isVisible = step.name === this._currentStep;

            step.toggleStep(isVisible);
            this.toggleDropzoneEvents(step, isVisible);

            if (isVisible) {
                this.getStepController(step.name).onShowStep();
            }
        });
    }

    getNextEnabledIndex(stepIndex: number): number {
        const nextStepIndex = stepIndex;

        if (!this._steps[stepIndex].disabled) {
            return nextStepIndex;
        }

        return this.getNextEnabledIndex(stepIndex < this._currentStepIndex ? stepIndex - 1 : stepIndex + 1);
    }

    async canChangeStep(stepIndex: number, ignoreValidation?: boolean): Promise<boolean> {
        if (stepIndex <= this._currentStepIndex || ignoreValidation) {
            return true;
        }

        const stepController = this.getStepControllerByIndex(this._currentStepIndex);
        const isValid = await stepController.validate();

        return isValid;
    }

    async changeStep(stepIndex: number, elementToScroll?: string, ignoreValidation?: boolean) {
        const canChange = await this.canChangeStep(stepIndex, ignoreValidation);

        if (!canChange) return;

        if (stepIndex > this._currentStepIndex) {
            const hasSubmitted = await this.getStepControllerByIndex(this._currentStepIndex).onSubmitStep();

            if (!hasSubmitted) return;
        }

        const previousStepIndex = this._currentStepIndex;
        this._currentStepIndex = this.getNextEnabledIndex(stepIndex);
        this._currentStep = this._steps[this._currentStepIndex].name;

        this.getStepControllerByIndex(this._currentStepIndex).setElementToScroll(elementToScroll);
        this.showCurrentStep();

        closeAllAlerts();

        emit(this, "atlas-wizard-change-step", {
            detail: {
                step: this._steps[this._currentStepIndex],
                previousStep: this._steps[previousStepIndex]
            }
        });
    }

    previousStep() {
        this.changeStep(this._currentStepIndex - 1);
        emit(this, "atlas-wizard-previous-step");
    }

    nextStep(ignoreValidation?: boolean) {
        this.changeStep(this._currentStepIndex + 1, null, ignoreValidation);
        emit(this, "atlas-wizard-next-step");
    }

    closeWizard() {
        emit(this, "atlas-wizard-close");
    }

    async finishWizard() {
        const stepController = this.getStepControllerByIndex(this._currentStepIndex);

        const isValid = await stepController.validate();
        if (!isValid) return;

        const hasSubmitted = await stepController.onSubmitStep();
        if (!hasSubmitted) return;

        emit(this, "atlas-wizard-finish", {
            detail: {
                steps: this._steps.filter((step) => !step.disabled)
            }
        });
    }

    goToStep(event: CustomEvent) {
        event.stopPropagation();

        const { step, element } = event.detail;
        const stepName = step || (event.target as AtlasWizardStep).name;

        this.goToStepWithName(stepName, element);
    }

    goToStepWithName(stepName: string, elementToScroll?: string) {
        const stepIndex = this.getStepIndex(stepName);

        this.changeStep(stepIndex, elementToScroll);
    }

    toggleDropzoneEvents(step: AtlasWizardStep, isVisible: boolean) {
        step.querySelectorAll("atlas-dropzone").forEach((dropzone: AtlasDropzone) => {
            if (isVisible) {
                dropzone.getDropzoneInstance().enable();
            } else {
                dropzone.getDropzoneInstance().disable();
            }
        });
    }

    isReturnDisabled() {
        return this.disableReturn || this.getStepReference(this._currentStep)?.disableReturn;
    }

    isIntroOrFinishStep() {
        return this.getStepReference(this._currentStep)?.isOffStep();
    }

    finishStepIsOnlyInfo() {
        const lastStepName = this._steps[this._steps.length - 1].name;
        const lastStep = this.getStepReference(lastStepName);

        if (!lastStep.isOffStep()) return false;

        return (lastStep as AtlasWizardFinishStep).onlyInfo;
    }

    shouldRenderCloseButton() {
        return !this.hideCloseButton && this._currentStepIndex === 0;
    }

    shouldRenderBackButton() {
        const stepReference = this.getStepReference(this._currentStep);

        if (stepReference?.hideButtons || this.isReturnDisabled()) {
            return false;
        }

        return this._currentStepIndex > 0 && this._steps.length > 2;
    }

    shouldRenderFinishButton() {
        const stepReference = this.getStepReference(this._currentStep);

        if (this._steps.length <= 0 || stepReference?.hideButtons) return false;

        let lastStepIndex = this._steps.length - 1;

        if (this.finishStepIsOnlyInfo()) lastStepIndex = this._steps.length - 2;

        return this._currentStepIndex === lastStepIndex;
    }

    shouldRenderNextButton() {
        if (this._steps.length <= 1) return false;

        let lastStepIndex = this._steps.length - 1;

        if (this.finishStepIsOnlyInfo()) lastStepIndex = this._steps.length - 2;

        return this._currentStepIndex !== lastStepIndex;
    }

    renderStepper() {
        return when(
            !this.hideStepper && this._steps.length > 0 && !this.isIntroOrFinishStep(),
            () => html`
                <atlas-stepper
                    .steps=${this._steps}
                    current-step=${this._currentStep}
                    current-step-index=${this._currentStepIndex}
                    ?disable-return=${this.isReturnDisabled()}
                ></atlas-stepper>
            `
        );
    }

    renderCloseButton() {
        return when(
            this.shouldRenderCloseButton(),
            () => html`
                <atlas-button
                    size="md"
                    theme="secondary"
                    description="Fechar"
                    @atlas-button-click=${this.closeWizard}
                ></atlas-button>
            `
        );
    }

    renderBackButton() {
        return when(
            this.shouldRenderBackButton(),
            () => html`
                <atlas-button
                    size="md"
                    theme="secondary"
                    description="Voltar"
                    @atlas-button-click=${this.previousStep}
                ></atlas-button>
            `
        );
    }

    renderNextButton() {
        const stepReference = this.getStepReference(this._currentStep);

        return when(
            this.shouldRenderNextButton(),
            () => html`
                <atlas-button
                    size="md"
                    theme=${stepReference?.nextButtonTheme || "primary"}
                    description=${stepReference?.nextButtonLabel || "Avançar"}
                    @atlas-button-click=${() => this.nextStep()}
                ></atlas-button>
            `
        );
    }

    renderFinishButton() {
        return when(
            this.shouldRenderFinishButton(),
            () => html`
                <atlas-button
                    size="md"
                    theme=${this.finishButtonTheme || "success"}
                    description=${this.finishButtonLabel || "Finalizar"}
                    @atlas-button-click=${this.finishWizard}
                ></atlas-button>
            `
        );
    }

    renderExtraButtons() {
        const extraButtons = this.getStepController(this._currentStep)?.getExtraButtons() || [];

        return extraButtons.map(
            (customButton: CustomStepButton) => html`
                <atlas-button
                    size="md"
                    theme="secondary"
                    description=${customButton.description}
                    @atlas-button-click=${customButton.click}
                ></atlas-button>
            `
        );
    }

    renderHeader() {
        if (this.headerImage) {
            return html`<img src=${this.headerImage} alt=${this.headerImageDescription} />`;
        }

        if (this.header) {
            return html`<h1>${this.header}</h1>`;
        }

        return html`
            <div class="slotted-header">
                <slot name="header"></slot>
            </div>
        `;
    }

    renderHeaderCloseButton() {
        return when(
            !this.hideHeaderCloseButton,
            () => html`
                <button @click=${this.closeWizard} class="wizard-close-button" aria-label="Fechar">
                    <atlas-icon name="x" size="3x"></atlas-icon>
                </button>
            `
        );
    }

    render() {
        const bodyClass = {
            "wizard-body": true,
            "centralized-content": this.getCurrentStep()?.centralizedContent
        };

        return html`
            <div class="wizard">
                <header class="wizard-header">
                    <div class="wizard-header-title">${this.renderHeader()}${this.renderHeaderCloseButton()}</div>
                </header>
                <main class=${classMap(bodyClass)}>
                    ${this.renderStepper()}
                    <div class="container">
                        <slot @slotchange=${this.onChangeSlot}></slot>
                    </div>
                </main>
                <footer class="wizard-footer">
                    ${this.renderCloseButton()} ${this.renderBackButton()} ${this.renderExtraButtons()}
                    ${this.renderNextButton()} ${this.renderFinishButton()}
                </footer>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-wizard": AtlasWizard;
    }
}
