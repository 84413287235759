import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { WithPopoverMixin, WithPopoverProps } from "@/internals/mixins/with-popover-mixin";
import { WithCollapseMixin, WithCollapseProps } from "@/internals/mixins/with-collapse-mixin";

import { getAllFormElements } from "@/helpers/form";
import { emit } from "@/internals/events";
import FormElement from "@/components/form/form-element";

import { HeadingSize } from "@/components/display/atlas-heading/types";
import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import AtlasElementGroup from "@/components/layout/atlas-element-group/atlas-element-group";

import styles from "./atlas-section.scss";
import "@/components/layout/atlas-collapse/atlas-collapse";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-heading/atlas-heading";

const ComposedClass = WithPopoverMixin(WithCollapseMixin(AtlasElement));

export type SectionProps = AtlasElementProps &
    WithPopoverProps &
    WithCollapseProps & {
        "header": string;
        "description": string;
        "optional": boolean;
        "header-size": HeadingSize;
        "is-faq": boolean;
    };

/**
 * @prop {string} header - Título da seção
 * @prop {string} description - Descrição da seção
 * @prop {boolean} optional - Indica se a seção é opcional
 * @prop {HeadingSize} header-size - Define o tamanho da tipografia do header
 * @prop {boolean} is-faq - Define se a seção é de faq
 *
 *  @slot - Slot padrão onde vai o conteúdo da section
 *  @slot description - Slot onde pode ser incluido a descrição da section - o parâmetro description vai ser priorizado
 *
 * @tag atlas-section
 */
@customElement("atlas-section")
export default class AtlasSection extends ComposedClass {
    static styles = styles;

    @property({ type: String }) header: string;

    @property({ type: String, attribute: "header-size" }) headerSize: HeadingSize = "h4";

    @property({ type: String }) description: string;

    @property({ type: Boolean, reflect: true }) optional = false;

    @property({ type: Boolean, attribute: "is-faq" }) isFaq = false;

    @state() private _hasSlottedDescription = false;

    @state() private _hasSlottedSubheading = false;

    @state() private _hasSlottedAction = false;

    connectedCallback() {
        super.connectedCallback?.();

        this.updateOptionalState = this.updateOptionalState.bind(this);
        this.addEventListener("atlas-form-element-required-change", this.updateOptionalState);
        this.addEventListener("atlas-collapse-button-click", this.emitSectionEvent);

        if (this.collapsible) this.collapsed = true;

        this.updateComplete.then(() => {
            this.updateOptionalState();
        });
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-form-element-required-change", this.updateOptionalState);
        this.removeEventListener("atlas-collapse-button-click", this.emitSectionEvent);
    }

    emitSectionEvent() {
        emit(this, `atlas-section-${this.collapsed ? "collapse" : "expand"}`);
    }

    open() {
        if (!this.collapsible) return;

        this.collapsed = false;

        this.emitSectionEvent();
    }

    close() {
        if (!this.collapsible) return;

        this.collapsed = true;

        this.emitSectionEvent();
    }

    toggle() {
        if (!this.collapsible) return;

        if (this.collapsed) {
            this.open();
        } else {
            this.close();
        }
    }

    async updateOptionalState() {
        await this.updateComplete;

        const formElements = getAllFormElements(this);

        if (formElements.length <= 0) {
            this.optional = false;
            return;
        }

        const allOptional = formElements.every((element: FormElement) => {
            if (element instanceof AtlasElementGroup) {
                return element.requiredFields === 0;
            }

            return !element.required;
        });

        this.optional = allOptional;

        formElements.forEach((element: FormElement) => {
            element.toggleAttribute("hide-optional", allOptional);
        });
    }

    onDescriptionChange() {
        const descriptionSlot = this.shadowRoot.querySelector("slot[name=description]") as HTMLSlotElement;
        if (!descriptionSlot) return;

        const slottedText = descriptionSlot.assignedElements()?.[0];

        slottedText?.toggleAttribute("muted");
        this._hasSlottedDescription = !!slottedText;
    }

    onActionSlotChange() {
        const actionSlot = this.shadowRoot.querySelector("slot[name=action]") as HTMLSlotElement;

        this._hasSlottedAction = actionSlot?.assignedElements().length > 0;
    }

    onSubheadingSlotChange() {
        const subheadingSlot = this.shadowRoot.querySelector("slot[name=subheading]") as HTMLSlotElement;

        this._hasSlottedSubheading = subheadingSlot?.assignedElements().length > 0;
    }

    renderActionSlot() {
        return html`
            <div class="section-action" style="${!this._hasSlottedAction ? "display: none;" : ""}">
                <slot name="action" @slotchange=${this.onActionSlotChange}></slot>
            </div>
        `;
    }

    renderSubheadingSlot() {
        return html`
            <div class="section-subheading" style="${!this._hasSlottedSubheading ? "display: none;" : ""}">
                <slot name="subheading" @slotchange=${this.onSubheadingSlotChange}></slot>
            </div>
        `;
    }

    renderHeading() {
        const headerSize = this.isFaq ? "h6" : this.headerSize;

        return html`
            <atlas-heading
                size="${headerSize}"
                popover-title=${this.popoverTitle}
                popover-content=${this.popoverContent}
            >
                ${this.header}${this.optional ? " (Opcional)" : ""}
            </atlas-heading>
        `;
    }

    renderCollapsibleHeader() {
        return html`
            <button class="section-header" @click=${this.toggle}>
                <div class="section-header-items">
                    <div class="section-header-title">
                        ${this.renderHeading()}
                        ${this.renderCollapseButton("primary", this.isFaq ? "primary" : "secondary")}
                    </div>
                    ${this.renderActionSlot()}
                </div>

                ${this.renderSubheadingSlot()}
            </button>
        `;
    }

    renderDefaultHeader() {
        return html`
            <div class="section-header">
                <div class="section-header-items">
                    <div class="section-header-title">${this.renderHeading()}</div>
                    ${this.renderActionSlot()}
                </div>

                ${this.renderSubheadingSlot()}
            </div>
        `;
    }

    renderHeader() {
        return when(
            this.collapsible,
            () => this.renderCollapsibleHeader(),
            () => this.renderDefaultHeader()
        );
    }

    renderDescription() {
        if (this.description) {
            return html`<p class="section-description">${this.description}</p>`;
        }

        return html`
            <div class="section-description" style="${!this._hasSlottedDescription ? "display: none;" : ""}">
                <slot name="description" @slotchange=${this.onDescriptionChange}></slot>
            </div>
        `;
    }

    render() {
        const sectionClass = {
            "section": true,
            "collapsible": this.collapsible,
            "is-faq": this.isFaq
        };

        return html`
            <div class=${classMap(sectionClass)}>
                ${this.renderHeader()}
                <div class="section-body">
                    ${this.renderContentWithCollapse(html`
                        ${this.renderDescription()}
                        <div class="section-content">
                            <slot></slot>
                        </div>
                    `)}
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-section": AtlasSection;
    }
}
