import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { styleMap } from "lit/directives/style-map.js";
import { when } from "lit/directives/when.js";
import { ModifierArguments, detectOverflow } from "@popperjs/core";

import DeviceController from "@/controllers/device-controller";
import OverlayElement, { OverlayElementProps } from "@/components/display/overlay-element";

import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-desktop-dropdown/atlas-desktop-dropdown";
import "@/components/display/atlas-navbar-dropdown/atlas-navbar-dropdown";
import "@/components/display/atlas-bottom-sheet/atlas-bottom-sheet";

export type DropdownProps = OverlayElementProps & {
    "header": string;
    "width": number;
    "max-width": string;
    "max-height": number;
    "spaced-content": boolean;
    "no-gap": boolean;
    "auto-close": boolean;
    "auto-close-trigger": "any" | "inside" | "outside";
    "overflow": boolean;
    "loading": boolean;
    "loading-text": string;
    "atlas-dropdown-opened": Function;
    "atlas-dropdown-closed": Function;
};

/**
 * Dropdowns servem para exibir conteúdos dentro de uma caixa, que sobrepõe os outros elementos da tela, dando destaque ao seu conteúdo
 *
 * @dependency atlas-icon
 * @dependency atlas-desktop-dropdown
 * @dependency atlas-navbar-dropdown
 * @dependency atlas-bottom-sheet
 *
 * @prop {boolean} open - Indica se o dropdown está aberto
 * @prop {boolean} disabled - Indica se o dropdown está desabilitado
 * @prop {OverlayPlacement} placement - A posição do dropdown em relação ao elemento que o acionou
 * @prop {OverlayTrigger} trigger - O gatilho que irá acionar o dropdown
 * @prop {string} header - Título do dropdown.
 * @prop {number} width - Largura do dropdown. (Caso não seja passada, é definida baseada no tamanho do seu conteúdo, respeitando o mínimo de `160` e o máximo de `540`)
 * @prop {string} max-width - Largura máxima do dropdown.
 * @prop {number} max-height - Altura máxima do dropdown.
 * @prop {boolean} spaced-content - Indica se o conteúdo deve possui um espaçamento interno, alinhando com o título.
 * @prop {boolean} no-gap - Indica se os conteúdos do dropdown não devem possuir espaçamento entre eles, ex: lista de itens.
 * @prop {boolean} mobile-fullscreen - (Bottom sheet) Indica se na versão bottom sheet do dropdown ele irá abrir em tela cheia
 * @prop {boolean} auto-close - Booleano que indica se o comportamento de fechamento automático está habilitado
 * @prop {"any" | "inside" | "outside"} auto-close-trigger - String que indica onde deve ser clicado para fechar o overlay (Apenas quando a flag de auto-close está habilitada)
 * @prop {boolean} overflow - Coloca o overflow como visible para casos que precisamos de algo que extrapole o container
 * @prop {boolean} loading - Indica se o dropdown está em estado de loading
 * @prop {string} loading-text - Texto que deve ser exibido quando o dropdown está em loading
 *
 * @slot - Slot padrão usado para definir o conteúdo do dropdown
 * @slot header - Slot para adicionar um conteúdo extra no cabeçalho (Apenas para o bottom sheet)
 *
 * @event {CustomEvent} atlas-dropdown-opened - Evento disparado quando o dropdown é aberto
 * @event {CustomEvent} atlas-dropdown-closed - Evento disparado quando o dropdown é fechado
 *
 * @tag atlas-dropdown
 */
@customElement("atlas-dropdown")
export default class AtlasDropdown extends OverlayElement {
    @property({ type: String }) header: string;

    @property({ type: Number }) width: number;

    @property({ type: String, attribute: "max-width" }) maxWidth: string;

    @property({ type: Number, attribute: "max-height" }) maxHeight: number;

    @property({ type: Boolean, attribute: "spaced-content" }) spacedContent: boolean;

    @property({ type: Boolean, attribute: "no-gap" }) noGap: boolean;

    @property({ type: Boolean, attribute: "mobile-fullscreen" }) mobileFullScreen: boolean;

    @property({ type: Boolean, attribute: "auto-close" }) autoClose: boolean;

    @property({ type: String, attribute: "auto-close-trigger" }) autoCloseTrigger: "any" | "inside" | "outside" = "any";

    @property({ type: Boolean }) overflow: boolean;

    @property({ type: Boolean }) loading: boolean;

    @property({ type: String, attribute: "loading-text" }) loadingText: string;

    @state() private _onNavbarContext = false;

    @state() private _hasSlottedSubheading = false;

    @state() private _modifierMaxHeight: number;

    private _deviceController = new DeviceController(this);

    constructor() {
        super("dropdown", "fixed", "click", "bottom-start");

        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onSyncTriggerElement = this.onSyncTriggerElement.bind(this);
    }

    connectedCallback(): void {
        super.connectedCallback?.();
        this.addEventListener("atlas-changed-is-new", this.onSyncTriggerElement);
        this.addEventListener("atlas-dropdown-trigger-close", this.hide);
        this.addEventListener("atlas-bottom-sheet-close", this.hide);
        this.addEventListener("atlas-open-advanced-filter", this.hide);
        this.addEventListener("atlas-filter-close", this.hide);

        this.updateComplete.then(() => {
            this._onNavbarContext = !!this.closest("atlas-navbar");
        });
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();
        this.removeEventListener("atlas-changed-is-new", this.onSyncTriggerElement);
        this.removeEventListener("atlas-dropdown-trigger-close", this.hide);
        this.removeEventListener("atlas-bottom-sheet-close", this.hide);
        this.removeEventListener("atlas-open-advanced-filter", this.hide);
        this.removeEventListener("atlas-filter-close", this.hide);
        this.onCloseOverlay();
    }

    isOnNavbarContext() {
        return this._onNavbarContext && this._deviceController.isMobile;
    }

    isBottomSheet() {
        return !this._onNavbarContext && this._deviceController.isMobile;
    }

    /**
     * @override
     */
    getOverlayElement(): HTMLElement {
        return this.shadowRoot.querySelector(".dropdown-wrapper");
    }

    /**
     * @override
     */
    onSyncTriggerElement(): void {
        setTimeout(() => {
            if (this._triggerElement.tagName === "ATLAS-AVATAR") {
                const defaultSlot = this.shadowRoot.querySelector("slot:not([name])") as HTMLSlotElement;
                const dropdownItems = defaultSlot.assignedElements({ flatten: true });
                const hasItemWithNewTag = dropdownItems.some((item) => item.hasAttribute("is-new"));

                this._triggerElement.toggleAttribute("show-badge", hasItemWithNewTag);
            }
        }, 0);
    }

    /**
     * @override
     */
    getPopperModifiers(): Array<object> {
        const modifiers = super.getPopperModifiers();
        const isDesktop = !this.isOnNavbarContext() && !this.isBottomSheet();

        const fullScreenModifier = {
            name: "fullScreenModifier",
            enabled: !isDesktop,
            phase: "afterWrite",
            fn: () => {
                this.getOverlayElement().style.top = "0px";
                this.getOverlayElement().style.left = "0px";
                this.getOverlayElement().style.transform = "none";
            }
        };

        const inputTriggerModifier = {
            name: "inputTriggerModifier",
            enabled: isDesktop && this._triggerElement.tagName === "INPUT",
            phase: "afterWrite",
            fn: () => {
                this.width = this._triggerElement.getBoundingClientRect().width;
                this.maxWidth = "unset";
            }
        };

        const maxSizeModifier = {
            name: "maxSizeModifier",
            enabled: isDesktop,
            phase: "main",
            requiresIfExists: ["offset", "preventOverflow", "flip"],
            fn: ({ state: popperState, name, options }: ModifierArguments<{}>) => {
                const overflow = detectOverflow(popperState, options);
                const { x, y } = popperState.modifiersData.preventOverflow || { x: 0, y: 0 };
                const { width, height } = popperState.rects.popper;
                const [basePlacement] = popperState.placement.split("-");

                const widthProp = basePlacement === "left" ? "left" : "right";
                const heightProp = basePlacement === "top" ? "top" : "bottom";

                const maxHeight = height - overflow[heightProp] - y - 8;
                const maxWidth = width - overflow[widthProp] - x - 8;

                /* eslint-disable no-param-reassign */
                popperState.modifiersData[name] = {
                    width: maxWidth,
                    height: maxHeight
                };
                /* eslint-enable no-param-reassign */

                this._modifierMaxHeight = maxHeight < this.maxHeight ? maxHeight : null;
            }
        };

        return [...modifiers, fullScreenModifier, inputTriggerModifier, maxSizeModifier];
    }

    /**
     * @override
     */
    onOpenOverlay() {
        document.addEventListener("click", this.onDocumentClick);
        document.body.classList.toggle("disable-scroll", this.isOnNavbarContext() || this.isBottomSheet());

        this._triggerElement.setAttribute("active", "");
        this._triggerElement.setAttribute("aria-expanded", "true");
    }

    /**
     * @override
     */
    onCloseOverlay() {
        document.removeEventListener("click", this.onDocumentClick);
        document.body.classList.remove("disable-scroll");

        this._triggerElement.removeAttribute("active");
        this._triggerElement.setAttribute("aria-expanded", "false");
    }

    onDocumentClick(event: PointerEvent) {
        if (!this.autoClose || !this.open || this.isBottomSheet()) {
            return;
        }

        const composedPath = event.composedPath();
        const isToggleTarget = composedPath.includes(this._triggerElement);
        const isMenuTarget = composedPath.includes(this.getOverlayElement());

        if (
            isToggleTarget ||
            (this.autoCloseTrigger === "inside" && !isMenuTarget) ||
            (this.autoCloseTrigger === "outside" && isMenuTarget)
        ) {
            return;
        }

        this.hide();
    }

    onSubheadingChange() {
        const subheadingSlot = this.shadowRoot.querySelector("slot[name=subheading]") as HTMLSlotElement;

        this._hasSlottedSubheading = subheadingSlot.assignedElements().length > 0;
    }

    renderLoading() {
        return when(
            this.loading,
            () => html`<atlas-dropdown-item loading>${this.loadingText || "Carregando opções"}</atlas-dropdown-item>`
        );
    }

    renderDropdownContent() {
        const defaultSlotStyle = {
            display: this.loading ? "none" : "contents"
        };

        return html`
            <slot name="subheading" slot="subheading" @slotchange=${this.onSubheadingChange}></slot>
            ${this.renderLoading()}
            <slot style=${styleMap(defaultSlotStyle)}></slot>
        `;
    }

    render() {
        const dropdownClass = {
            "dropdown-wrapper": true,
            "loading": this.loading,
            "has-subheading": this._hasSlottedSubheading
        };

        if (this.isOnNavbarContext()) {
            return html`
                <atlas-navbar-dropdown
                    class=${classMap(dropdownClass)}
                    header=${this.header}
                    ?spaced-content=${this.spacedContent}
                    ?no-gap=${this.noGap}
                    ?open=${this.open}
                >
                    ${this.renderDropdownContent()}
                </atlas-navbar-dropdown>
            `;
        }

        if (this.isBottomSheet()) {
            return html`
                <atlas-bottom-sheet
                    class=${classMap(dropdownClass)}
                    header=${this.header}
                    ?open=${this.open}
                    ?fullscreen=${this.mobileFullScreen}
                >
                    ${this.renderDropdownContent()}
                </atlas-bottom-sheet>
            `;
        }

        return html`
            <atlas-desktop-dropdown
                class=${classMap(dropdownClass)}
                header=${this.header}
                width=${this.width}
                max-width=${this.maxWidth}
                max-height=${this._modifierMaxHeight || this.maxHeight}
                ?spaced-content=${this.spacedContent}
                ?no-gap=${this.noGap}
                ?open=${this.open}
                ?scale-vertically=${this._triggerElement?.tagName === "INPUT"}
                ?overflow=${this.overflow}
            >
                ${this.renderDropdownContent()}
            </atlas-desktop-dropdown>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-dropdown": AtlasDropdown;
    }
}
