import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import AtlasElement from "@/components/atlas-element";
import DeviceController from "@/controllers/device-controller";
import type { AtlasElementProps } from "@/components/atlas-element";

import { Alignment, Justify } from "./types";

import styles from "./atlas-layout.scss";

export type LayoutProps = AtlasElementProps & {
    "gap": number;
    "auto": boolean;
    "inline": boolean;
    "fluid": boolean;
    "alignment": Alignment;
    "justify": Justify;
    "mobile-inline": boolean;
    "mobile-gap": number;
};

/**
 * @prop {number} gap - Propriedade que define o espaco entre elementos (Deve ser um número entre 0 e 9)
 * @prop {boolean} auto - Propriedade que se ativa transforma o layout para disposição automática
 * @prop {boolean} inline - Propriedade que se ativa transforma o layout para disposição em linha
 * @prop {Alignment} alignment - Alinhamento dos itens do layout
 * @prop {Justify} justify - Justificação dos itens do layout
 * @prop {boolean} mobileInline - Indica se mesmo no mobile, os itens devem permanecer lado a lado
 * @prop {number} mobileGap - Propriedade que define o espaco entre elementos (Deve ser um número entre 0 e 9), no mobile
 * @prop {boolean} fluid - Indica se o layout deve ser fluido(se encaixar inteiramente no container pai)
 *
 * @tag atlas-layout
 */
@customElement("atlas-layout")
export default class AtlasLayout extends AtlasElement {
    static styles = styles;

    @property({ type: Number }) gap: number;

    @property({ type: Boolean }) auto: boolean;

    @property({ type: Boolean }) inline: boolean;

    @property({ type: Boolean }) fluid: boolean;

    @property({ type: String }) alignment: Alignment;

    @property({ type: String }) justify: Justify;

    @property({ type: Number, attribute: "mobile-gap" }) mobileGap: number;

    @property({ type: Boolean, attribute: "mobile-inline" }) mobileInline: boolean;

    protected _deviceController = new DeviceController(this, this.onSlotChange.bind(this));

    getIsInline() {
        return (
            (!this._deviceController.isMobile && this.inline) || (this._deviceController.isMobile && this.mobileInline)
        );
    }

    async onSlotChange() {
        await this.updateComplete;

        const slottedElements = this.shadowRoot.querySelector("slot").assignedElements();
        const buttonElements = ["ATLAS-BUTTON", "ATLAS-DROPDOWN-BUTTON"];

        slottedElements
            .filter((element) => buttonElements.includes(element.tagName))
            .forEach((element) => {
                element.toggleAttribute("block", this._deviceController.isMobile);
            });
    }

    render() {
        const layoutClass = {
            "atlas-layout": true,
            "inline": this.getIsInline(),
            "mobile": this._deviceController.isMobile,
            "fluid": this.fluid,
            [`align-${this.alignment}`]: !!this.alignment,
            [`justify-${this.justify}`]: !!this.justify,
            [`gap-${this.gap}`]: !!this.gap,
            [`mobile-gap-${this.mobileGap}`]: !!this.mobileGap && this._deviceController.isMobile
        };

        return html`
            <div class="${classMap(layoutClass)}">
                <slot @slotchange=${this.onSlotChange}></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-layout": AtlasLayout;
    }
}
