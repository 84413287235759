import { customElement, property } from "lit/decorators.js";
import AtlasFloatInput, { FloatInputProps } from "@/components/form/atlas-float-input/atlas-float-input";

export type PercentageProps = FloatInputProps;

/**
 * @extends atlas-float-input
 *
 * @tag atlas-percentage
 */
@customElement("atlas-percentage")
export default class AtlasPercentage extends AtlasFloatInput {
    @property({ type: Number }) maxlength = 5;

    connectedCallback() {
        super.connectedCallback?.();
        this.inputGroupPrefix = "%";
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-percentage": AtlasPercentage;
    }
}
