import { LitElement, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";

import styles from "./atlas-page-header.scss";
import "@/components/display/atlas-heading/atlas-heading";

export type PageHeaderProps = {
    "page-name": string;
};

/**
 * @dependency atlas-heading
 *
 * @prop {string} page-name - Noma da página, que irá aparecer abaixo do breadcrumb
 *
 * @slot breadcrumb - Slot onde deve ficar o breadcrumb da página
 * @slot actions - Slot onde devem ficar os botões de ação da página
 *
 * @tag atlas-page-header
 */
@customElement("atlas-page-header")
export default class AtlasPageHeader extends LitElement {
    static styles = styles;

    @property({ type: String, attribute: "page-name" }) pageName: string;

    @state() private _hasActions = false;

    onActionsChange() {
        const actionsSlot = this.shadowRoot.querySelector("slot[name=actions]") as HTMLSlotElement;

        this._hasActions = actionsSlot.assignedElements().length > 0;
    }

    render() {
        const headerClass = {
            "page-header": true,
            "with-actions": this._hasActions
        };

        return html`
            <div class=${classMap(headerClass)}>
                <div class="page-breadcrumb">
                    <slot name="breadcrumb"></slot>
                </div>
                <div class="page-title">
                    <atlas-heading size="h4" line-clamp="2">${this.pageName}</atlas-heading>
                    <div class="page-actions">
                        <slot name="actions" @slotchange=${this.onActionsChange}></slot>
                    </div>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-page-header": AtlasPageHeader;
    }
}
