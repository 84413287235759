import { html } from "lit";
import { customElement, property } from "lit/decorators.js";

/* eslint-disable import/no-extraneous-dependencies */
import Chart from "chart.js/auto";
/* eslint-enable import/no-extraneous-dependencies */

import { Watch } from "@/decorators/watch";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";

import styles from "./atlas-flow-chart.scss";
import colorStyles from "../chart-colors.scss";

import { FlowChartDataType } from "./types";

import {
    flowChartBaseOptions,
    flowChartInflowPattern,
    flowChartOutflowPattern,
    externalLegendHandler,
    externalTooltipHandler
} from "../chart-config";

export type FlowChartProps = AtlasElementProps & {
    "x-axis-key": string;
    "y-axis-key": string;
    "inflow-label": string;
    "outflow-label": string;
    "overall-label": string;
    "inflow-data": string;
    "outflow-data": string;
    "overall-data": string;
    "max-y-value": number;
};

/**
 * @prop {string} x-axis-key - Propriedade que define a key utilizada para os valores do eixo X
 * @prop {string} y-axis-key - Propriedade que define a key utilizada para os valores do eixo Y
 * @prop {string} inflow-label - Propriedade que define a legenda que se refere aos dados de entrada
 * @prop {string} outflow-label - Propriedade que define a legenda que se refere aos dados de saída
 * @prop {string} overall-label - Propriedade que define a legenda que se refere aos dados de resultado entre o cruzamento das entradas e saidas
 * @prop {Array} inflow-data - Propriedade que contém os dados de entrada
 * @prop {Array} outflow-data - Propriedade que contém os dados de saida
 * @prop {Array} overall-data - Propriedade que contém os dados de resultado entre o cruzamento das entradas e saidas
 * @prop {number} max-y-value - Propriedade que define qual o valor máximo no eixo Y
 *
 * @tag atlas-flow-chart
 */
@customElement("atlas-flow-chart")
export default class AtlasFlowChart extends AtlasElement {
    static styles = styles;

    @property({ type: String, attribute: "x-axis-key" }) xAxisKey: string = "key";

    @property({ type: String, attribute: "y-axis-key" }) yAxisKey: string = "value";

    @property({ type: String, attribute: "inflow-label" }) inflowlabel: string = "Recebimentos";

    @property({ type: String, attribute: "outflow-label" }) outflowLabel: string = "Pagamentos";

    @property({ type: String, attribute: "overall-label" }) overallLabel: string = "Saldo";

    @property({ type: Array, attribute: "inflow-data" }) inflowData: Array<object>;

    @property({ type: Array, attribute: "outflow-data" }) outflowData: Array<object>;

    @property({ type: Array, attribute: "overall-data" }) overallData: Array<object>;

    @property({ type: Number, attribute: "max-y-value" }) maxYValue: number;

    public legendList: HTMLElement;

    public legendItemTemplate: HTMLElement;

    public chartElement: HTMLCanvasElement;

    private _chartObject: Chart;

    private _chartPatterns: { [key in FlowChartDataType]: string } = {
        inflow: flowChartInflowPattern,
        outflow: flowChartOutflowPattern,
        overall: ""
    };

    private _chartColors: { [key in FlowChartDataType]: string };

    private _chartLabels: { [key in FlowChartDataType]: string };

    connectedCallback(): void {
        super.connectedCallback?.();

        this.buildColorsFromCSS();

        this.updateComplete.then(() => {
            this.legendList = this.shadowRoot.querySelector(".legend-list") as HTMLElement;
            this.legendItemTemplate = this.shadowRoot.querySelector(".legend-item") as HTMLElement;
            this.chartElement = this.shadowRoot.querySelector(".chart") as HTMLCanvasElement;

            this.buildChartLabels();
            this.buildChart();
        });
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();
    }

    buildColorsFromCSS() {
        const { cssRules } = colorStyles.styleSheet;

        const colors = {
            inflow: "",
            outflow: "",
            overall: ""
        };

        for (const rule of cssRules) {
            const key = (rule as CSSStyleRule).selectorText;
            const value = (rule as CSSStyleRule).style.getPropertyValue("color");

            Object.assign(colors, { [`${key}`]: value });
        }

        this._chartColors = colors;
    }

    buildChartLabels() {
        this._chartLabels = {
            inflow: this.inflowlabel,
            outflow: this.outflowLabel,
            overall: this.overallLabel
        };
    }

    loadImage(url: string, element: HTMLImageElement) {
        /* eslint-disable no-param-reassign */
        return new Promise((resolve, reject) => {
            element.onload = () => resolve(element);
            element.onerror = () => reject();
            element.src = url;
        });
        /* eslint-enable no-param-reassign */
    }

    isInflowData(dataType: FlowChartDataType) {
        return dataType === "inflow";
    }

    isOutflowData(dataType: FlowChartDataType) {
        return dataType === "outflow";
    }

    isOverallData(dataType: FlowChartDataType) {
        return dataType === "overall";
    }

    getDataLabel(dataType: FlowChartDataType) {
        return this._chartLabels[dataType];
    }

    getDataFallbackColor(dataType: FlowChartDataType) {
        return this._chartColors[dataType];
    }

    getDataPatternPath(dataType: FlowChartDataType) {
        return this._chartPatterns[dataType];
    }

    async getChartBackgroundColor(dataType: FlowChartDataType) {
        const fallbackColor = this.getDataFallbackColor(dataType);
        const patternPath = this.getDataPatternPath(dataType);

        return this.loadImage(patternPath, new Image(8, 8)).then(
            (image: HTMLImageElement) => {
                const canvas = new OffscreenCanvas(8, 8);
                const canvasContext = canvas.getContext("2d");

                return canvasContext.createPattern(image, "repeat");
            },
            () => fallbackColor
        );
    }

    async buildBaseBarChartDataset(dataType: FlowChartDataType) {
        return {
            type: "bar",
            label: this.getDataLabel(dataType),
            backgroundColor: await this.getChartBackgroundColor(dataType),
            order: 2,
            data: [{}]
        };
    }

    buildBaseLineChartDataset() {
        const color = this.getDataFallbackColor("overall");

        return {
            type: "line",
            label: this.getDataLabel("overall"),
            backgroundColor: color,
            borderColor: color,
            borderWidth: 2,
            order: 1,
            data: [{}]
        };
    }

    async buildOutflowChartBar() {
        const outflowBar = await this.buildBaseBarChartDataset("outflow");

        outflowBar.data = this.outflowData;

        return outflowBar;
    }

    async buildInflowChartBar() {
        const inflowBar = await this.buildBaseBarChartDataset("inflow");

        inflowBar.data = this.inflowData;

        return inflowBar;
    }

    buildOverallChartLine() {
        const overallLine = this.buildBaseLineChartDataset();

        overallLine.data = this.overallData;

        return overallLine;
    }

    async buildData() {
        const inflowChartBar = await this.buildInflowChartBar();
        const outflowChartBar = await this.buildOutflowChartBar();
        const overallChartLine = this.buildOverallChartLine();

        return {
            datasets: [inflowChartBar, outflowChartBar, overallChartLine]
        };
    }

    buildOptions() {
        const options = flowChartBaseOptions;

        options.parsing = {
            xAxisKey: this.xAxisKey,
            yAxisKey: this.yAxisKey
        };

        options.plugins = {
            // @ts-expect-error
            externalLegendHandler: {
                element: {
                    legendList: this.legendList,
                    legendItemTemplate: this.legendItemTemplate
                },
                isFlowChart: true,
                labels: {
                    inflowLabel: this.inflowlabel,
                    outflowLabel: this.outflowLabel,
                    overallLabel: this.overallLabel
                }
            },
            legend: {
                display: false
            },
            tooltip: {
                enabled: false,
                position: "average",
                external: externalTooltipHandler
            }
        };

        options.scales.y.max = this.maxYValue;

        return options;
    }

    @Watch("maxYValue", true)
    updateMaxValue() {
        if (!this._chartObject) return;

        this._chartObject.options.scales.y.max = this.maxYValue;
        this._chartObject.update();
    }

    @Watch(
        [
            "inflowValues",
            "outflowValues",
            "overallValues",
            "inflowLabel",
            "outflowLabel",
            "overallLabel",
            "xAxisKey",
            "yAxisKey"
        ],
        true
    )
    async buildChart() {
        if (this._chartObject) this._chartObject.destroy();

        const config: any = {
            data: await this.buildData(),
            options: this.buildOptions(),
            plugins: [externalLegendHandler]
        };

        this._chartObject = new Chart(this.chartElement, config);
    }

    renderLegendItemTemplate() {
        return html`
            <li class="legend-item" hidden>
                <div class="legend-color"></div>
                <span class="legend-text"></span>
            </li>
        `;
    }

    renderLegend() {
        return html`
            <ul class="legend-list"></ul>

            ${this.renderLegendItemTemplate()}
        `;
    }

    renderTooltip() {
        return html`
            <div class="atlas-chart-tooltip" role="tooltip" aria-labelledby="tooltip-slot">
                <div class="tooltip-inner"></div>
            </div>
        `;
    }

    render() {
        return html`
            ${this.renderLegend()}
            <div class="chart-container">
                <canvas class="chart"></canvas>
                ${this.renderTooltip()}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-flow-chart": AtlasFlowChart;
    }
}
