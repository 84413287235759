import { html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { isEmptySlot } from "@/internals/slot";
import { emit } from "@/internals/events";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-modal.scss";
import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-icon-button/atlas-icon-button";
import "@/components/display/atlas-illustration/atlas-illustration";

export type ModalProps = AtlasElementProps & {
    "size": "default" | "large" | "extra-large" | "fullscreen";
    "open": boolean;
    "disable-auto-close": boolean;
    "hide-close-button": boolean;
    "header": string;
    "header-logo": string;
    "header-img": string;
    "header-illustration": string;
    "header-img-description": string;
};

/**
 * @prop {"default" | "large" | "extra-large" | "fullscreen"} size - Tamanho do modal
 * @prop {boolean} open - Indica se o modal está aberto ou não
 * @prop {boolean} disable-auto-close - Indica se o clique fora do modal deve ou não fechar o modal
 * @prop {boolean} hide-close-button - Indica se o botão de fechar "X" deve aparecer
 * @prop {string} header - Título do modal
 * @prop {string} header-logo - Caminho da imagem (do tipo logo) que deve aparecer no lugar do título
 * @prop {string} header-img - Caminho da imagem que aparece como título do modal
 * @prop {string} header-illustration - Nome da ilustração que aparece como título do modal
 * @prop {string} header-img-description - Descrição das imagens que aparecem no cabeçalho (Serve tanto para as imagens quanto para a ilustração)
 *
 * @event {CustomEvent} atlas-modal-open - Evento disparado quando o modal é exibido
 * @event {CustomEvent} atlas-modal-close - Evento disparado quando o modal é fechado
 *
 * @tag atlas-modal
 */
@customElement("atlas-modal")
export default class AtlasModal extends AtlasElement {
    static styles = styles;

    @property({ type: String }) size: "default" | "large" | "extra-large" | "fullscreen" = "default";

    @property({ type: Boolean, reflect: true }) open: boolean;

    @property({ type: Boolean, reflect: true, attribute: "disable-auto-close" }) disableAutoClose: boolean;

    @property({ type: Boolean, reflect: true, attribute: "hide-close-button" }) hideCloseButton: boolean;

    @property({ type: String }) header: string;

    @property({ type: String, attribute: "header-logo" }) headerLogo: string;

    @property({ type: String, attribute: "header-img" }) headerImg: string;

    @property({ type: String, attribute: "header-illustration" }) headerIllustration: string;

    @property({ type: String, attribute: "header-img-description" }) headerImgDescription: string;

    @state() private _isVisible = false;

    @state() private _showFooter = false;

    @query(".modal")
    private _modal: HTMLElement;

    connectedCallback() {
        super.connectedCallback?.();

        this.onDocumentKeyDown = this.onDocumentKeyDown.bind(this);
        this.onClickOverlay = this.onClickOverlay.bind(this);
    }

    openModal() {
        this.open = true;
    }

    closeModal() {
        this.open = false;
    }

    @Watch("open")
    onChangeOpenState() {
        if (this.open) {
            this.onOpenModal();
        } else {
            this.onCloseModal();
        }
    }

    onOpenModal() {
        setTimeout(() => {
            this._isVisible = true;
        }, 0);

        emit(this, "atlas-modal-open");
        document.addEventListener("keydown", this.onDocumentKeyDown);
        document.body.classList.add("disable-scroll");
    }

    onCloseModal() {
        setTimeout(() => {
            this._isVisible = false;
        }, 0);

        emit(this, "atlas-modal-close");
        document.removeEventListener("keydown", this.onDocumentKeyDown);
        document.body.classList.remove("disable-scroll");
    }

    onChangeSlottedActions() {
        this._showFooter = !isEmptySlot(this, "actions");
    }

    onClickClose() {
        this.closeModal();
    }

    onDocumentKeyDown(event: KeyboardEvent) {
        if (!this.disableAutoClose && event.key === "Escape") {
            this.closeModal();
        }
    }

    onClickOverlay(event: PointerEvent) {
        if (!this.open || this.disableAutoClose) return;

        const composedPath = event.composedPath();
        const isModalTarget = composedPath.includes(this._modal);

        if (!isModalTarget) {
            this.closeModal();
        }
    }

    renderCloseButton() {
        return when(
            !this.hideCloseButton && !this.headerImg && !this.skeletonLoading,
            () => html`
                <atlas-icon-button
                    icon="x"
                    size="3x"
                    theme="secondary"
                    class="close-button"
                    @click=${this.onClickClose}
                ></atlas-icon-button>
            `
        );
    }

    renderHeader() {
        if (this.headerLogo) {
            return html`
                <div class="modal-title">
                    <img class="header-logo" src=${this.headerLogo} alt=${this.headerImgDescription} />
                </div>
            `;
        }

        if (this.headerImg) {
            return html`
                <div class="modal-title">
                    <img class="header-img" src=${this.headerImg} alt=${this.headerImgDescription} />
                </div>
            `;
        }

        if (this.headerIllustration) {
            return html`
                <div class="modal-title">
                    <div class="illustration-container">
                        <atlas-illustration
                            name=${this.headerIllustration}
                            alt=${this.headerImgDescription}
                            scale-vertically
                        ></atlas-illustration>
                    </div>
                </div>
            `;
        }

        return html`
            <div class="modal-title">
                <atlas-heading size="h5">${this.header}</atlas-heading>
            </div>
        `;
    }

    render() {
        const modalOverlayClass = {
            "modal-overlay": true,
            "fade": true,
            "show": this._isVisible
        };

        const modalClass = {
            "modal": true,
            "fade-and-scale": true,
            "show": this._isVisible,
            "with-image": !!this.headerImg,
            "with-illustration": !!this.headerIllustration,
            "show-footer": this._showFooter,
            [`size-${this.size}`]: !!this.size
        };

        /* eslint-disable lit-a11y/click-events-have-key-events */
        return html`
            <div class=${classMap(modalOverlayClass)} @click=${this.onClickOverlay}>
                <div class=${classMap(modalClass)}>
                    ${this.renderCloseButton()} ${this.renderHeader()}
                    <div class="modal-content">
                        <slot></slot>
                    </div>
                    <div class="modal-footer">
                        <slot name="actions" @slotchange=${this.onChangeSlottedActions}></slot>
                    </div>
                </div>
            </div>
        `;
        /* eslint-enable lit-a11y/click-events-have-key-events */
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-modal": AtlasModal;
    }
}
