import { html, unsafeStatic } from "lit/static-html.js";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";
import { Theme } from "@/internals/theme";

import { GroupedButtonProps } from "./types";
import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-button-group.scss";
import "@/components/display/atlas-button/atlas-button";
import "@/components/display/atlas-dropdown/atlas-dropdown";
import "@/components/display/atlas-dropdown-item/atlas-dropdown-item";
import { IconButtonSize } from "../atlas-icon-button/types";

export type ButtonGroupProps = AtlasElementProps & {
    "gap": number;
    "group-after": number;
    "group-all": boolean;
    "more-button-type": "filled" | "outlined" | "ghost" | "icon";
    "more-button-theme": Theme;
    "more-button-size": IconButtonSize;
    "loading": boolean;
    "loading-text": string;
};

/**
 * @dependency atlas-button
 * @dependency atlas-dropdown
 * @dependency atlas-dropdown-item
 *
 * @prop {number} gap - Propriedade que define o espaco entre elementos (Deve ser um número entre 0 e 9)
 * @prop {number} group-after - Número de botões que devem ser exibidos antes de criar a opção "Mais"
 * @prop {boolean} group-all - Define que todos os botões serão agrupados
 * @prop {"filled" | "outlined" | "ghost" | "icon"} more-button-type - Tipo do botão "Mais opções"
 * @prop {Theme} more-button-theme - Tema do botão "Mais opções"
 * @prop {boolean} more-button-size - (Para o tipo `icon`) Define o tamanho do botão
 * @prop {boolean} loading - Indica se o dropdown de "Mais opções" está em estado de loading
 * @prop {string} loading-text - Texto que é exibido dentro do dropdown de "Mais opções" quando ele esta em estado de loading
 *
 * @tag atlas-button-group
 */
@customElement("atlas-button-group")
export default class AtlasButtonGroup extends AtlasElement {
    static styles = styles;

    @property({ type: Number }) gap: number = 4;

    @property({ type: Number, attribute: "group-after" }) groupAfter: number = 2;

    @property({ type: Boolean, attribute: "group-all" }) groupAll: boolean;

    @property({ type: String, attribute: "more-button-type" }) moreButtonType:
        | "filled"
        | "outlined"
        | "ghost"
        | "icon" = "filled";

    @property({ type: String, attribute: "more-button-theme" }) moreButtonTheme: Theme = "primary";

    @property({ type: String, attribute: "more-button-size" }) moreButtonSize: IconButtonSize = "3x";

    @property({ type: Boolean }) loading: boolean;

    @property({ type: String, attribute: "loading-text" }) loadingText: string = "Carregando ações";

    @state() private _buttons: GroupedButtonProps[] = [];

    connectedCallback(): void {
        super.connectedCallback?.();

        this.extractSlottedButtonsProps = this.extractSlottedButtonsProps.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);

        this.updateComplete.then(() => {
            this.shadowRoot
                .querySelector("slot")
                .addEventListener("atlas-element-update", this.extractSlottedButtonsProps);
        });
    }

    getCountBeforeMore() {
        return this.groupAll ? 0 : this.groupAfter;
    }

    getShowMoreOptions() {
        return this._buttons.length > 1 && this._buttons.length > this.getCountBeforeMore();
    }

    openMoreOptions() {
        this.shadowRoot.querySelector("atlas-dropdown")?.show();
    }

    closeMoreOptions() {
        this.shadowRoot.querySelector("atlas-dropdown")?.hide();
    }

    @Watch("moreButtonType", true)
    updateDropdownRef() {
        this.updateComplete.then(() => {
            this.shadowRoot.querySelector("atlas-dropdown")?.syncTriggerElement();
        });
    }

    extractSlottedButtonsProps() {
        const slottedButtons = this.shadowRoot.querySelector("slot").assignedElements();

        this._buttons = slottedButtons.map((button: HTMLElement) => {
            const tag = button.tagName.toLowerCase();
            const attrs = button.attributes;
            const extractedProps: { [key: string]: any } = {};

            for (let i = 0; i < attrs.length; i++) {
                extractedProps[attrs[i].nodeName] = attrs[i].nodeValue;
            }

            return {
                tag,
                props: extractedProps,
                element: button
            };
        });
    }

    onButtonClick(event: PointerEvent) {
        const target = event.currentTarget as HTMLElement;
        const buttonIndex = parseInt(target.dataset.buttonIndex, 10);
        const buttonElement = this._buttons[buttonIndex]?.element;

        emit(this, "atlas-button-group-button-click", {
            detail: { button: buttonElement }
        });

        buttonElement?.click();
    }

    renderButtonsBeforeGroup() {
        let buttonsBeforeGroup = this._buttons.slice(0, this.getCountBeforeMore());

        if (buttonsBeforeGroup.length === 0 && this._buttons.length === 1) {
            buttonsBeforeGroup = [this._buttons[0]];
        }

        return buttonsBeforeGroup.map((button, index) => {
            const tag = unsafeStatic(button.tag);
            let attributes = Object.entries(button.props).map((entry) => `${entry[0]}="${entry[1]}"`);

            if (button.tag === "atlas-icon-button") {
                attributes = attributes.filter((attr) => !attr.includes("size"));
                attributes.push("hoverable");
                attributes.push("theme-on-interactions");
                attributes.push(`size="${this.moreButtonSize}"`);
            }

            /* eslint-disable lit/binding-positions, lit/no-invalid-html */
            return html`
                <${tag}
                    ${unsafeStatic(attributes.join(" "))}
                    data-button-index=${index}
                    @click=${this.onButtonClick}>
                </${tag}>
            `;
            /* eslint-enable lit/binding-positions, lit/no-invalid-html */
        });
    }

    renderGroupedButtons() {
        const groupedButtons = this._buttons.slice(this.getCountBeforeMore());

        return groupedButtons.map((button, index) => {
            const itemTheme = ["primary", "secondary"].includes(button.props.theme) ? undefined : button.props.theme;

            return html`
                <atlas-dropdown-item
                    theme=${ifDefined(itemTheme)}
                    icon=${button.props.icon}
                    ?disabled=${"disabled" in button.props}
                    ?loading=${"loading" in button.props}
                    tooltip=${button.props.tooltip}
                    data-button-index=${this.getCountBeforeMore() + index}
                    @atlas-dropdown-item-click=${this.onButtonClick}
                >
                    ${button.props.description}
                </atlas-dropdown-item>
            `;
        });
    }

    renderMoreButton() {
        if (!this.getShowMoreOptions()) {
            return null;
        }

        return when(
            this.moreButtonType === "icon",
            () => html`
                <atlas-icon-button
                    icon="three-dots"
                    data-atlas-dropdown="button-group-dropdown"
                    size=${this.moreButtonSize}
                    theme=${this.moreButtonTheme}
                    aria-label="Ações"
                    theme-on-interactions
                    hoverable
                ></atlas-icon-button>
            `,
            () => html`
                <atlas-button
                    icon="three-dots"
                    data-atlas-dropdown="button-group-dropdown"
                    type=${this.moreButtonType}
                    theme=${this.moreButtonTheme}
                    aria-label="Ações"
                ></atlas-button>
            `
        );
    }

    renderMoreDropdown() {
        return when(
            this.getShowMoreOptions(),
            () => html`
                <atlas-dropdown
                    id="button-group-dropdown"
                    no-gap
                    auto-close
                    ?loading=${this.loading}
                    loading-text=${this.loadingText}
                >
                    ${this.renderGroupedButtons()}
                </atlas-dropdown>
            `
        );
    }

    render() {
        const buttonGroupClass = {
            "button-group": true,
            [`gap-${this.gap}`]: !!this.gap
        };

        return html`
            <div class=${classMap(buttonGroupClass)}>${this.renderButtonsBeforeGroup()}${this.renderMoreButton()}</div>
            <slot @slotchange=${this.extractSlottedButtonsProps}></slot>
            ${this.renderMoreDropdown()}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-button-group": AtlasButtonGroup;
    }
}
