import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";

import styles from "./atlas-list-item.scss";

import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-text/atlas-text";

export type ListItemProps = {
    status: string;
};

/**
 * @dependency atlas-icon
 * @dependency atlas-text
 *
 * @slot - Usado para escrever o que o item representa
 *
 * @prop {string} status - Propriedade que define o status do item
 *
 * @tag atlas-list-item
 */
@customElement("atlas-list-item")
export default class AtlasListItem extends LitElement {
    static styles = styles;

    @property({ type: String }) status: "default" | "success" = "default";

    render() {
        const theme = this.status === "default" ? "secondary" : "success";
        const icon = this.status === "default" ? "circle" : "check-circle";

        return html`
            <div class="atlas-list-item" role="listitem">
                <atlas-icon size="2x" theme="${theme}" name="${icon}"></atlas-icon>
                <atlas-text theme="${theme}" ?muted=${this.status === "default"}>
                    <slot></slot>
                </atlas-text>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-list-item": AtlasListItem;
    }
}
