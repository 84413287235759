import flowChartInflowPattern from "@/assets/images/chart-inflow-pattern.svg";
import flowChartOutflowPattern from "@/assets/images/chart-outflow-pattern.svg";

import { formatMoney } from "@/helpers/formatters";

const clearTooltipContent = (tooltipElement: any) => {
    const innerTooltip = tooltipElement.querySelector(".tooltip-inner");

    while (innerTooltip.firstChild) {
        innerTooltip.firstChild.remove();
    }
};

const createTooltipContent = (tooltipElement: any, chartDataPoints: any) => {
    const { label } = chartDataPoints[0];

    const span = document.createElement("span");

    const heading = span.cloneNode(true) as HTMLElement;
    heading.innerText = label;

    const innerTooltip = tooltipElement.querySelector(".tooltip-inner");

    innerTooltip.appendChild(heading);

    chartDataPoints.forEach((line: any) => {
        const lineInfo = span.cloneNode(true) as HTMLElement;

        const { options } = line.element;

        if (line.datasetIndex === 0) {
            options.pointStyle = "circle";
        } else {
            options.pointStyle = "rectRot";
        }

        const value = `R$ ${formatMoney(line.parsed.y)}`;
        const dataLabel = line.dataset.label;

        lineInfo.innerHTML = `
            ${dataLabel}: <br/>
            ${value}
        `;

        innerTooltip.appendChild(lineInfo);
    });
};

const getTooltipYPosition = (chart: any, tooltip: any) => {
    const { canvas } = chart;

    const { offsetTop: canvasYPosition } = canvas;

    const lineYPosition: number[] = [];

    tooltip.dataPoints.forEach((dataPoint: any) => {
        const { y } = dataPoint.element;

        lineYPosition.push(y);
    });

    return `${canvasYPosition + Math.min(...lineYPosition)}px`;
};

const getTooltipXPosition = (chart: any, tooltip: any) => {
    const { offsetLeft: positionX } = chart.canvas;

    return `${positionX + tooltip.caretX}px`;
};

export const externalTooltipHandler = (context: any) => {
    const { chart, tooltip } = context;
    const tooltipElement = chart.canvas.parentNode.querySelector(".atlas-chart-tooltip");

    if (!tooltipElement) return;

    clearTooltipContent(tooltipElement);

    if (tooltip.opacity === 0) {
        tooltipElement.style.opacity = 0;
        return;
    }

    const chartDataPoints = tooltip.dataPoints;

    if (!tooltip || chartDataPoints.length < 1) return;

    createTooltipContent(tooltipElement, chartDataPoints);

    tooltipElement.style.opacity = 1;
    tooltipElement.style.left = getTooltipXPosition(chart, tooltip);
    tooltipElement.style.top = getTooltipYPosition(chart, tooltip);
};

export const externalLegendHandler = {
    id: "externalLegendHandler",
    // @ts-expect-error
    afterUpdate(chart: any, args: any, options: any) {
        const { legendList, legendItemTemplate } = options.element;

        while (legendList.firstChild) {
            legendList.firstChild.remove();
        }

        const chartLegendLabels = chart.options.plugins.legend.labels.generateLabels(chart);

        chartLegendLabels.forEach((legendObject: any) => {
            const legendItemElement = legendItemTemplate.cloneNode(true) as HTMLElement;

            legendItemElement.addEventListener("click", () => {
                const { type } = chart.config;
                if (type === "pie" || type === "doughnut") {
                    chart.toggleDataVisibility(legendObject.index);
                } else {
                    chart.setDatasetVisibility(
                        legendObject.datasetIndex,
                        !chart.isDatasetVisible(legendObject.datasetIndex)
                    );
                }
                chart.update();
            });

            const legendColor = legendItemElement.querySelector(".legend-color") as HTMLElement;

            legendColor.style.background = legendObject.fillStyle;

            const { isFlowChart } = options;

            if (isFlowChart) {
                const { inflowLabel, outflowLabel } = options.labels;

                if (legendObject.text === inflowLabel) {
                    legendColor.style.background = `url(${flowChartInflowPattern})`;
                } else if (legendObject.text === outflowLabel) {
                    legendColor.style.background = `url(${flowChartOutflowPattern})`;
                }
            }

            const title = legendItemElement.querySelector(".legend-text") as HTMLElement;
            title.innerText = legendObject.text;
            title.style.textDecoration = legendObject.hidden ? "line-through" : "";

            legendItemElement.hidden = false;
            legendList.appendChild(legendItemElement);
        });
    }
};
