import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import { emit } from "@/internals/events";

import { Theme } from "@/internals/theme";
import styles from "./atlas-notification-card.scss";

import "@/components/display/atlas-icon/atlas-icon";

/**
 * O Notification Card é o item que aparece no dropdown de alertas, presente na navbar, ele serve para destacar notificações personalizados do sistema
 *
 * @dependency atlas-icon
 *
 * @prop {string} icon - Ícone principal do alerta
 * @prop {string} overlay-icon - Ícone que aparece sobreposto ao ícone principal
 * @prop {string} overlay-theme - O tema do ícone de overlay, serve para definir a cor do ícone
 * @prop {string} header - Título da notificação
 * @prop {string} description - Descrição da notificação
 * @prop {string} link-text - Texto do link da notificação
 * @prop {string} link-path - Página para qual o link irá redirecionar ao ser clicado
 *
 * @event {CustomEvent} atlas-notification-link-click - Evento lançado ao clicar sobre no link da notificação
 *
 * @tag atlas-notification-card
 */
@customElement("atlas-notification-card")
export default class AtlasNotificationCard extends LitElement {
    static styles = styles;

    @property({ type: String }) icon: string;

    @property({ type: String, attribute: "overlay-icon" }) overlayIcon: string;

    @property({ type: String, attribute: "overlay-theme" }) overlayTheme: Theme;

    @property({ type: String }) header: string;

    @property({ type: String }) description: string;

    @property({ type: String, attribute: "link-text" }) linkText: string;

    @property({ type: String, attribute: "link-path" }) linkPath: string;

    onClickLink() {
        emit(this, "atlas-notification-link-click");
    }

    renderOverlayIcon() {
        return when(
            !!this.overlayIcon,
            () => html`
                <atlas-icon
                    class="overlay-icon"
                    name=${this.overlayIcon}
                    theme=${this.overlayTheme || "secondary"}
                    size="2x"
                ></atlas-icon>
            `
        );
    }

    renderLink() {
        return when(
            !!this.linkText,
            () => html`
                <a class="notification-link" @click=${this.onClickLink} href=${ifDefined(this.linkPath || undefined)}>
                    ${this.linkText}
                </a>
            `
        );
    }

    render() {
        return html`
            <div class="notification-card">
                <div class="notification-icon">
                    <atlas-icon name=${this.icon} size="3x" theme="secondary"></atlas-icon>
                    ${this.renderOverlayIcon()}
                </div>
                <div class="notification-info">
                    <h6 class="notification-title">${this.header}</h6>
                    <span class="notification-description">${this.description}</span>
                    ${this.renderLink()}
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-notification-card": AtlasNotificationCard;
    }
}
