import AirplaneCheckSuccess from "@/assets/illustrations/airplane-check-success.svg";
import AirplaneError from "@/assets/illustrations/airplane-error.svg";
import Analysis from "@/assets/illustrations/analysis.svg";
import Authentication from "@/assets/illustrations/authentication.svg";
import Bank from "@/assets/illustrations/bank.svg";
import CalendarDollarsign from "@/assets/illustrations/calendar-dollarsign.svg";
import CardCoin from "@/assets/illustrations/card-coin.svg";
import CellPhoneLinkChat from "@/assets/illustrations/cell-phone-link-chat.svg";
import CellPhoneReceipt from "@/assets/illustrations/cell-phone-receipt.svg";
import ComputerKey from "@/assets/illustrations/computer-key.svg";
import CreditCardSearch from "@/assets/illustrations/credit-card-search.svg";
import EnvelopeLampExclamationMark from "@/assets/illustrations/envelope-lamp-exclamation-mark.svg";
import Error404UFO from "@/assets/illustrations/error-404-UFO.svg";
import Error404CloudsBirds from "@/assets/illustrations/error-404-clouds-birds.svg";
import FilesFolderCards from "@/assets/illustrations/files-folder-cards.svg";
import FilesNotificationNotebook from "@/assets/illustrations/files-notification-notebook.svg";
import FlowMoneyCoins from "@/assets/illustrations/flow-money-coins.svg";
import FolderMagnifyingGlassFiles from "@/assets/illustrations/folder-magnifying-glass-files.svg";
import Folder from "@/assets/illustrations/folder.svg";
import FunnelCoins from "@/assets/illustrations/funnel-coins.svg";
import GraphicsBarsPercentage from "@/assets/illustrations/graphics-bars-percentage.svg";
import GraphicsMoneyCoins from "@/assets/illustrations/graphics-money-coins.svg";
import HappyWomanNotebook from "@/assets/illustrations/happy-woman-notebook.svg";
import LineGraphicBarsPercentage from "@/assets/illustrations/line-graphic-bars-percentage.svg";
import ManCoinMoney from "@/assets/illustrations/man-coin-money.svg";
import MoneyCoinsFiles from "@/assets/illustrations/money-coins-files.svg";
import MoneyCoinsHandshake from "@/assets/illustrations/money-coins-handshake.svg";
import PaperAirplane from "@/assets/illustrations/paper-airplane.svg";
import Papers from "@/assets/illustrations/papers.svg";
import PodiumMedal from "@/assets/illustrations/podium-medal.svg";
import ProgressCredit from "@/assets/illustrations/progress-credit.svg";
import SecurityKeyTabletCellPhone from "@/assets/illustrations/security-key-tablet-cell-phone.svg";
import Survey from "@/assets/illustrations/survey.svg";
import TelescopeFilesStars from "@/assets/illustrations/telescope-files-stars.svg";
import TriangleExclamationMarkClouds from "@/assets/illustrations/triangle-exclamation-mark-clouds.svg";
import TriangleExclamationMarkSiren from "@/assets/illustrations/triangle-exclamation-mark-siren.svg";
import WomanCellPhoneNotebook from "@/assets/illustrations/woman-cell-phone-notebook.svg";
import WomanCheckBars from "@/assets/illustrations/woman-check-bars.svg";

const illustrations: { [key: string]: any } = {
    "airplane-check-success": AirplaneCheckSuccess,
    "airplane-error": AirplaneError,
    "analysis": Analysis,
    "authentication": Authentication,
    "bank": Bank,
    "calendar-dollarsign": CalendarDollarsign,
    "card-coin": CardCoin,
    "cell-phone-link-chat": CellPhoneLinkChat,
    "cell-phone-receipt": CellPhoneReceipt,
    "computer-key": ComputerKey,
    "credit-card-search": CreditCardSearch,
    "envelope-lamp-exclamation-mark": EnvelopeLampExclamationMark,
    "error-404-UFO": Error404UFO,
    "error-404-clouds-birds": Error404CloudsBirds,
    "files-folder-cards": FilesFolderCards,
    "files-notification-notebook": FilesNotificationNotebook,
    "flow-money-coins": FlowMoneyCoins,
    "folder-magnifying-glass-files": FolderMagnifyingGlassFiles,
    "folder": Folder,
    "funnel-coins": FunnelCoins,
    "graphics-bars-percentage": GraphicsBarsPercentage,
    "graphics-money-coins": GraphicsMoneyCoins,
    "happy-woman-notebook": HappyWomanNotebook,
    "line-graphic-bars-percentage": LineGraphicBarsPercentage,
    "man-coin-money": ManCoinMoney,
    "money-coins-files": MoneyCoinsFiles,
    "money-coins-handshake": MoneyCoinsHandshake,
    "paper-airplane": PaperAirplane,
    "papers": Papers,
    "podium-medal": PodiumMedal,
    "progress-credit": ProgressCredit,
    "security-key-tablet-cell-phone": SecurityKeyTabletCellPhone,
    "survey": Survey,
    "telescope-files-stars": TelescopeFilesStars,
    "triangle-exclamation-mark-clouds": TriangleExclamationMarkClouds,
    "triangle-exclamation-mark-siren": TriangleExclamationMarkSiren,
    "woman-cell-phone-notebook": WomanCellPhoneNotebook,
    "woman-check-bars": WomanCheckBars
};

export default illustrations;
