import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { styleMap } from "lit/directives/style-map.js";

import { TextSize } from "./types";

import TextElement, { TextElementProps } from "../text-element";
import styles from "./atlas-text.scss";

export type TextProps = TextElementProps & {
    size: TextSize;
    bold: boolean;
    italic: boolean;
    underline: boolean;
    strikethrough: boolean;
};

/**
 * Componente de tipografia relacionado aos tipos de texto que existem
 *
 * @prop {TextSize} size - O tamanho de text desejado, dentre os tamanhos possiveis
 * @prop {boolean} bold - Define o texto em negrito
 * @prop {boolean} italic - Define o texto em itálico
 * @prop {boolean} underline - Define o texto sublinhado
 * @prop {boolean} strikethrough - Define o texto riscado
 *
 * @tag atlas-text
 */
@customElement("atlas-text")
export default class AtlasText extends TextElement {
    static styles = styles;

    @property({ type: String }) size: TextSize = "md";

    @property({ type: Boolean }) bold = false;

    @property({ type: Boolean }) italic = false;

    @property({ type: Boolean }) underline = false;

    @property({ type: Boolean }) strikethrough = false;

    renderSkeleton() {
        const skeletonClass = {
            "atlas-text": true,
            "skeleton": true,
            [this.size]: !!this.size
        };

        return html`<div class="${classMap(skeletonClass)}"></div>`;
    }

    renderElement() {
        const textClass = {
            "atlas-text": true,
            "italic": this.italic,
            "underline": this.underline,
            "bold": this.bold,
            "strikethrough": this.strikethrough,
            [this.size]: !!this.size,
            ...this.getTextClasses()
        };

        return html`
            <span
                class="${classMap(textClass)}"
                style=${styleMap(this.getTextStyles())}
                data-atlas-tooltip="text-tooltip"
            >
                ${this.renderContentSlot()}
            </span>
            ${this.renderEllipsisTooltip()}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-text": AtlasText;
    }
}
