import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import DeviceController from "@/controllers/device-controller";

import AtlasElement, { AtlasElementProps } from "../../../components/atlas-element";
import styles from "./atlas-toolbar.scss";
import "../../display/atlas-dropdown-button/atlas-dropdown-button";

export type ToolbarProps = AtlasElementProps & {};

/**
 * @slot search - Slot onde poderá ser adicionado um input de pesquisa
 * @slot filter - Slot onde poderá ser adicionado um botão de filtro
 * @slot actions - Slot onde poderam ser adicionados ações na toolbar
 *
 * @tag atlas-toolbar
 */
@customElement("atlas-toolbar")
export default class AtlasToolbar extends AtlasElement {
    static styles = styles;

    @property({ type: Boolean, attribute: "only-actions" }) onlyActions: boolean;

    private _deviceController = new DeviceController(this, this.adjustButtonsSize.bind(this));

    async adjustButtonsSize() {
        await this.updateComplete;

        const actionsSlot = this.shadowRoot.querySelector("slot[name=actions]") as HTMLSlotElement;
        const filterSlot = this.shadowRoot.querySelector("slot[name=filter]") as HTMLSlotElement;

        const slottedButtons = [
            ...actionsSlot.assignedElements({ flatten: true }),
            ...(filterSlot?.assignedElements({ flatten: true }) || [])
        ];

        slottedButtons.forEach((element) => {
            element.toggleAttribute("block", this._deviceController.isMobile);
        });
    }

    renderSearchAndFilter() {
        return when(
            !this.onlyActions,
            () => html`
                <div class="search-and-filter">
                    <slot name="search"></slot>
                    <slot name="filter" @slotchange=${this.adjustButtonsSize}></slot>
                </div>
            `
        );
    }

    render() {
        return html`
            <div class="atlas-toolbar">
                ${this.renderSearchAndFilter()}
                <div class="actions-wrapper">
                    <slot name="actions" @slotchange=${this.adjustButtonsSize}></slot>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-toolbar": AtlasToolbar;
    }
}
