import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { Theme } from "@/internals/theme";
import { formatMoney } from "@/helpers/formatters";

import { ChartObject, ChartType } from "./types";
import { BillingType, BillingTypeTooltips } from "./billing-type";

import { WithTooltipMixin } from "@/internals/mixins/with-tooltip-mixin";
import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";

import styles from "./atlas-chart.scss";
import "@/components/dashboard/atlas-bar-chart-item/atlas-bar-chart-item";

export type ChartProps = AtlasElementProps & {
    type: ChartType;
    theme: Theme;
    values: string;
};

/**
 * @dependency atlas-bar-chart-item
 *
 * @prop {ChartType} type - Define o tipo do gráfico
 * @prop {Theme} theme - Define o tema do gráfico
 * @prop {object} values - Recebe o objeto de  tipos : valores a serem renderizados no gráfico.
 *
 * @tag atlas-chart
 */
@customElement("atlas-chart")
export default class AtlasChart extends WithTooltipMixin(AtlasElement) {
    static styles = styles;

    @property({ type: String }) type: ChartType = "bar";

    @property({ type: String }) theme: Theme;

    @property({ type: Object }) values: object;

    @state() private _hasValues: boolean;

    @state() private _chartValues: Array<ChartObject> = [];

    @state() private _billingTypeArray: Array<string> = BillingType;

    @state() private _totalValue = 0;

    private _defaultTooltipMessage = "Não há dados para visualizar";

    private _itemMinWidth = 5;

    connectedCallback() {
        super.connectedCallback?.();

        this.updateComplete.then(() => {
            if (this._chartValues.length === 0) {
                this.tooltip = this._defaultTooltipMessage;
            }
        });
    }

    setValues(values: object) {
        this.values = values;
        this._hasValues = values && Object.keys(values).length > 0;
        this.tooltip = !this._hasValues ? this._defaultTooltipMessage : "";
    }

    cleanValues() {
        this._hasValues = false;

        setTimeout(() => {
            this.tooltip = this._defaultTooltipMessage;
            this._chartValues = [];
            this._totalValue = 0;
            this.values = [];
        }, 350);
    }

    @Watch("values")
    onChangeChartValues() {
        this.buildChartValuesArray();
        this.sortDescendingOrderValues();

        this._totalValue = this._chartValues.reduce((acc: number, object: ChartObject) => acc + object.value, 0);
    }

    buildChartValuesArray() {
        this._chartValues = [];
        this._billingTypeArray.forEach((type: string) => {
            if (!this.values[type as keyof object]) return;

            const value = parseFloat(this.values[type as keyof object]);

            if (value > 0) this._chartValues.push({ type, value });
        });
    }

    sortDescendingOrderValues() {
        this._chartValues.sort((objectA: any, objectB: any) => objectB.value - objectA.value);
    }

    getWidth(index: number) {
        const width = (this._chartValues[index].value / this._totalValue) * 100;

        return width < this._itemMinWidth ? this._itemMinWidth : width;
    }

    getVariation(index: number) {
        return `${7 - index}00`;
    }

    getTooltip(chartObject: ChartObject) {
        return `R$ 
            ${formatMoney(chartObject.value)}
            ${BillingTypeTooltips[chartObject.type as keyof typeof BillingTypeTooltips]}
        `;
    }

    renderBar() {
        return this._chartValues.map(
            (chartObject: ChartObject, index: number) => html`
                <atlas-bar-chart-item
                    theme="${this.theme}"
                    variation="${this.getVariation(index)}"
                    width="${this.getWidth(index)}"
                    tooltip="${this.getTooltip(chartObject)}"
                ></atlas-bar-chart-item>
            `
        );
    }

    renderElement() {
        const chartClass = {
            "atlas-chart": true,
            [`bg-${this.theme}-200`]: true,
            "has-chart": this._hasValues
        };

        return html`
            <div class="${classMap(chartClass)}" data-atlas-tooltip="chart-tooltip">
                <div class="chart-wrap">${when(this.type === "bar", () => this.renderBar())}</div>
            </div>
            ${this.renderTooltip("chart-tooltip")}
        `;
    }

    renderSkeleton() {
        return html` <div class="chart skeleton"></div> `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-chart": AtlasChart;
    }
}
