const BLOCKED_EVENTS_TO_TRACK = [
    "atlas-form-element-value-change",
    "atlas-form-element-touch",
    "atlas-form-element-disabled-change",
    "atlas-form-element-required-change",
    "atlas-form-panel-start-editing",
    "atlas-form-panel-end-editing",
    "atlas-form-panel-submit",
    "atlas-input-change",
    "atlas-textarea-change",
    "atlas-table-header-sync-cols",
    "atlas-summary-card-change-visibility",
    "atlas-wizard-step-go",
    "atlas-wizard-step-changed",
    "atlas-element-update",
    "atlas-element-change-skeleton",
    "atlas-radio-check",
    "atlas-radio-uncheck",
    "atlas-radio-change",
    "atlas-checkbox-check",
    "atlas-checkbox-uncheck",
    "atlas-checkbox-change",
    "atlas-tooltip-opened",
    "atlas-tooltip-closed",
    "atlas-popover-opened",
    "atlas-popover-closed"
];

const ALLOWED_ELEMENT_ATTRIBUTES = ["label", "placeholder", "header", "description", "id"];

function extractElementProps(el: HTMLElement) {
    const attrs = el.attributes;
    const extractedProps: { [key: string]: any } = {};

    for (let i = 0; i < attrs.length; i++) {
        const isInWhiteList = ALLOWED_ELEMENT_ATTRIBUTES.includes(attrs[i].nodeName);
        const isTrackProp = attrs[i].nodeName.startsWith("data-track-");

        if (isInWhiteList || isTrackProp) {
            extractedProps[attrs[i].nodeName.replace("data-track-", "")] = attrs[i].nodeValue;
        }
    }

    return extractedProps;
}

function getElementContainers(el: HTMLElement) {
    return {
        "panel": el.closest("atlas-panel")?.getAttribute("header"),
        "section": el.closest("atlas-section")?.getAttribute("header"),
        "wizard-step": el.closest("atlas-wizard-step")?.getAttribute("header")
    };
}

/**
 * Emite um evento customizado para fazer tracks nos sistemas
 * @param {HTMLElement} element - O elemento que emitiu o evento principal
 * @param {string} name - O nome do evento que foi emitido
 * @param {object} options - Opções que serão passado ao evento (opcional)
 */
function emitTrackEvent(el: HTMLElement | Window | Document, name: string, options?: { [key: string]: any }): void {
    if (BLOCKED_EVENTS_TO_TRACK.includes(name)) return;

    let trackDetails = options?.detail || {};

    if (el instanceof HTMLElement) {
        trackDetails = {
            ...trackDetails,
            ...getElementContainers(el),
            ...extractElementProps(el)
        };
    }

    const filteredDetails = Object.keys(trackDetails).reduce((acc, detail) => {
        if (!trackDetails[detail]) {
            return acc;
        }

        return { ...acc, [detail]: trackDetails[detail] };
    }, {});

    const trackEvent = new CustomEvent("atlas-track-event", {
        bubbles: true,
        cancelable: false,
        composed: true,
        detail: {
            action: name,
            ...filteredDetails
        }
    });

    el.dispatchEvent(trackEvent);
}

/**
 * Emite um evento customizado, aplicando algumas opções padrões
 * @param {HTMLElement | Window} element - O elemento que vai emitir o evento
 * @param {string} name - O nome do evento a ser emitido
 * @param {object} options - Opções que serão passado ao evento (opcional)
 * @returns Evento que foi emitido
 */
export function emit(el: HTMLElement | Window | Document, name: string, options?: { [key: string]: any }): CustomEvent {
    const event = new CustomEvent(name, {
        bubbles: true,
        cancelable: true,
        composed: true,
        detail: {},
        ...options
    });

    el.dispatchEvent(event);

    emitTrackEvent(el, name, options);
    return event;
}
