import { LitElement, html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import DeviceController from "@/controllers/device-controller";
import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";
import { SelectOption } from "./types";
import type AtlasDropdown from "@/components/display/atlas-dropdown/atlas-dropdown";

import "./atlas-select-tags";
import "@/components/form/atlas-input/atlas-input";
import "@/components/form/atlas-select-item/atlas-select-item";
import "@/components/display/atlas-dropdown/atlas-dropdown";

/**
 * @dependency atlas-select-tags
 * @dependency atlas-input
 * @dependency atlas-select-item
 * @dependency atlas-dropdown
 *
 * @prop {string} header - O cabeçalho do dropdown (para versão mobile)
 * @prop {string} multiple - Indica se o select permite vários valores
 * @prop {string} loading - Indica se o select está carregando opções
 * @prop {string} options - As opções disponíveis no select
 * @prop {string} selectedOptions - As opções que já foram selecionadas
 * @prop {string} search-placeholder - O placeholder do input de pesquisa
 * @prop {string} empty-state-text - Mensagem que é exibida no dropdown quando o select não possui opções
 * @prop {string} extra-keys - Chave dos conteúdos extras que serão exibidos na opção do select, separados por ";"
 * @prop {string} groups - Objeto contendo o nome e a chave dos grupos do select
 *
 * @event {CustomEvent} atlas-select-dropdown-change - Evento disparado quando uma opção é selecionada (Via teclado ou clique)
 *
 * @tag atlas-select-dropdown
 */
@customElement("atlas-select-dropdown")
export default class AtlasSelectDropdown extends LitElement {
    @property({ type: String }) header: string;

    @property({ type: Boolean }) loading: boolean;

    @property({ type: Boolean }) multiple: boolean;

    @property({ type: Boolean, attribute: "enable-new" }) enableNew: boolean;

    @property({ type: String, attribute: "new-item-prefix" }) newItemPrefix: string = "Criar";

    @property({ type: Array }) options: SelectOption[] = [];

    @property({ type: Array }) selectedOptions: SelectOption[] = [];

    @property({ type: String, attribute: "search-value" }) searchValue: string;

    @property({ type: String, attribute: "search-placeholder" }) searchPlaceholder: string;

    @property({ type: String, attribute: "empty-state-text" }) emptyStateText: string = "Sem escolhas para fazer";

    @property({ type: String, attribute: "extra-keys" }) extraKeys: string;

    @property({ type: Object }) groups: { [key: string]: string } = {};

    @state() private _isDropdownOpen = false;

    @state() private _focusedOption = 0;

    @query("atlas-dropdown")
    private _dropdown: AtlasDropdown;

    private _deviceController = new DeviceController(this);

    connectedCallback() {
        super.connectedCallback?.();

        this.onDropdownOpen = this.onDropdownOpen.bind(this);
        this.onDropdownClose = this.onDropdownClose.bind(this);
        this.onMouseOverOption = this.onMouseOverOption.bind(this);
        this.onDocumentKeyDown = this.onDocumentKeyDown.bind(this);
        this.onSearchInputChange = this.onSearchInputChange.bind(this);
        this.onSearchInputKeyDown = this.onSearchInputKeyDown.bind(this);
    }

    canCreateNew() {
        return this.enableNew && this.searchValue && this.searchValue.trim() !== "";
    }

    findOptionWithValue(value: string) {
        return this.shadowRoot.querySelector(`atlas-select-item[value="${value}"]`) as HTMLElement;
    }

    findOptionWithIndex(index: number) {
        return this.shadowRoot.querySelector(`atlas-select-item[data-option-index="${index}"]`) as HTMLElement;
    }

    openDropdown() {
        this._dropdown.show();
    }

    closeDropdown() {
        this._dropdown.hide();
    }

    updateDropdownPosition() {
        this._dropdown.updatePosition();
    }

    onDropdownOpen() {
        this._isDropdownOpen = true;

        if (this._deviceController.isMobile) {
            const searchInput = this.shadowRoot.querySelector(".dropdown-search-input") as HTMLElement;

            setTimeout(() => {
                searchInput?.focus();
            }, 250);
        } else {
            document.addEventListener("keydown", this.onDocumentKeyDown);
            this.setFirstFocus();
        }
    }

    onDropdownClose() {
        this._isDropdownOpen = false;

        document.removeEventListener("keydown", this.onDocumentKeyDown);
        this.removeFocusFromOptions();
    }

    onDocumentKeyDown(event: KeyboardEvent) {
        const escapeKeys = ["Tab", "Escape"];
        const arrowKeys = ["ArrowUp", "ArrowDown"];
        const mappedKeys = ["Enter", ...escapeKeys, ...arrowKeys];

        if (!mappedKeys.includes(event.key)) return;

        if (arrowKeys.includes(event.key)) {
            event.preventDefault();
            this.changeFocusedOption(event);
        } else if (escapeKeys.includes(event.key)) {
            this.closeDropdown();
        } else if (event.key === "Enter") {
            this.selectFocusedOption();
        }
    }

    onClickOption(event: CustomEvent) {
        const option = event.target as HTMLElement;
        const optionValue = option.getAttribute("value");

        this.selectOption(optionValue);
    }

    onSearchInputChange(event: CustomEvent) {
        emit(this, "atlas-select-dropdown-search", {
            detail: event.detail
        });
    }

    onSearchInputKeyDown() {
        emit(this, "atlas-select-dropdown-input-keydown");
    }

    onMouseOverOption(event: MouseEvent) {
        this.removeFocusFromOptions();

        const targetOption = event.target as HTMLElement;

        this._focusedOption = parseInt(targetOption.dataset.optionIndex, 10);
        this.applyFocusOnOption();
    }

    @Watch("searchValue", true)
    setFirstFocus() {
        const firstSelected = this.selectedOptions[0]?.value;
        let focusedIndex = 0;

        if (firstSelected) {
            const option = this.findOptionWithValue(firstSelected);

            if (option) {
                focusedIndex = parseInt(option.dataset.optionIndex, 10);
            }
        }

        this._focusedOption = focusedIndex;
        this.removeFocusFromOptions();
        this.applyFocusOnOption();
    }

    removeFocusFromOptions() {
        this.shadowRoot.querySelectorAll(`atlas-select-item`).forEach((option) => {
            option.removeAttribute("focused");
        });
    }

    async applyFocusOnOption() {
        await this.updateComplete;

        const option = this.findOptionWithIndex(this._focusedOption);

        option?.toggleAttribute("focused", true);
        option?.scrollIntoView({
            block: "nearest",
            inline: "start",
            behavior: "smooth"
        });
    }

    changeFocusedOption(event: KeyboardEvent) {
        this.removeFocusFromOptions();

        const optionsLength = this.canCreateNew() ? this.options.length + 1 : this.options.length;
        const nextOption = event.key === "ArrowDown" ? this._focusedOption + 1 : this._focusedOption - 1;

        if (nextOption < 0) {
            this._focusedOption = optionsLength - 1;
        } else if (nextOption >= optionsLength) {
            this._focusedOption = 0;
        } else {
            this._focusedOption = nextOption;
        }

        this.applyFocusOnOption();
    }

    selectFocusedOption() {
        const option = this.options[this._focusedOption];

        if (option) {
            this.selectOption(option.value);
        }

        const focusedOption = this.findOptionWithIndex(this._focusedOption);

        if (focusedOption?.hasAttribute("data-is-create-new")) {
            this.createNewOption();
        }
    }

    selectOption(optionValue: string) {
        emit(this, "atlas-select-dropdown-change", {
            detail: {
                option: optionValue
            }
        });

        if (!this.multiple) {
            this.closeDropdown();
        }
    }

    createNewOption() {
        emit(this, "atlas-select-dropdown-create-new", {
            detail: {
                optionLabel: this.searchValue
            }
        });

        if (!this.multiple) {
            this.closeDropdown();
        }
    }

    renderDropdownSearch() {
        return when(
            this._deviceController.isMobile,
            () => html`
                <atlas-input
                    slot="subheading"
                    size="lg"
                    icon="magnifier"
                    class="dropdown-search-input"
                    placeholder=${this.searchPlaceholder}
                    value=${this.searchValue}
                    ?loading=${this.loading}
                    @atlas-input-change=${this.onSearchInputChange}
                    @keydown=${this.onSearchInputKeyDown}
                ></atlas-input>
                <atlas-select-tags
                    slot="subheading"
                    ?multiple=${this.multiple}
                    .selectedOptions=${this.selectedOptions}
                ></atlas-select-tags>
            `
        );
    }

    renderOptionsFromGroup(optionsFromGroup: SelectOption[], baseIndex: number) {
        const extraKeys = this.extraKeys && this.extraKeys.trim() ? this.extraKeys.split(";") : [];

        return optionsFromGroup.map(
            (option, index) => html`
                <atlas-select-item
                    value=${option.value}
                    label=${option.label}
                    highlighted-text=${this.searchValue}
                    ?selected=${this.selectedOptions.some((selected) => `${selected.value}` === `${option.value}`)}
                    ?disabled=${option.disabled}
                    .extraContent=${extraKeys.map((key) => option.customProperties[key])}
                    @atlas-select-item-click=${this.onClickOption}
                    @mouseover=${this.onMouseOverOption}
                    data-option-index=${baseIndex + index}
                ></atlas-select-item>
            `
        );
    }

    renderSelectOptions() {
        const groupKeys = Object.keys(this.groups);

        if (groupKeys.length > 0) {
            let countOptions = 0;

            return groupKeys.map((groupName) => {
                const groupOptions = this.options.filter((option) => option.group === groupName);

                if (groupOptions.length === 0) return null;

                countOptions += groupOptions.length;

                return html`
                    <atlas-select-item label=${this.groups[groupName]} is-group-title></atlas-select-item>
                    ${this.renderOptionsFromGroup(groupOptions, countOptions - groupOptions.length)}
                `;
            });
        }

        return this.renderOptionsFromGroup(this.options, 0);
    }

    renderCreateNew() {
        return when(
            this.canCreateNew(),
            () => html`
                <atlas-select-item
                    label=${`${this.newItemPrefix} ${this.searchValue}`}
                    icon="plus"
                    @atlas-select-item-click=${this.createNewOption}
                    @mouseover=${this.onMouseOverOption}
                    data-option-index=${this.options.length}
                    data-is-create-new
                ></atlas-select-item>
            `
        );
    }

    renderDropdownContent() {
        if (this.loading) {
            return html`<atlas-select-item label="Carregando opções..." disabled></atlas-select-item>`;
        }

        if (this.options.length === 0 && !this.canCreateNew()) {
            return html`<atlas-select-item label=${this.emptyStateText} disabled></atlas-select-item>`;
        }

        return html`${this.renderSelectOptions()}${this.renderCreateNew()}`;
    }

    render() {
        return html`
            <atlas-dropdown
                no-gap
                auto-close
                mobile-fullscreen
                auto-close-trigger=${this.multiple ? "outside" : "any"}
                max-height=${this.extraKeys ? 340 : 420}
                header=${this._deviceController.isMobile ? this.header : ""}
                @atlas-dropdown-opened=${this.onDropdownOpen}
                @atlas-dropdown-closed=${this.onDropdownClose}
                tabindex=${ifDefined(this._isDropdownOpen ? undefined : "-1")}
                id="select-dropdown"
            >
                ${this.renderDropdownSearch()} ${this.renderDropdownContent()}
            </atlas-dropdown>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-select-dropdown": AtlasSelectDropdown;
    }
}
