import { LitElement, html, HTMLTemplateResult } from "lit";
import { property } from "lit/decorators.js";

import { when } from "lit/directives/when.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";

import { Constructor } from "@/internals/basic-types";

import "@/components/display/atlas-popover/atlas-popover";

export type WithPopoverProps = {
    "popover-title": string;
    "popover-content": string;
};

export declare class WithPopoverInterface {
    popoverTitle: string;

    popoverContent: string;

    hasPopover(): boolean;

    /* eslint-disable no-unused-vars */
    renderPopover(id: string): HTMLTemplateResult;
    /* eslint-enable no-unused-vars */
}

export const WithPopoverMixin = <T extends Constructor<LitElement>>(superClass: T) => {
    /**
     * @dependency atlas-popover
     *
     * @prop {string} popover-title - O título que será exibido no popover
     * @prop {string} popover-content - O conteúdo do popover
     */
    class WithPopoverClass extends superClass {
        @property({ type: String, attribute: "popover-title" }) popoverTitle: string;

        @property({ type: String, attribute: "popover-content" }) popoverContent: string;

        hasPopover(): boolean {
            return !!this.popoverContent;
        }

        renderPopover(id: string): HTMLTemplateResult {
            return when(
                !!this.popoverContent,
                () => html`
                    <atlas-popover id=${id} header=${this.popoverTitle}>
                        ${unsafeHTML(this.popoverContent)}
                    </atlas-popover>
                `
            );
        }
    }

    return WithPopoverClass as Constructor<WithPopoverInterface> & T;
};
