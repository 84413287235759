import customElementsJson from "../../custom-elements.json";
import { emit } from "./events";

const getAllAtlasElements = () => {
    const atlasElements: string[] = [];

    customElementsJson.modules.forEach((elementModule) => {
        const declarations = elementModule.declarations?.[0] as { [key: string]: any };

        if (declarations.tagName) {
            atlasElements.push(declarations.tagName);
        }
    });

    return atlasElements;
};

const waitAllElementsDefine = async () => {
    const atlasElements = getAllAtlasElements();
    const definePromises = atlasElements.map((tagName) => window.customElements.whenDefined(tagName));

    await Promise.all(definePromises);

    emit(document, "AtlasContentLoaded");
};

document.addEventListener("DOMContentLoaded", () => {
    document.body.classList.add("has-atlas");
    waitAllElementsDefine();
});
