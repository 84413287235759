import { html } from "lit";
import { property, state } from "lit/decorators.js";
import { ClassInfo } from "lit/directives/class-map.js";
import { StyleInfo } from "lit/directives/style-map.js";
import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { getSlotTextContent, isEmptySlot } from "@/internals/slot";

import { Theme, ThemeVariation } from "@/internals/theme";
import { TextAlignment } from "@/internals/basic-types";

import AtlasElement, { AtlasElementProps } from "../atlas-element";
import "@/components/display/atlas-tooltip/atlas-tooltip";

export type TextElementProps = AtlasElementProps & {
    "theme": Theme;
    "theme-variation": ThemeVariation;
    "muted": boolean;
    "white": boolean;
    "ellipsis": boolean;
    "line-clamp": number;
    "alignment": TextAlignment;
};

/**
 * @dependency atlas-toolitp
 *
 * @prop {Theme} theme - Cor do texto
 * @prop {ThemeVariation} theme-variation - A variação da tonalidade da cor do tema
 * @prop {boolean} muted - Indica se o texto terá a cor cinza clara
 * @prop {boolean} white - Indica se o texto terá a cor branca
 * @prop {boolean} ellipsis - Indica se o texto possuirá ellipsis ao execeder a largura do elemento pai
 * @prop {number} line-clamp - Indica se o texto deve quebrar em uma certa linha e mostrar o ellipsis no final da linha definida
 * @prop {TextAlignment} alignment - Alinhamento do texto
 *
 */
export default abstract class TextElement extends AtlasElement {
    @property({ type: String }) theme: Theme;

    @property({ type: Number, attribute: "theme-variation" }) themeVariation: ThemeVariation;

    @property({ type: Boolean }) muted: boolean;

    @property({ type: Boolean }) white: boolean;

    @property({ type: Boolean }) ellipsis: boolean;

    @property({ type: Number, attribute: "line-clamp" }) lineClamp: number;

    @property({ type: String }) alignment: TextAlignment;

    @state() private _tooltipText = "";

    connectedCallback(): void {
        super.connectedCallback?.();
        this.onContentChange = this.onContentChange.bind(this);
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();
        window.removeEventListener("resize", this.onContentChange);
    }

    getThemeClass() {
        if (this.theme) {
            if (this.themeVariation) {
                return `color-${this.theme}-${this.themeVariation}`;
            }

            return `color-${this.theme}`;
        }

        return "";
    }

    getTextClasses(): ClassInfo {
        const themeClass = this.getThemeClass();

        return {
            "text-wrapper": true,
            "text-muted": this.muted,
            "text-white": this.white,
            "ellipsis": this.ellipsis,
            "line-clamp": !!this.lineClamp,
            [`text-align-${this.alignment}`]: !!this.alignment,
            [themeClass]: !!themeClass
        };
    }

    getTextStyles(): StyleInfo {
        return {
            "-webkit-line-clamp": this.lineClamp
        };
    }

    @Watch("ellipsis")
    onContentChange() {
        if (this.ellipsis) {
            window.addEventListener("resize", this.onContentChange);
            this.updateEllipsisTooltip();
        } else {
            window.removeEventListener("resize", this.onContentChange);
        }
    }

    async updateEllipsisTooltip() {
        await this.updateComplete;

        this._tooltipText = "";

        if (!this.ellipsis || this.skeletonLoading || isEmptySlot(this)) return;

        const contentWrapper = this.shadowRoot.querySelector(".text-wrapper") as HTMLElement;
        const contentWidth = contentWrapper.getBoundingClientRect().width;

        if (Math.ceil(contentWidth) < contentWrapper.scrollWidth) {
            this._tooltipText = getSlotTextContent(this.shadowRoot.querySelector("slot:not([name])"));
        }
    }

    renderEllipsisTooltip() {
        return when(
            !!this._tooltipText,
            () => html`<atlas-tooltip id="text-tooltip" placement="bottom">${this._tooltipText}</atlas-tooltip>`
        );
    }

    renderContentSlot() {
        return html`<slot @slotchange=${this.onContentChange}></slot>`;
    }
}
