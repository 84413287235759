import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import { Watch } from "@/decorators/watch";

import type AtlasTableHeader from "@/components/table/atlas-table-header/atlas-table-header";
import type AtlasTableBody from "@/components/table/atlas-table-body/atlas-table-body";
import type AtlasTableRow from "../atlas-table-row/atlas-table-row";
import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-table.scss";

export type TableProps = AtlasElementProps & {
    "has-actions": boolean;
    "selectable": boolean;
};

/**
 * @prop {boolean} has-actions - Indica se a table tem ações
 * @prop {boolean} selectable - Indica se a table permite múltipla seleção
 *
 * @slot header - Slot para colocar o cabeçalho da table
 * @slot body - Slot para colocar o corpo da table
 * @slot footer - Slot para colocar o rodapé da table
 *
 * @tag atlas-table
 */
@customElement("atlas-table")
export default class AtlasTable extends AtlasElement {
    static styles = styles;

    @property({ type: Boolean }) selectable = false;

    @property({ type: Boolean, attribute: "has-actions" }) hasActions = false;

    @state() private _hasFooter = false;

    connectedCallback() {
        super.connectedCallback?.();

        this.applyColumnPropsFromSync = this.applyColumnPropsFromSync.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.addEventListener("atlas-table-header-sync-cols", this.applyColumnPropsFromSync);
        this.addEventListener("atlas-table-select-all", this.onSelectAll);
        this.addEventListener("atlas-table-row-select", this.onSelectRow);
    }

    disconnectedCallback() {
        super.disconnectedCallback?.();
        this.removeEventListener("atlas-table-header-sync-cols", this.applyColumnPropsFromSync);
        this.removeEventListener("atlas-table-select-all", this.onSelectAll);
        this.removeEventListener("atlas-table-row-select", this.onSelectRow);
    }

    getSlottedHeader(): AtlasTableHeader {
        const headerSlot = this.shadowRoot.querySelector("slot[name=header]") as HTMLSlotElement;
        const slottedElements = headerSlot.assignedElements();

        return (slottedElements[0] as AtlasTableHeader) || null;
    }

    getSlottedBody(): AtlasTableBody {
        const bodySlot = this.shadowRoot.querySelector("slot[name=body]") as HTMLSlotElement;
        const slottedElements = bodySlot.assignedElements();

        return (slottedElements[0] as AtlasTableBody) || null;
    }

    onSlotChangeFooter() {
        const footerSlot = this.shadowRoot.querySelector("slot[name=footer]") as HTMLSlotElement;
        const footerContent = footerSlot.assignedElements();

        this._hasFooter = footerContent.length > 0;
    }

    @Watch(["selectable", "hasActions"])
    async applyPropsInSlots() {
        await this.updateComplete;

        const tableHeader = this.getSlottedHeader();
        const tableBody = this.getSlottedBody();

        tableHeader?.toggleAttribute("selectable", this.selectable);
        tableHeader?.toggleAttribute("has-actions", this.hasActions);

        tableBody?.toggleAttribute("selectable", this.selectable);
        tableBody?.toggleAttribute("has-actions", this.hasActions);
    }

    async applyColumnPropsFromSync(event: CustomEvent) {
        await this.updateComplete;

        const tableBody = this.getSlottedBody();
        const colsProps: any[] = event.detail;

        tableBody.applyPropsFromHeader(colsProps);
    }

    async onSelectAll(event: CustomEvent) {
        const tableBody = this.getSlottedBody();
        const allSelected = event.detail;

        (await tableBody.getSlottedRows()).forEach((row: AtlasTableRow) => {
            row.toggleSelection(allSelected);
        });
    }

    async onSelectRow() {
        const tableBody = this.getSlottedBody();
        const allSelected = (await tableBody.getSlottedRows()).every((row: AtlasTableRow) => row.selected);

        this.getSlottedHeader().allSelected = allSelected;
    }

    render() {
        const tableClass = {
            "atlas-table": true,
            "has-footer": this._hasFooter
        };

        return html`
            <div class="table-wrapper">
                <div class="table-scroll">
                    <div class=${classMap(tableClass)}>
                        <div class="table-header">
                            <slot name="header"></slot>
                        </div>
                        <div class="table-body">
                            <slot name="body"></slot>
                        </div>
                    </div>
                </div>
                <slot name="footer" @slotchange=${this.onSlotChangeFooter}></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-table": AtlasTable;
    }
}
