import { LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";

/**
 * @prop {string} value - O valor da opção
 * @prop {string} label - O texto que irá descrever a opção
 * @prop {string} group - Grupo ao qual a opção pertence
 * @prop {boolean} disabled - Booleano que define se a opção está desabilitada
 * @prop {boolean} selected - Booleano que informa se a opção está selecionada
 */
@customElement("atlas-option")
export default class AtlasOption extends LitElement {
    @property({ type: String }) value = "";

    @property({ type: String }) label = "";

    @property({ type: String }) group = "";

    @property({ type: Boolean, reflect: true }) disabled = false;

    @property({ type: Boolean, reflect: true }) selected = false;
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-option": AtlasOption;
    }
}
