import { html } from "lit";
import { customElement, property } from "lit/decorators.js";

import AtlasElement from "@/components/atlas-element";
import type { AtlasElementProps } from "@/components/atlas-element";

import styles from "./atlas-col.scss";

export type ColProps = AtlasElementProps & {
    sm: number;
    md: number;
    lg: number;
};

/**
 * @prop {number} sm - O valor do tamanho de coluna para a quebra sm (Deve ser um valor entre 1 e 2)
 * @prop {number} md - O valor do tamanho de coluna para a quebra md (Deve ser um valor entre 1 e 6)
 * @prop {number} lg - O valor do tamanho de coluna para a quebra lg (Deve ser um valor entre 1 e 12)
 *
 * @slot - Usado para incluir o conteúdo que ficará dentro da coluna
 */
@customElement("atlas-col")
class AtlasCol extends AtlasElement {
    static styles = styles;

    @property({ type: Number, reflect: true }) sm: number = 2;

    @property({ type: Number, reflect: true }) md: number = 6;

    @property({ type: Number, reflect: true }) lg: number = 12;

    render() {
        return html`<slot></slot>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-col": AtlasCol;
    }
}
