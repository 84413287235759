import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { map } from "lit/directives/map.js";
import { range } from "lit/directives/range.js";
import { when } from "lit/directives/when.js";
import { styleMap } from "lit/directives/style-map.js";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-carousel.scss";

import DeviceController from "@/controllers/device-controller";

import "@/components/display/atlas-icon/atlas-icon";

export type CarouselProps = AtlasElementProps & {
    "items-per-page": number;
    "inside-content": boolean;
};

/**
 * @dependency atlas-icon
 *
 * @prop {number} items-per-page - Número de itens que vão aparecer em uma página do carrossel
 * @prop {boolean} inside-content - Define se os controles do carrossel ficarão dentro do conteúdo
 *
 * @tag atlas-carousel
 */
@customElement("atlas-carousel")
export default class AtlasCarousel extends AtlasElement {
    static styles = styles;

    @property({ type: Number, attribute: "items-per-page" }) itemsPerPage: number = 1;

    @property({ type: Boolean, attribute: "inside-content" }) insideContent: boolean = false;

    @state() private _currentPage = 0;

    @state() private _countItems = 0;

    @state() private _countPages = 0;

    @state() private _autoPlayId: any;

    @state() private _autoPlayPaused: boolean;

    private _deviceController = new DeviceController(this, this.onScreenModeChange.bind(this));

    connectedCallback() {
        super.connectedCallback?.();

        this.addEventListener("mouseover", this.pauseAutoPlay);
        this.addEventListener("mouseout", this.resumeAutoPlay);
        this.updateComplete.then(() => {
            this.registerCarouselAutoPlay();
        });
    }

    disconnectedCallback() {
        super.disconnectedCallback?.();

        this.cancelCarouselAutoPlay();
        this.removeEventListener("mouseover", this.pauseAutoPlay);
        this.removeEventListener("mouseout", this.resumeAutoPlay);
    }

    registerCarouselAutoPlay() {
        if (!this._deviceController.isMobile && this._countPages > 1) {
            this._autoPlayId = setInterval(() => {
                if (!this._autoPlayPaused) {
                    this.onClickNextPage();
                }
            }, 5000);
        }
    }

    cancelCarouselAutoPlay() {
        if (this._autoPlayId) {
            clearInterval(this._autoPlayId);
        }
    }

    pauseAutoPlay() {
        this._autoPlayPaused = true;
    }

    resumeAutoPlay() {
        this._autoPlayPaused = false;
    }

    getSlottedItems(slotElement: HTMLSlotElement = null): Element[] {
        if (slotElement.assignedElements()[0]?.tagName === "SLOT") {
            return this.getSlottedItems(slotElement.assignedElements()[0] as HTMLSlotElement);
        }

        return slotElement.assignedElements();
    }

    getItemsPerPage() {
        if (this._deviceController.isMobile || this.itemsPerPage < 1) return 1;

        if (this.itemsPerPage > 4) return 4;

        return this.itemsPerPage;
    }

    updatePagesCount() {
        this._countPages = Math.ceil(this._countItems / this.getItemsPerPage());
    }

    updateItemsSize() {
        const items = this.getSlottedItems(this.shadowRoot.querySelector("slot:not([name])"));
        const sizeInPercent = 100 / this.getItemsPerPage();

        items.forEach((element: HTMLElement) => {
            /* eslint-disable no-param-reassign */
            element.style.display = `flex`;
            element.style.flex = `1 0 ${sizeInPercent}%`;
            element.style.boxSizing = "border-box";
            /* eslint-enable no-param-reassign */
        });

        this.toggleEmptySlots();
    }

    toggleEmptySlots() {
        const itemsPerPage = this.getItemsPerPage();
        const remainingItemsOnLastPage =
            this._countPages === 1
                ? itemsPerPage - this._countItems
                : this._countPages * itemsPerPage - this._countItems;

        this.shadowRoot.querySelectorAll(".carousel-items .ghost").forEach((ghostItem) => {
            ghostItem.remove();
        });

        if (remainingItemsOnLastPage > 0) {
            const sizeInPercent = 100 / itemsPerPage;

            for (let i = 0; i < remainingItemsOnLastPage; i++) {
                const ghostElement = document.createElement("div");

                ghostElement.style.flex = `1 0 ${sizeInPercent}%`;
                ghostElement.classList.add("ghost");

                this.shadowRoot.querySelector(".carousel-items").appendChild(ghostElement);
            }
        }
    }

    showItemsFromCurrentPage() {
        const pageWidth = this.shadowRoot.querySelector(".carousel-content").clientWidth;
        const leftScroll = (pageWidth + 16) * this._currentPage;

        this.shadowRoot.querySelector(".carousel-content").scroll({
            top: 0,
            left: leftScroll,
            behavior: "smooth"
        });
    }

    changePage(pageNumber: number) {
        this._currentPage = pageNumber;

        this.showItemsFromCurrentPage();
    }

    onClickPreviousPage() {
        let previousPage = this._currentPage - 1;

        if (previousPage < 0) {
            previousPage = this._countPages - 1;
        }

        this.changePage(previousPage);
    }

    onClickNextPage() {
        let nextPage = this._currentPage + 1;

        if (nextPage > this._countPages - 1) {
            nextPage = 0;
        }

        this.changePage(nextPage);
    }

    onSlotChange() {
        const items = this.getSlottedItems(this.shadowRoot.querySelector("slot:not([name])"));

        this._countItems = items.length;
        this.updatePagesCount();
        this.updateItemsSize();
        this.showItemsFromCurrentPage();
    }

    async onScreenModeChange() {
        await this.updateComplete;

        if (this._deviceController.isMobile) {
            this.cancelCarouselAutoPlay();
        } else {
            this.registerCarouselAutoPlay();
        }

        this._currentPage = 0;
        this.updatePagesCount();
        this.updateItemsSize();
        this.showItemsFromCurrentPage();
    }

    renderCarouselPages() {
        return map(range(this._countPages), (pageNumber) => {
            const onClick = this.changePage.bind(this, pageNumber);
            const buttonClass = {
                "carousel-page-control": true,
                "active": pageNumber === this._currentPage
            };

            return html`
                <button
                    class=${classMap(buttonClass)}
                    @click=${onClick}
                    aria-label=${`Página ${pageNumber + 1}`}
                ></button>
            `;
        });
    }

    renderCarouselControls() {
        const carouselControlsClass = {
            "carousel-controls": true,
            "inside-content": this.insideContent
        };

        return when(
            this._countItems > this.getItemsPerPage(),
            () => html`
                <div class="${classMap(carouselControlsClass)}">
                    <button @click=${this.onClickPreviousPage}>
                        <atlas-icon name="chevron-left" size="3x"></atlas-icon>
                    </button>
                    ${this.renderCarouselPages()}
                    <button @click=${this.onClickNextPage}>
                        <atlas-icon name="chevron-right" size="3x"></atlas-icon>
                    </button>
                </div>
            `
        );
    }

    renderContent() {
        const itemsPerPage = this.getItemsPerPage();
        const sizeDiff = itemsPerPage > 1 ? `${itemsPerPage - 1}rem` : `0`;
        const carouselStyles =
            itemsPerPage > 1 ? { paddingLeft: sizeDiff, marginLeft: `-${sizeDiff}`, marginRight: sizeDiff } : {};

        return html`
            <div class="carousel-items" style=${styleMap(carouselStyles)}>
                <slot @slotchange=${this.onSlotChange}></slot>
            </div>
        `;
    }

    render() {
        return html`
            <div class="carousel">
                <div class="carousel-content">${this.renderContent()}</div>
                ${this.renderCarouselControls()}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-carousel": AtlasCarousel;
    }
}
