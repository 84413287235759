import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { ifDefined } from "lit/directives/if-defined.js";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-helper.scss";

import { emit } from "@/internals/events";
import "@/components/display/atlas-icon/atlas-icon";

export type HelperProps = AtlasElementProps & {
    message: string;
    href: string;
    external: boolean;
};

/**
 * O helper é usado para levar mais informações ao usuário, através do direcionamento, sobre o contexto em que ele se encontra.
 *
 * @dependency atlas-icon
 *
 * @prop {string} message - A mensagem que será exibida no helper
 * @prop {string} href - Link que será aberto ao clicar no helper
 * @prop {boolean} external - Indica se o link que vai ser aberto ao clicar no helper é um link externo
 *
 * @tag atlas-helper
 */
@customElement("atlas-helper")
export default class AtlasHelper extends AtlasElement {
    static styles = styles;

    @property({ type: String }) message: string = "";

    @property({ type: String }) href: string;

    @property({ type: Boolean }) external: boolean;

    onClickHelper() {
        emit(this, "atlas-helper-click");
    }

    renderContent() {
        return html`
            <atlas-icon name="help-circle" size="3x" class="helper-icon"></atlas-icon>
            <span class="helper-text">
                <slot>${this.message}</slot>
            </span>
            <atlas-icon name="chevron-right" size="3x" class="helper-icon"></atlas-icon>
        `;
    }

    render() {
        return when(
            !!this.href,
            () => html`
                <a
                    class="atlas-helper"
                    href=${this.href}
                    rel="${ifDefined(this.external ? "noreferrer noopener" : undefined)}"
                    target="${ifDefined(this.external ? "_blank" : undefined)}"
                    @click=${this.onClickHelper}
                >
                    ${this.renderContent()}
                </a>
            `,
            () => html` <button class="atlas-helper" @click=${this.onClickHelper}>${this.renderContent()}</button> `
        );
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-helper": AtlasHelper;
    }
}
