import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import { emit } from "../../../internals/events";
import AtlasInput, { InputProps } from "../atlas-input/atlas-input";

export type SearchInputProps = InputProps & {};

/**
 * @event {CustomEvent} atlas-input-trigger-search - Evento lançada quando a pesquisa é acionada (Através do botão | Ao apertar "Enter" no campo | Ao clicar para limpar o campo)
 *
 * @tag atlas-search-input
 */
@customElement("atlas-search-input")
export default class AtlasSearchInput extends AtlasInput {
    connectedCallback(): void {
        super.connectedCallback?.();

        this.onInputKeyDown = this.onInputKeyDown.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onClickClear = this.onClickClear.bind(this);
        this.triggerSearch = this.triggerSearch.bind(this);

        this.hasIconEvent = true;
        this.actionButtonIcon = "magnifier";
        this.actionButtonLabel = "Pesquisar";

        this.updateComplete.then(() => {
            this.addEventListener("atlas-input-change", this.onInputChange);
            this.addEventListener("atlas-input-icon-click", this.onClickClear);
            this.addEventListener("atlas-input-action-button-click", this.triggerSearch);
            this._input.addEventListener("keydown", this.onInputKeyDown);
        });
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-input-change", this.onInputChange);
        this.removeEventListener("atlas-input-icon-click", this.onClickClear);
        this.removeEventListener("atlas-input-action-button-click", this.triggerSearch);

        this.updateComplete.then(() => {
            this._input.removeEventListener("keydown", this.onInputKeyDown);
        });
    }

    onInputKeyDown(event: KeyboardEvent) {
        if (event.key === "Enter") {
            this.triggerSearch();
        }
    }

    onInputChange(event: CustomEvent) {
        this.icon = event.detail ? "x" : "";
    }

    onClickClear() {
        this.value = "";
        this.triggerSearch();
    }

    triggerSearch() {
        if (this.readonly || this.disabled || this.loading) return;

        emit(this, "atlas-input-trigger-search", {
            detail: this.value
        });
    }

    renderSkeleton() {
        const inputSkeletonClass = {
            "skeleton": true,
            "form-control": true,
            "form-control-sm": this.size === "sm",
            "form-control-lg": this.size === "lg" || this._deviceController.isMobile
        };

        return html` <div class="${classMap(inputSkeletonClass)}" style="width: 100%;"></div> `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-search-input": AtlasSearchInput;
    }
}
