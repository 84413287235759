import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";

import styles from "./atlas-page-content.scss";

export type PageContentProps = {};

/**
 * @tag atlas-page-content
 */
@customElement("atlas-page-content")
export default class AtlasPageContent extends LitElement {
    static styles = styles;

    render() {
        return html`
            <div class="page-content">
                <slot></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-page-content": AtlasPageContent;
    }
}
