import { html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import FormElement, { FormElementProps } from "@/components/form/form-element";
import { emit } from "@/internals/events";

import styles from "./atlas-switch.scss";

export type SwitchProps = FormElementProps & {
    "label": string;
    "description": string;
    "checked": Boolean;
    "label-before": Boolean;
    "atlas-switch-change": Function;
};

/**
 * @prop {string} label - Serve para definir o texto que aparece ao lado do switch
 * @prop {string} description - Serve para definir a descrição que a label tem - automaticamente vira uma label-before
 * @prop {boolean} checked - Booleano que define se o switch está marcado
 * @prop {boolean} label-before - Booleano que indica se a label deve vir antes do switch
 *
 * @event {CustomEvent} atlas-switch-change - Evento disparado quando o switch é marcado ou desmarcado
 */
@customElement("atlas-switch")
export default class AtlasSwitch extends FormElement {
    static styles = styles;

    @property({ type: String }) label = "";

    @property({ type: String }) description = "";

    @property({ type: Boolean, reflect: true }) checked = false;

    @property({ type: Boolean, attribute: "label-before" }) labelBefore = false;

    getElementValue(): boolean {
        return this.checked;
    }

    focus() {
        (this.shadowRoot.querySelector(".form-check-input") as HTMLInputElement).focus();
    }

    check() {
        this.checked = true;
    }

    uncheck() {
        this.checked = false;
    }

    enable() {
        this.disabled = false;
    }

    disable() {
        this.disabled = true;
    }

    handleChange() {
        if (!this.disabled) {
            this.checked = !this.checked;

            emit(this, "atlas-switch-change");
            emit(this, "atlas-form-element-touch");
        }
    }

    renderLabel() {
        if (this.description) {
            return html`
                <label class="form-check-label with-description" for="${this.id || "checkbox_id"}">
                    <span class="label"> ${this.label} </span>
                    <div>
                        <atlas-text muted size="xsm">${this.description}</atlas-text>
                    </div>
                </label>
            `;
        }
        return html`<label class="form-check-label" for="${this.id || "checkbox_id"}">${this.label}</label>`;
    }

    renderSwitchHandle() {
        /* eslint-disable lit-a11y/click-events-have-key-events */
        return html`
            <svg
                class="switch-handle"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @click=${this.handleChange}
            >
                <circle cx="10" cy="10" r="10" fill="#000000" />
            </svg>
        `;
        /* eslint-enable lit-a11y/click-events-have-key-events */
    }

    renderSwitch() {
        const switchClass = {
            "form-check-input": true,
            [`is-${this._status}`]: this.getShowStatus()
        };

        return html`
            <span class="switch-input-container">
                <input
                    class="${classMap(switchClass)}"
                    type="checkbox"
                    role="switch"
                    id="${this.id || "checkbox_id"}"
                    name="${this.name}"
                    .checked=${this.checked}
                    ?required=${this.required}
                    ?disabled=${this.disabled}
                    aria-labelledby="${this.id}"
                    aria-checked="${this.checked}"
                    aria-disabled="${this.disabled}"
                    aria-required="${this.required}"
                    @change="${this.handleChange}"
                />
                ${this.renderSwitchHandle()}
            </span>
        `;
    }

    render() {
        const switchContainerClass = {
            "form-check": true,
            "form-switch": true,
            "form-check-disabled": this.disabled,
            "has-description": !!this.description,
            "has-label": !!this.label
        };

        const content = when(
            this.labelBefore,
            () => html` ${this.renderLabel()} ${this.renderSwitch()} `,
            () => html` ${this.renderSwitch()} ${this.renderLabel()} `
        );

        return html` <div class="${classMap(switchContainerClass)}">${content}</div> `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-switch": AtlasSwitch;
    }
}
