import { html } from "lit";
import { customElement, property, queryAssignedElements } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { classMap } from "lit/directives/class-map.js";

import { emit } from "@/internals/events";
import { Watch } from "@/decorators/watch";

import { WithCollapseMixin, WithCollapseProps } from "@/internals/mixins/with-collapse-mixin";

import FormElement, { FormElementProps } from "@/components/form/form-element";

import styles from "./atlas-critical-action-item.scss";

import "@/components/form/atlas-checkbox/atlas-checkbox";

export type CriticalActionItemProps = WithCollapseProps &
    FormElementProps & {
        checked: boolean;
        label: string;
        expired: boolean;
    };

/**
 * @dependency atlas-checkbox
 *
 * @prop {boolean} checked - Propriedade que indica se o item está checado
 * @prop {boolean} expired - Propriedade que indica se o item está expirado
 * @prop {string} label - Propriedade que define a label do item
 *
 * @tag atlas-critical-action-item
 */
@customElement("atlas-critical-action-item")
export default class AtlasCriticalActionItem extends WithCollapseMixin(FormElement) {
    static styles = styles;

    @property({ type: String }) owner!: string;

    @property({ type: String }) label: string;

    @property({ type: Boolean, reflect: true }) checked: boolean = false;

    @property({ type: Boolean, reflect: true }) expired: boolean = false;

    @queryAssignedElements({ selector: "atlas-critical-action-item", flatten: true })
    public subItems!: Array<HTMLElement>;

    public ownerElement!: HTMLElement;

    private _preventCheckDefaultBehavior = false;

    constructor() {
        super();

        this.onCheckboxChange = this.onCheckboxChange.bind(this);
    }

    setOwnerElement(ownerElement: HTMLElement) {
        this.ownerElement = ownerElement;
    }

    @Watch("expired", true)
    setExpiredState() {
        if (!this.hasSubItems()) {
            (this.ownerElement as AtlasCriticalActionItem)?.updateExpiredState();
            return;
        }

        this.subItems.forEach((element: AtlasCriticalActionItem) => {
            /* eslint-disable no-param-reassign */
            element.expired = this.expired;
            /* eslint-enable no-param-reassign */
        });
    }

    @Watch("disabled", true)
    setDisabledState() {
        if (!this.hasSubItems()) {
            (this.ownerElement as AtlasCriticalActionItem)?.updateDisabledState();
            return;
        }

        this.subItems.forEach((element: AtlasCriticalActionItem) => {
            /* eslint-disable no-param-reassign */
            element.disabled = this.disabled;
            /* eslint-enable no-param-reassign */
        });
    }

    connectedCallback(): void {
        super.connectedCallback?.();

        this.addEventListener("atlas-checkbox-change", this.onCheckboxChange);
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-checkbox-change", this.onCheckboxChange);
    }

    async onSlotChange() {
        await this.updateComplete;

        if (!this.hasSubItems()) return;

        this.collapsible = true;
        this.collapsed = true;

        this.subItems.forEach((element: AtlasCriticalActionItem) => {
            element.setAttribute("owner", this.name);
            element.setOwnerElement(this);
        });

        this.updateDisabledState();
        this.updateExpiredState();
    }

    updateDisabledState() {
        if (this.disabled) this.setDisabledState();
        else this.disabled = this.subItems.every((element: AtlasCriticalActionItem) => element.disabled);
    }

    updateExpiredState() {
        if (this.expired) this.setExpiredState();
        else this.expired = this.subItems.every((element: AtlasCriticalActionItem) => element.expired);
    }

    hasSubItems() {
        return this.subItems.length > 0;
    }

    check() {
        this._preventCheckDefaultBehavior = true;
        this.checked = true;
    }

    uncheck() {
        this._preventCheckDefaultBehavior = true;
        this.checked = false;
    }

    toggleChecked(checked?: boolean) {
        this._preventCheckDefaultBehavior = true;

        if (typeof checked === "boolean") {
            this.checked = checked;
        } else {
            this.checked = !this.checked;
        }
    }

    getElementValue(): boolean | string {
        if (this.value && this.checked) {
            return this.value;
        }

        return this.checked;
    }

    getSubItemsType() {
        let expiredSubItems = 0;
        let pendingSubItems = 0;
        let disabledSubItems = 0;

        this.subItems.forEach((element: AtlasCriticalActionItem) => {
            if (element.expired) expiredSubItems += 1;
            else if (element.disabled) disabledSubItems += 1;
            else pendingSubItems += 1;
        });

        return { expiredSubItems, pendingSubItems, disabledSubItems };
    }

    getPluralizedSubItemsTypeDescription(type: string, quantity: number) {
        return `evento${quantity > 1 ? "s" : ""} ${type}${quantity > 1 ? "s" : ""}`;
    }

    getSubItemsDescription() {
        const { expiredSubItems, pendingSubItems, disabledSubItems } = this.getSubItemsType();
        const description = [];

        if (pendingSubItems) {
            description.push(
                `${pendingSubItems}${
                    pendingSubItems !== this.subItems.length
                        ? ` ${this.getPluralizedSubItemsTypeDescription("pendente", pendingSubItems)}`
                        : ""
                }`
            );
        }

        if (expiredSubItems) {
            description.push(
                `${expiredSubItems}${
                    expiredSubItems !== this.subItems.length
                        ? ` ${this.getPluralizedSubItemsTypeDescription("expirado", expiredSubItems)}`
                        : ""
                }`
            );
        }

        if (disabledSubItems) {
            description.push(
                `${disabledSubItems}${
                    disabledSubItems !== this.subItems.length
                        ? ` ${this.getPluralizedSubItemsTypeDescription("desabilitado", disabledSubItems)}`
                        : ""
                }`
            );
        }

        return description.join(" e ");
    }

    toggleCheckedAllSubItems() {
        this.subItems.forEach((element: AtlasCriticalActionItem) => {
            if (element.expired || element.disabled) return;

            if (this.checked) {
                if (element.checked) return;

                element.check();
            } else {
                if (!element.checked) return;

                element.uncheck();
            }
        });
    }

    toggleCheckedActionGroup() {
        const groupElement = this.ownerElement as AtlasCriticalActionItem;

        if (!groupElement) return;

        const hasAnyChildrenChecked = groupElement.subItems.some((element: AtlasCriticalActionItem) => element.checked);

        if (hasAnyChildrenChecked) {
            if (groupElement.checked) return;

            groupElement.check();
        } else {
            if (!groupElement.checked) return;

            groupElement.uncheck();
        }
    }

    onCheckboxChange(event: CustomEvent) {
        event.stopImmediatePropagation();

        const { checked } = event.detail;

        this.checked = checked;

        if (this._preventCheckDefaultBehavior) {
            this._preventCheckDefaultBehavior = false;
            return;
        }

        if (this.hasSubItems()) this.toggleCheckedAllSubItems();
        else this.toggleCheckedActionGroup();
    }

    @Watch("checked", true)
    onChangeChecked() {
        const eventObject = {
            detail: {
                group: this.hasSubItems(),
                name: this.name,
                value: this.value,
                checked: this.checked
            }
        };

        emit(this, `atlas-critical-action-item-${this.checked ? "check" : "uncheck"}`, eventObject);

        emit(this, "atlas-critical-action-item-change", eventObject);
    }

    renderItemCollapse() {
        return when(
            this.hasSubItems(),
            () => html` ${this.renderCollapseButton("critical-action-item", "critical-action-item")} `
        );
    }

    render() {
        const criticalActionItemClass = {
            "critical-action-item": true,
            "critical-action-item-parent": !this.owner
        };

        return html`
            <div class="${classMap(criticalActionItemClass)}">
                <atlas-checkbox
                    name="${this.name}"
                    value="${this.value}"
                    ?checked=${this.checked}
                    ?required=${this.required}
                    ?disabled=${this.disabled}
                    ?expired=${this.expired}
                >
                    ${this.label} ${when(this.hasSubItems(), () => html`(${this.getSubItemsDescription()})`)}
                </atlas-checkbox>

                ${this.renderItemCollapse()}
            </div>
            ${this.renderContentWithCollapse(html`
                <div class="critical-action-sub-item">
                    <slot @slotchange=${this.onSlotChange}></slot>
                </div>
            `)}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-critical-action-item": AtlasCriticalActionItem;
    }
}
