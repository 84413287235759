import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";

import styles from "./atlas-page.scss";

export type PageProps = {};

/**
 * @slot header - Slot onde deve ser incluido o cabeçalho da página
 * @slot content - Slot onde deve ser incluido o conteúdo da página
 *
 * @tag atlas-page
 */
@customElement("atlas-page")
export default class AtlasPage extends LitElement {
    static styles = styles;

    render() {
        return html`
            <div class="page">
                <div class="page-header">
                    <slot name="header"></slot>
                </div>
                <div class="page-content">
                    <slot name="content"></slot>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-page": AtlasPage;
    }
}
