import { LitElement, html } from "lit";
import { customElement, property, queryAssignedElements, state } from "lit/decorators.js";

import { when } from "lit/directives/when.js";
import { Watch } from "@/decorators/watch";

import { CHECK_ELEMENTS, SELECT_ELEMENTS, INPUT_ELEMENTS } from "@/helpers/form";

import type AtlasRadio from "@/components/form/atlas-radio/atlas-radio";
import type AtlasCheckbox from "@/components/form/atlas-checkbox/atlas-checkbox";
import AtlasLayout, { LayoutProps } from "@/components/layout/atlas-layout/atlas-layout";

import styles from "./atlas-element-group.scss";

export type ElementGroupProps = LayoutProps & {
    "name": string;
    "disabled": boolean;
    "required-fields": string;
    "required-fields-error-message": string;
};

/**
 * @prop {string} name - Nome do grupo, repassa o mesmo nome para os elementos filhos (Útil para definir o mesmo nome em radios, sem precisar definir em cada elemento)
 * @prop {boolean} disabled - Indica se o grupo está desabilitado
 * @prop {number} required-fields - Numero de campos requeridos dentro de um group
 * @prop {string} required-fields-error-message - Mensagem de erro para quando nao possui o numero de campos requeridos
 *
 * @tag atlas-element-group
 */
@customElement("atlas-element-group")
export default class AtlasElementGroup extends AtlasLayout {
    static styles = styles;

    @property({ type: String }) name = "";

    @property({ type: Boolean }) disabled = false;

    @property({ type: Number, attribute: "required-fields" }) requiredFields = 0;

    @property({ type: String, attribute: "required-fields-error-message" }) requiredFieldsErrorMessage = "";

    @state() protected _valid = true;

    @queryAssignedElements({ flatten: true })
    protected slottedElements: Array<LitElement>;

    protected _errorMessage: string;

    constructor() {
        super();

        this.onChangeCheckedState = this.onChangeCheckedState.bind(this);
    }

    connectedCallback(): void {
        super.connectedCallback?.();

        this.addEventListener("atlas-checkbox-change", this.onChangeCheckedState);
        this.addEventListener("atlas-radio-change", this.onChangeCheckedState);
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-checkbox-change", this.onChangeCheckedState);
        this.removeEventListener("atlas-radio-change", this.onChangeCheckedState);
    }

    checkValidity(): boolean {
        this._valid = this.countCheckedFields() >= this.requiredFields;

        return this._valid;
    }

    reportValidity(): boolean {
        const isValid = this.checkValidity();
        this.validateRequiredFields();
        return isValid;
    }

    onChangeCheckedState() {
        this.reportValidity();
    }

    getAllChildrenCheckElements() {
        return Array.from(this.querySelectorAll(CHECK_ELEMENTS.toString()));
    }

    getAllChildrenSelectElements() {
        return Array.from(this.querySelectorAll(SELECT_ELEMENTS.toString()));
    }

    getAllChildrenInputElements() {
        return Array.from(this.querySelectorAll(INPUT_ELEMENTS.toString()));
    }

    validateRequiredFields() {
        this.getAllChildrenCheckElements().forEach((slotted: AtlasRadio | AtlasCheckbox) => {
            slotted.setValidationState(this._valid);
        });
    }

    countCheckedFields() {
        return this.getAllChildrenCheckElements().reduce(
            (acc: number, slotted: AtlasRadio | AtlasCheckbox) => (slotted.checked ? acc + 1 : acc),
            0
        );
    }

    setPropertiesToSlottedElements() {
        this.getAllChildrenCheckElements().forEach((element: AtlasCheckbox | AtlasRadio) => {
            element.toggleAttribute("disabled", this.disabled);
            element.toggleAttribute("ignore-validations", this.requiredFields > 0);

            if (!this.name || element.name) return;
            element.setAttribute("name", this.name);
        });
    }

    @Watch(["requiredFields", "requiredFieldsErrorMessage"], false)
    setRequiredFieldsErrorMessage() {
        if (this.requiredFieldsErrorMessage) {
            this._errorMessage = this.requiredFieldsErrorMessage;
            return;
        }

        this._errorMessage = `Selecione pelo menos ${this.requiredFields} 
        ${this.requiredFields > 1 ? " opções " : " opção "} para continuar`;
    }

    @Watch("disabled", true)
    shouldDisableSlottedElements() {
        this.getAllChildrenCheckElements().forEach((slotted) => {
            slotted.toggleAttribute("disabled", this.disabled);
        });
    }

    async onSlotChange() {
        await this.updateComplete;

        const buttonElements = ["ATLAS-BUTTON", "ATLAS-DROPDOWN-BUTTON"];

        this.slottedElements
            .filter((element) => buttonElements.includes(element.tagName))
            .forEach((element) => {
                element.toggleAttribute("block", this._deviceController.isMobile);
            });

        this.setPropertiesToSlottedElements();
    }

    renderValidationMessage() {
        return when(
            !this._valid,
            () => html`
                <atlas-text size="xsm" theme="danger" class="invalid-feedback"> ${this._errorMessage} </atlas-text>
            `
        );
    }

    render() {
        return html` ${super.render()} ${this.renderValidationMessage()} `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-element-group": AtlasElementGroup;
    }
}
