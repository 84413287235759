import { literal, html, unsafeStatic } from "lit/static-html.js";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { styleMap } from "lit/directives/style-map.js";

import { HeadingSize } from "./types";

import TextElement, { TextElementProps } from "../text-element";
import styles from "./atlas-heading.scss";

export type HeadingProps = TextElementProps & {
    size: HeadingSize;
};

/**
 * Componente de tipografia relacionado aos tipos de titulos e cabeçalhos que existem
 *
 * @prop {string} size - O tamanho de heading desejado, dentre os tamanhos possiveis
 *
 * @tag atlas-heading
 */
@customElement("atlas-heading")
export default class AtlasHeading extends TextElement {
    static styles = styles;

    @property({ type: String }) size: HeadingSize = "h4";

    renderSkeleton() {
        const skeletonClass = {
            "atlas-heading": true,
            "skeleton": true,
            [this.size]: !!this.size
        };

        return html`<div class="${classMap(skeletonClass)}"></div>`;
    }

    renderElement() {
        const headingClass = {
            "atlas-heading": true,
            [this.size]: !!this.size,
            ...this.getTextClasses()
        };

        const tag = this.size ? unsafeStatic(this.size) : literal`h4`;

        /* eslint-disable lit/binding-positions, lit/no-invalid-html */
        return html`
            <${tag}
                class=${classMap(headingClass)}
                style=${styleMap(this.getTextStyles())}
                data-atlas-tooltip="text-tooltip"
            >
                ${this.renderContentSlot()}
            </${tag}>
            ${this.renderEllipsisTooltip()}
        `;
        /* eslint-enable lit/binding-positions, lit/no-invalid-html */
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-heading": AtlasHeading;
    }
}
