import { customElement, property } from "lit/decorators.js";

import { Watch } from "@/decorators/watch";
import { getMaskInputMode, getMaskPlaceholder, MaskAliasType } from "@/internals/mask-config";
import MaskValidator from "@/internals/validators/mask-validator";
import Inputmask from "@/vendors/inputmask-utils";

import AtlasInput, { InputProps } from "@/components/form/atlas-input/atlas-input";

export type MaskedInputProps = InputProps & {
    "mask-alias": MaskAliasType;
    "invalid-mask-error-message": string;
};

/**
 * @extends atlas-input
 *
 * @prop {MaskAliasType} mask-alias - Alias da máscara que será usada no input
 *
 * @tag atlas-masked-input
 */
@customElement("atlas-masked-input")
export default class AtlasMaskedInput extends AtlasInput {
    @property({ type: String, attribute: "mask-alias" }) maskAlias: MaskAliasType;

    @property({ type: String, attribute: "invalid-mask-error-message" }) invalidMaskErrorMessage: string;

    private _maskInstance: Inputmask.Instance;

    private _configPlaceholder = "";

    connectedCallback() {
        super.connectedCallback?.();
        this._configPlaceholder = this.placeholder;

        this.updateComplete.then(() => {
            this.buildMask();
        });
    }

    buildMask() {
        this.placeholder = this._configPlaceholder || getMaskPlaceholder(this.maskAlias);
        this.inputMode = getMaskInputMode(this.maskAlias);
        this._maskInstance = Inputmask({ alias: this.maskAlias }).mask(this._input);

        // @ts-expect-error
        this._maskInstance.shadowRoot = this.shadowRoot;

        const maskValidator = this.getValidator("mask") as MaskValidator;

        if (!maskValidator) {
            this.addValidator(new MaskValidator(this.maskAlias, this.invalidMaskErrorMessage));
        } else {
            maskValidator.type = this.maskAlias;
            maskValidator.customErrorMessage = this.invalidMaskErrorMessage;
        }
    }

    onChangeValue() {
        if (!Inputmask.isValid(this.value, { alias: this.maskAlias })) {
            this.value = Inputmask.format(this.value, { alias: this.maskAlias });
        }

        super.onChangeValue();
    }

    @Watch("maskAlias", true)
    onChangeMask() {
        this.value = "";
        this.buildMask();
    }

    getUnmaskedValue() {
        return this._maskInstance.unmaskedvalue();
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-masked-input": AtlasMaskedInput;
    }
}
