import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import { WithTooltipMixin } from "@/internals/mixins/with-tooltip-mixin";

import { Theme } from "@/internals/theme";

import styles from "./atlas-bar-chart-item.scss";

export type BarChartItemProps = {
    text: string;
};

/**
 * @prop {Theme} theme - Define o tema do item
 * @prop {string} variation - Define a variação de cor do item
 * @prop {number} width - Define o tamanho do item
 *
 * @tag atlas-bar-chart-item
 */
@customElement("atlas-bar-chart-item")
export default class AtlasBarChartItem extends WithTooltipMixin(LitElement) {
    static styles = styles;

    @property({ type: String }) theme: Theme;

    @property({ type: String }) variation: string;

    @property({ type: String }) width: number = 100;

    render() {
        const barChartClass = {
            "bar-chart": true,
            [`bar-chart-${this.theme}`]: !!this.theme,
            [`bar-chart-${this.variation}`]: !!this.variation
        };

        return html`
            <div
                class="${classMap(barChartClass)}"
                data-atlas-tooltip="bar-chart-tooltip"
                style="width: ${this.width}%"
            ></div>
            ${this.renderTooltip("bar-chart-tooltip")}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-bar-chart-item": AtlasBarChartItem;
    }
}
