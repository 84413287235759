import { HTMLTemplateResult, LitElement, TemplateResult, html } from "lit";
import { property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";

export type AtlasElementProps = {
    "skeleton-loading": boolean;
    "hide-on-desktop": boolean;
    "hide-on-mobile": boolean;
};

/**
 * @prop {boolean} skeletonLoading - Indica se o componente está carregando
 * @prop {boolean} hideOnDesktop - Indica se o componente deve ser escondido no desktop
 * @prop {boolean} hideOnMobile - Indica se o componente deve ser escondido no mobile
 */
export default class AtlasElement extends LitElement {
    @property({ type: Boolean, attribute: "hide-on-desktop", reflect: true }) hideOnDesktop: boolean;

    @property({ type: Boolean, attribute: "hide-on-mobile", reflect: true }) hideOnMobile: boolean;

    @property({ type: Boolean, attribute: "skeleton-loading", reflect: true }) skeletonLoading: boolean;

    update(changedProperties: Map<string, any>) {
        super.update(changedProperties);

        if (this.hasUpdated) {
            this.updateComplete.then(() => {
                emit(this, "atlas-element-update", { detail: changedProperties });
            });
        }
    }

    @Watch("skeletonLoading")
    async applySkeleton() {
        await this.updateComplete;

        const allElements = this.shadowRoot.querySelectorAll("*");

        allElements.forEach((element: HTMLElement) => {
            if (element.tagName === "SLOT") {
                this.setLoadingSlot(element as HTMLSlotElement);
            } else {
                element.toggleAttribute("skeleton-loading", this.skeletonLoading);
            }
        });

        emit(this, "atlas-element-change-skeleton");
    }

    setLoadingSlot(slot: HTMLSlotElement) {
        const elements = slot.assignedElements();

        elements.forEach((element: HTMLElement) => {
            element.toggleAttribute("skeleton-loading", this.skeletonLoading);
        });
    }

    renderSkeleton(): HTMLTemplateResult | TemplateResult | Generator<unknown, void, unknown> {
        return html``;
    }

    renderElement(): HTMLTemplateResult | TemplateResult | Generator<unknown, void, unknown> {
        return html``;
    }

    render() {
        return when(
            this.skeletonLoading,
            () => this.renderSkeleton(),
            () => this.renderElement()
        );
    }
}
