import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import { showAlert } from "@/helpers/notifications";
import { get } from "@/helpers/request";
import { emit } from "@/internals/events";
import { TableColumn } from "./types";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-easy-table.scss";
import "@/components/display/atlas-empty-state/atlas-empty-state";
import "@/components/table/atlas-table/atlas-table";
import "@/components/table/atlas-table-header/atlas-table-header";
import "@/components/table/atlas-table-body/atlas-table-body";
import "@/components/table/atlas-table-footer/atlas-table-footer";
import "@/components/table/atlas-table-col/atlas-table-col";

export type EasyTableProps = AtlasElementProps & {
    "selectable": boolean;
    "has-actions": boolean;
    "hide-footer": boolean;
    "search-on-render": boolean;
    "url": string;
    "params": object;
    "items-per-page": number;
    "current-page": number;
    "total-records": number;
    "footer-text": string;
    "columns": TableColumn[];
    "empty-state-icon": string;
    "empty-state-header": string;
    "empty-state-description": string;
};

/**
 * @dependency atlas-empty-state
 * @dependency atlas-table
 * @dependency atlas-table-header
 * @dependency atlas-table-body
 * @dependency atlas-table-footer
 * @dependency atlas-table-col
 *
 * @prop {boolean} selectable - Indica se a table permite múltipla seleção
 * @prop {boolean} has-actions - Indica se a table tem ações
 * @prop {boolean} hide-footer - Indica se o rodapé com a paginação deve ser escondido
 * @prop {boolean} search-on-render - Indica se a pesquisa deve ser feita já ao renderizar a table
 * @prop {string} url - URL do endpoint que irá buscar as linhas da table
 * @prop {object} params - Parâmetros que serão enviados para o backend
 * @prop {number} items-per-page - Número de itens que serão carregados por página
 * @prop {number} current-page - Página atual da listagem
 * @prop {number} total-records - Total de registros que a listagem possui (incluindo todas as páginas)
 * @prop {string} footer-text - Texto que aparece no rodapé, no lugar da informação do número de registros da página
 * @prop {TableColumn[]} columns - Array com as configurações das colunas, os atributos `name` e `label` são requeridos para todas as colunas, os atributos `size`, `ellipsis` e `sortable` são opcionais
 * @prop {string} empty-state-icon - Ícone do empty-state que é exibido quando a listagem está vazia
 * @prop {string} empty-state-header - Título do empty-state que é exibido quando a listagem está vazia
 * @prop {string} empty-state-description - Descrição do empty-state que é exibido quando a listagem está vazia
 *
 * @event {CustomEvent} atlas-table-before-search - Evento disparado logo antes de uma pesquisa ser feita
 * @event {CustomEvent} atlas-table-after-search - Evento disparado logo após uma pesquisa ser feita
 *
 * @slot "" - No slot padrão é possível passar as linhas do primeiro carregamento, para que seja feito de forma síncrona, sem bater novamente no backend
 * @slot empty-state-template - Nesse slot é possível informar o template do empty-state, permitindo enviar um empty-state mais customizável
 *
 * @tag atlas-easy-table
 */
@customElement("atlas-easy-table")
export default class AtlasEasyTable extends AtlasElement {
    static styles = styles;

    @property({ type: Boolean }) selectable: boolean;

    @property({ type: Boolean, attribute: "has-actions" }) hasActions: boolean;

    @property({ type: Boolean, attribute: "hide-footer" }) hideFooter: boolean;

    @property({ type: Boolean, attribute: "search-on-render" }) searchOnRender: boolean;

    @property({ type: String }) url: string;

    @property({ type: Object }) params: { [key: string]: any } = {};

    @property({ type: Number, attribute: "items-per-page" }) itemsPerPage = 10;

    @property({ type: Number, attribute: "current-page", reflect: true }) currentPage = 1;

    @property({ type: Number, attribute: "total-records", reflect: true }) totalRecords = 0;

    @property({ type: String, attribute: "footer-text", reflect: true }) footerText: string;

    @property({ type: Array }) columns: TableColumn[] = [];

    @property({ type: String, attribute: "empty-state-icon" }) emptyStateIcon: string;

    @property({ type: String, attribute: "empty-state-header" }) emptyStateHeader: string;

    @property({ type: String, attribute: "empty-state-description" }) emptyStateDescription: string;

    connectedCallback(): void {
        super.connectedCallback?.();

        this.onPageChange = this.onPageChange.bind(this);
        this.addEventListener("atlas-table-page-change", this.onPageChange);

        if (this.searchOnRender) {
            this.fetchRecords();
        }
    }

    disconnectedCallback(): void {
        this.removeEventListener("atlas-table-page-change", this.onPageChange);
    }

    async fetchRecords(shouldGoToFirstPage?: boolean) {
        await this.updateComplete;

        this.skeletonLoading = true;
        emit(this, "atlas-table-before-search");

        const params = {
            ...this.params,
            page: shouldGoToFirstPage ? 1 : this.currentPage,
            itemsPerPage: this.itemsPerPage
        };

        get(this.url, params).then((response) => {
            if (!response.success) {
                showAlert(response.message, "ERROR");
                return;
            }

            const defaultSlot = this.shadowRoot.querySelector("slot:not([name])") as HTMLSlotElement;
            defaultSlot.assignedElements({ flatten: true }).forEach((element) => element.remove());

            this.innerHTML += response.content;
            this.totalRecords = response.totalRecords;

            if (shouldGoToFirstPage) {
                this.currentPage = 1;
            }

            setTimeout(() => {
                this.skeletonLoading = false;

                emit(this, "atlas-table-after-search", {
                    detail: { response }
                });
            }, 1000);
        });
    }

    onPageChange(event: CustomEvent) {
        const { page } = event.detail;

        this.currentPage = page;
        this.fetchRecords();
    }

    renderHeaderContent() {
        return this.columns.map(
            (column) => html`
                <atlas-table-col
                    name=${column.name}
                    size=${column.size || "md"}
                    ?sortable=${column.sortable}
                    ?ellipsis=${column.ellipsis}
                >
                    ${column.label}
                </atlas-table-col>
            `
        );
    }

    renderTable() {
        return html`
            <atlas-table ?selectable=${this.selectable} ?has-actions=${this.hasActions}>
                <atlas-table-header slot="header">${this.renderHeaderContent()}</atlas-table-header>
                <atlas-table-body slot="body">
                    <slot></slot>
                </atlas-table-body>
                <atlas-table-footer
                    slot="footer"
                    items-per-page=${this.itemsPerPage}
                    current-page=${this.currentPage}
                    total-items=${this.totalRecords}
                    footer-text=${this.footerText}
                    ?hidden=${this.hideFooter}
                ></atlas-table-footer>
            </atlas-table>
        `;
    }

    renderEmptyState() {
        return when(
            !this.emptyStateIcon || !this.emptyStateHeader || !this.emptyStateDescription,
            () => html`<slot name="empty-state-template"></slot>`,
            () => html`
                <atlas-empty-state
                    icon=${this.emptyStateIcon}
                    header=${this.emptyStateHeader}
                    description=${this.emptyStateDescription}
                ></atlas-empty-state>
            `
        );
    }

    render() {
        return html`
            <div class="easy-table">
                ${when(
                    this.totalRecords > 0 || this.skeletonLoading,
                    () => this.renderTable(),
                    () => this.renderEmptyState()
                )}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-easy-table": AtlasEasyTable;
    }
}
