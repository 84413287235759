import { customElement, property } from "lit/decorators.js";

import Inputmask from "@/vendors/inputmask-utils";
import AtlasInput, { InputProps } from "@/components/form/atlas-input/atlas-input";

import RangeValidator from "@/internals/validators/range-validator";

export type FloatInputProps = InputProps & {
    "decimal-precision": number;
    "max-value": number;
    "max-value-error-message": string;
    "min-value": number;
    "min-value-error-message": string;
    "allow-negative": boolean;
};

/**
 * @extends atlas-input
 *
 * @prop {number} decimal-precision - Precisão de casas decimais do input
 * @prop {number} max-value - Valor máximo que o input pode receber
 * @prop {string} max-value-error-message - Mensagem exibida no input caso o valor seja maior que o valor máximo
 * @prop {number} min-value - Valor mínimo que o input pode receber
 * @prop {string} min-value-error-message - Mensagem exibida no input caso o valor seja menor que o valor máximo
 * @prop {boolean} allow-negative - Indica se o input permite valores negativos (true) ou não (false)
 *
 * @tag atlas-float-input
 */
@customElement("atlas-float-input")
export default class AtlasFloatInput extends AtlasInput {
    @property({ type: Number, attribute: "decimal-precision" }) decimalPrecision = 2;

    @property({ type: Number, attribute: "max-value" }) maxValue: number;

    @property({ type: String, attribute: "max-value-error-message" }) maxValueErrorMessage: string;

    @property({ type: Number, attribute: "min-value" }) minValue: number;

    @property({ type: String, attribute: "min-value-error-message" }) minValueErrorMessage: string;

    @property({ type: Boolean, attribute: "allow-negative" }) allowNegative = false;

    private maskInstance: Inputmask.Instance;

    connectedCallback() {
        super.connectedCallback?.();

        this.fixCarretPosition = this.fixCarretPosition.bind(this);

        this.addValidator(new RangeValidator(this.maxValueErrorMessage, this.minValueErrorMessage));
        this.placeholder = this.decimalPrecision <= 0 ? "0" : `0,${"0".repeat(this.decimalPrecision)}`;
        this.inputMode = "decimal";

        this.updateComplete.then(() => {
            this.maskInstance = Inputmask({
                alias: "float",
                allowMinus: this.allowNegative,
                digits: this.decimalPrecision,
                onKeyDown: (event) => {
                    const arrowKeys = ["ArrowLeft", "ArrowUp", "ArrowDown", "ArrowRight"];

                    if (arrowKeys.includes(event.key)) {
                        event.preventDefault();
                    }
                }
            }).mask(this._input);

            this._input.addEventListener("click", this.fixCarretPosition);
        });
    }

    focus() {
        this._input.focus();
    }

    handleFocus() {
        super.handleFocus();
        this.fixCarretPosition();
    }

    getUnmaskedValue() {
        return this.maskInstance.unmaskedvalue();
    }

    fixCarretPosition() {
        setTimeout(() => {
            const length = this.value ? this.value.length : "0,00".length;

            if (this._input.setSelectionRange) {
                this._input.setSelectionRange(length, length);
            }
        }, 0);
    }

    onChangeValue() {
        if (Inputmask.isValid(this.value, { alias: "float", digits: this.decimalPrecision })) {
            super.onChangeValue();
            return;
        }

        if (String(this.value).includes(".")) {
            this.value = String(this.value).replace(".", ",");
        }

        this.value = Inputmask.format(this.value, { alias: "float", digits: this.decimalPrecision });

        super.onChangeValue();
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-float-input": AtlasFloatInput;
    }
}
