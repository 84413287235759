import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { emit } from "@/internals/events";

import styles from "./atlas-tag.scss";
import "@/components/display/atlas-icon/atlas-icon";

export type TagProps = {
    text: string;
    disabled: boolean;
};

/**
 * @dependecy atlas-icon
 *
 * @prop {string} text - Texto que aparece na tag
 * @prop {boolean} disabled - Indica se a tag está desabilitada
 *
 * @event {CustomEvent} atlas-tag-click - Evento disparado ao clicar sobre a tag
 * @event {CustomEvent} atlas-tag-close-click - Evento disparado ao clicar no fechar da tag
 *
 * @tag atlas-tag
 */
@customElement("atlas-tag")
export default class AtlasTag extends LitElement {
    static styles = styles;

    @property({ type: String }) text: string;

    @property({ type: Boolean }) disabled: boolean;

    onClickTag() {
        emit(this, "atlas-tag-click");
    }

    onClickClose(event: MouseEvent) {
        event.stopPropagation();
        emit(this, "atlas-tag-close-click");
    }

    render() {
        return html`
            <button class="atlas-tag" @click=${this.onClickTag} ?disabled=${this.disabled}>
                <span>${this.text}</span>
                <atlas-icon name="x" size="2x" @click=${this.onClickClose}></atlas-icon>
            </button>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-tag": AtlasTag;
    }
}
