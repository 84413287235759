import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import DeviceController from "@/controllers/device-controller";
import { emit } from "@/internals/events";
import { WithCollapseMixin } from "@/internals/mixins/with-collapse-mixin";
import { Theme } from "@/internals/theme";

import AtlasElement from "@/components/atlas-element";
import type { AtlasElementProps } from "@/components/atlas-element";
import type AtlasCarousel from "@/components/layout/atlas-carousel/atlas-carousel";

import styles from "./atlas-panel.scss";
import "@/components/display/atlas-badge/atlas-badge";
import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-text/atlas-text";
import "@/components/layout/atlas-collapse/atlas-collapse";
import "@/components/layout/atlas-carousel/atlas-carousel";
import "@/components/layout/atlas-layout/atlas-layout";

export type PanelProps = AtlasElementProps & {
    "header": string;
    "description": string;
    "collapsible": boolean;
    "carousel": boolean;
    "items-per-page": number;
};

/**
 * @prop {string} header - Título do painel
 * @prop {string} description - Descrição do painel
 * @prop {boolean} collapsible - Indica se o painel vai ser expansível/colapsável
 * @prop {boolean} carousel - Indica se o painel vai ser exibido como um carosel
 * @prop {number} items-per-page - Número de itens que vão aparecer em uma página do carrossel
 *
 * @tag atlas-panel
 */
@customElement("atlas-panel")
export default class AtlasPanel extends WithCollapseMixin(AtlasElement) {
    static styles = styles;

    @property({ type: String }) header: string;

    @property({ type: String }) description: string;

    @property({ type: String, attribute: "badge-type" }) badgeType: "filled" | "outlined";

    @property({ type: String, attribute: "badge-text" }) badgeText: string;

    @property({ type: String, attribute: "badge-icon" }) badgeIcon: string;

    @property({ type: String, attribute: "badge-theme" }) badgeTheme: Theme;

    @property({ type: Boolean }) carousel: boolean;

    @property({ type: Number, attribute: "items-per-page" }) itemsPerPage: number = 1;

    @state() private _hasSlottedActions: boolean = false;

    public carouselElement: AtlasCarousel;

    private _deviceController: DeviceController = new DeviceController(this);

    connectedCallback() {
        super.connectedCallback?.();

        this.emitPanelEvent = this.emitPanelEvent.bind(this);

        if (this.collapsible) {
            this.collapsed = true;
        }

        this.updateComplete.then(() => {
            this.addEventListener("atlas-collapse-button-click", this.emitPanelEvent);

            if (this.carousel) {
                this.carouselElement = this.shadowRoot.querySelector("atlas-carousel") as AtlasCarousel;
            }
        });
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-collapse-button-click", this.emitPanelEvent);
    }

    emitPanelEvent() {
        emit(this, `atlas-panel-${this.collapsed ? "collapse" : "expand"}`);
    }

    onActionsSlotChange() {
        const actionsSlot = this.shadowRoot.querySelector("slot[name=actions]") as HTMLSlotElement;

        this._hasSlottedActions = actionsSlot.assignedElements().length > 0;
    }

    isHeaderVisible() {
        return this.header || (this.collapsible && !this._deviceController.isMobile) || this._hasSlottedActions;
    }

    renderContent() {
        if (this.carousel) {
            return html`
                <atlas-carousel items-per-page=${this.itemsPerPage}>
                    <slot></slot>
                </atlas-carousel>
            `;
        }

        return html` <slot></slot> `;
    }

    renderHeaderBadge() {
        return when(
            !!this.badgeText,
            () => html`
                <atlas-badge
                    .type=${this.badgeType}
                    .text=${this.badgeText}
                    .icon=${this.badgeIcon}
                    .theme=${this.badgeTheme}
                ></atlas-badge>
            `
        );
    }

    render() {
        const panelClass = {
            "panel": true,
            "collapsible": this.collapsible,
            "hide-header": !this.isHeaderVisible()
        };

        const panelBodyClass = {
            "panel-body": true,
            "collapsed": this.collapsible && this.collapsed
        };

        return html`
            <div class=${classMap(panelClass)}>
                <div class="panel-header">
                    <div class="panel-title">
                        <atlas-layout gap="4" alignment="center" inline>
                            <atlas-heading size="h5">${this.header}</atlas-heading>
                            ${this.renderHeaderBadge()}
                        </atlas-layout>
                        ${when(!!this.description, () => html`<atlas-text muted>${this.description}</atlas-text>`)}
                    </div>
                    <atlas-toolbar only-actions>
                        <slot name="actions" slot="actions" @slotchange=${this.onActionsSlotChange}></slot>
                    </atlas-toolbar>
                    ${when(this.collapsible, () => this.renderCollapseButton())}
                </div>
                ${this.renderContentWithCollapse(
                    html` <div class="${classMap(panelBodyClass)}">${this.renderContent()}</div>`
                )}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-panel": AtlasPanel;
    }
}
