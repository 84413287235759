import { LitElement, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { classMap } from "lit/directives/class-map.js";

import { emit } from "@/internals/events";

import { AlertType, AlertTypeInfo } from "./types";
import alertStyles from "./atlas-alert.scss";

import "@/components/display/atlas-icon/atlas-icon";

export type AlertProps = {
    "message": string;
    "type": AlertType;
    "atlas-alert-show": Function;
    "atlas-alert-close": Function;
};

/**
 * Alerts são mensagens que avisam que uma ação foi executada ou dão feedback sobre a ação que o usuário executou.
 *
 * @prop {string} message - A mensagem exibida no alert
 * @prop {AlertType} type - O tipo de alert
 *
 * @event {CustomEvent} atlas-alert-show - Evento disparado quando o alert é exibido
 * @event {CustomEvent} atlas-alert-close - Evento disparado quando o alert é fechado
 *
 * @tag atlas-alert
 */
@customElement("atlas-alert")
export default class AtlasAlert extends LitElement {
    static styles = alertStyles;

    @property({ type: String }) message: string = "";

    @property({ type: String }) type: AlertType = "info";

    @state() private _visible = false;

    constructor() {
        super();

        this.show = this.show.bind(this);
        this.close = this.close.bind(this);
        this.destroy = this.destroy.bind(this);
    }

    firstUpdated() {
        setTimeout(this.show, 100);
    }

    show() {
        this._visible = true;
        emit(this, "atlas-alert-show");
    }

    close() {
        this._visible = false;
        emit(this, "atlas-alert-close");
    }

    destroy() {
        this.close();

        setTimeout(() => {
            this.remove();
        }, 150);
    }

    getTypeInfo(): AlertTypeInfo {
        return {
            success: { className: "alert-success", wrapperClass: "js-alert-success", icon: "check" },
            error: { className: "alert-error", wrapperClass: "js-alert-error", icon: "alert-triangle" },
            warning: { className: "alert-warning", wrapperClass: "js-alert-warning", icon: "alert-triangle" },
            info: { className: "alert-info", wrapperClass: "js-alert-info", icon: "info" }
        }[this.type] as AlertTypeInfo;
    }

    renderCloseButton() {
        return when(
            this.type !== "success",
            () => html`
                <button type="button" class="alert-close close" aria-label="Fechar" @click=${this.destroy}>
                    <atlas-icon name="x" size="3x"></atlas-icon>
                </button>
            `
        );
    }

    render() {
        const typeInfo = this.getTypeInfo();

        const alertClass = {
            "alert": true,
            "alert-dismissible": true,
            "fade": true,
            "show": this._visible,
            [typeInfo.className]: true
        };

        return html`
            <div role="alert" class=${classMap(alertClass)}>
                <atlas-icon name="${typeInfo.icon}" size="3x"></atlas-icon>
                <span class="alert-text">${this.message}</span>
                ${this.renderCloseButton()}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-alert": AtlasAlert;
    }
}
