import { LitElement } from "lit";
import { html, literal } from "lit/static-html.js";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import { emit } from "@/internals/events";
import { Theme } from "@/internals/theme";
import { WithTooltipMixin, WithTooltipProps } from "@/internals/mixins/with-tooltip-mixin";

import DeviceController from "@/controllers/device-controller";

import styles from "./atlas-badge.scss";

import "@/components/display/atlas-icon/atlas-icon";

export type BadgeProps = WithTooltipProps & {
    "type": "filled" | "outlined";
    "text": string;
    "icon": string;
    "theme": Theme;
    "is-counter": boolean;
    "show-zero": boolean;
};

/**
 * @dependency atlas-icon
 *
 * @prop {"filled" | "outlined"} type - Tipo do tema do badge
 * @prop {string} text - Texto de conteúdo do badge
 * @prop {string} icon - Nome do ícone de apoio visual do badge
 * @prop {Theme} theme - Tema do badge
 * @prop {boolean} is-counter - Indica se é um badge de contador
 * @prop {boolean} show-zero - Indica se o badge deve mostrar o valor 0 quando ele for um contador
 *
 * @event {CustomEvent} atlas-badge-click - Evento disparado quando é feito o clique no badge
 *
 * @tag atlas-badge
 */
@customElement("atlas-badge")
export default class AtlasBadge extends WithTooltipMixin(LitElement) {
    static styles = styles;

    @property({ type: String }) type: "filled" | "outlined" = "filled";

    @property({ type: String }) text: string;

    @property({ type: String }) icon: string;

    @property({ type: String }) theme: Theme = "danger";

    @property({ type: Boolean, attribute: "is-counter" }) isCounter = false;

    @property({ type: Boolean, attribute: "show-zero" }) showZero = false;

    private deviceController = new DeviceController(this);

    onClickBadge() {
        emit(this, "atlas-badge-click");
    }

    getTextContent(): string {
        if (this.isCounter) {
            let number = parseInt(this.text, 10);

            if (Number.isNaN(number)) {
                number = 0;
            }

            if (!this.showZero && number === 0) {
                return "";
            }

            return number > 9 ? "9+" : `${number}`;
        }

        return this.text;
    }

    renderIcon() {
        return when(
            !!this.icon && !this.isCounter,
            () => html`<atlas-icon size="2x" name="${this.icon}"></atlas-icon>`
        );
    }

    render() {
        const textContent = this.getTextContent();
        const badgeClass = {
            "badge": true,
            "small": this.deviceController.isMobile && (this.isCounter || !textContent),
            "counter": this.isCounter,
            "with-text": !!textContent,
            "with-tooltip": !!this.tooltip,
            [`badge-${this.type}-${this.theme}`]: !!this.theme
        };

        const tag = this.tooltip ? literal`button` : literal`span`;

        /* eslint-disable lit/binding-positions, lit/no-invalid-html */
        return html`
            <${tag}
                class=${classMap(badgeClass)}
                data-atlas-tooltip="badge-tooltip"
                @click=${ifDefined(this.tooltip ? this.onClickBadge : undefined)}
            >
                ${this.renderIcon()}
                <span class="content">${textContent}</span>
            </${tag}>
            ${this.renderTooltip("badge-tooltip")}
        `;
        /* eslint-enable lit/binding-positions, lit/no-invalid-html */
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-badge": AtlasBadge;
    }
}
