import { LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";

import styles from "./atlas-divider.scss";

/**
 * Divider serve para separar conteúdos ou agrupar elementos
 *
 * @prop {boolean} vertical - Booleano que indica se o divider aparece na vertical
 * @prop {number} spacing - O espaçamento entre os conteúdos
 * @prop {string} color - Uma cor alternativa para o divider
 * @prop {number} width - A largura do divider
 *
 */
@customElement("atlas-divider")
export default class AtlasDivider extends LitElement {
    static styles = styles;

    @property({ type: Boolean }) vertical = false;

    @property({ type: Number }) spacing = 16;

    @property({ type: String }) color: string;

    @property({ type: Number }) width: number;

    updated() {
        const width = this.width > 1 ? this.width : 1;
        const spacing = !this.spacing ? 16 : this.spacing;

        this.style.borderTopColor = !this.vertical && this.color ? this.color : "";
        this.style.borderLeftColor = this.vertical && this.color ? this.color : "";

        this.style.borderTopWidth = !this.vertical && this.width ? `${width}px` : "";
        this.style.borderLeftWidth = this.vertical && this.width ? `${width}px` : "";

        this.style.margin = this.vertical ? `0 ${spacing}px` : `${spacing}px 0`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-divider": AtlasDivider;
    }
}
