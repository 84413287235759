import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { TableSort } from "@/components/table/atlas-table/types";
import { emit } from "@/internals/events";
import DeviceController from "@/controllers/device-controller";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-table-col.scss";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-text/atlas-text";
import "@/components/display/atlas-tooltip/atlas-tooltip";

export type TableColProps = AtlasElementProps & {
    "name": string;
    "size": "sm" | "md" | "lg";
    "ellipsis": boolean;
    "sortable": boolean;
    "is-header-column": boolean;
    "is-selection-column": boolean;
    "is-action-column": boolean;
    "column-label": string;
};

/**
 * @dependency atlas-icon
 * @dependency atlas-text
 *
 * @prop {string} name - Nome da coluna (Usado para ordenação)
 * @prop {"sm" | "md" | "lg"} size - Tamanho da coluna, serve também para definir qual será o tamanho mínimo que a coluna irá possuir (Se passado no cabeçalho as colunas das linhas da table também herdam essa propriedade)
 * @prop {boolean} ellipsis - Indica se a coluna deve possuir ellipsis quando o texto exceder o tamanho da coluna (Se passado no cabeçalho as colunas das linhas da table também herdam essa propriedade)
 * @prop {boolean} sortable - Indica se a coluna possui ordenação (Apenas para o cabeçalho)
 * @prop {boolean} is-header-column - Indica se a coluna está no cabeçalho **(É informado automáticamente)**
 * @prop {boolean} is-selection-column - Indica se a coluna é de seleção de registros **(É informado automaticamente)**
 * @prop {boolean} is-action-column - Indica se a coluna é de ações **(É informado automaticamente)**
 * @prop {string} column-label - Label da coluna **(É informado automaticamente, usando o texto da coluna de cabeçalho)**
 *
 * @tag atlas-table-col
 */
@customElement("atlas-table-col")
export default class AtlasTableCol extends AtlasElement {
    static styles = styles;

    @property({ type: String }) name: string;

    @property({ type: String, reflect: true }) size: "sm" | "md" | "lg";

    @property({ type: Boolean }) ellipsis: boolean;

    @property({ type: Boolean }) sortable: boolean;

    @property({ type: Boolean, attribute: "is-header-column", reflect: true }) isHeaderColumn: boolean;

    @property({ type: Boolean, attribute: "is-selection-column", reflect: true }) isSelectionColumn: boolean;

    @property({ type: Boolean, attribute: "is-action-column", reflect: true }) isActionColumn: boolean;

    @property({ type: String, attribute: "column-label" }) columnLabel: string;

    @state() private _sort: TableSort = "none";

    private _deviceController = new DeviceController(this);

    getSort() {
        return this._sort;
    }

    setSort(sort: TableSort) {
        this._sort = sort;
    }

    onClickCol(event: PointerEvent) {
        event.preventDefault();
        emit(this, "atlas-table-col-click");
    }

    getSortingIcon(sort: TableSort) {
        switch (sort) {
            case "ascending":
                return "chevron-up";
            case "descending":
                return "chevron-down";
            default:
                return "sort";
        }
    }

    renderColumnLabel() {
        return when(
            this._deviceController.isMobile && this.columnLabel,
            () => html` <span class="column-label"> ${this.columnLabel} </span> `
        );
    }

    renderContent() {
        return when(
            this.isHeaderColumn && !this.isActionColumn && !this.isSelectionColumn,
            () => html`
                <atlas-text size="xsm" muted>
                    <slot></slot>
                </atlas-text>
                <atlas-icon name=${this.getSortingIcon(this._sort)} size="2x" theme="secondary"></atlas-icon>
            `,
            () => html`
                <atlas-text class="column-content" ?ellipsis=${this.ellipsis && !this._deviceController.isMobile}>
                    <slot></slot>
                </atlas-text>
            `
        );
    }

    renderElement() {
        const columnClass = {
            "atlas-table-column": true,
            "header-column": this.isHeaderColumn,
            "is-selection": this.isSelectionColumn,
            "is-actions": this.isActionColumn,
            "sortable": this.sortable,
            "ellipsis": this.ellipsis,
            [`size-${this.size}`]: !!this.size && this.isHeaderColumn && !this.isSelectionColumn && !this.isActionColumn
        };

        return when(
            this.isHeaderColumn || this.isSelectionColumn || this.isActionColumn,
            () => html`
                <button
                    class=${classMap(columnClass)}
                    @click=${this.onClickCol}
                    tabindex=${!this.sortable ? "-1" : "1"}
                >
                    ${this.renderContent()}
                </button>
            `,
            () => html` <div class=${classMap(columnClass)}>${this.renderColumnLabel()}${this.renderContent()}</div> `
        );
    }

    renderSkeleton() {
        return html`
            <div class="atlas-table-column">
                <div class="column-content skeleton"></div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-table-col": AtlasTableCol;
    }
}
