import { removeNonNumeric } from "@/helpers/formatters";
import { buildPathToApi, MAPPED_ENDPOINTS } from "./api-helpers";
import { get } from "@/helpers/request";

export function getPostalCodeData(postalCode: string) {
    const postalCodeValue = removeNonNumeric(postalCode);
    const apiPath = buildPathToApi(MAPPED_ENDPOINTS.postalCodeValidate);

    return get(apiPath, { postalCode: postalCodeValue });
}
