import { LitElement, html, HTMLTemplateResult } from "lit";
import { property } from "lit/decorators.js";

import { Constructor } from "@/internals/basic-types";

import { emit } from "@/internals/events";

import "@/components/display/atlas-collapse-button/atlas-collapse-button";
import "@/components/layout/atlas-collapse/atlas-collapse";

export type WithCollapseProps = {
    collapsible: boolean;
    collapsed: boolean;
};

export declare class WithCollapseInterface {
    collapsible: boolean;

    collapsed: boolean;

    toggleCollapse(event: CustomEvent): void;

    renderContentWithCollapse(template: HTMLTemplateResult): HTMLTemplateResult;

    renderCollapseButton(theme?: string, iconColor?: string): HTMLTemplateResult;
}

export const WithCollapseMixin = <T extends Constructor<LitElement>>(superClass: T) => {
    /**
     * @dependency atlas-collapse
     * @dependency atlas-collapse-button
     *
     * @prop {boolean} collapsible - Indica se o elemento é colapsável
     * @prop {boolean} collapsed - Indica se o elemento está colapsado
     */
    class WithCollapseClass extends superClass {
        @property({ type: Boolean }) collapsible: boolean;

        @property({ type: Boolean, reflect: true }) collapsed: boolean = false;

        collapse() {
            if (!this.collapsible) return;

            this.collapsed = true;
        }

        expand() {
            if (!this.collapsible) return;

            this.collapsed = false;
        }

        toggleCollapse(event: CustomEvent): void {
            if (!this.collapsible) return;

            event.stopPropagation();

            this.collapsed = !this.collapsed;

            emit(this, "atlas-collapse-button-click", {
                detail: {
                    collapsed: this.collapsed
                }
            });
        }

        renderContentWithCollapse(template: HTMLTemplateResult): HTMLTemplateResult {
            return html`
                <atlas-collapse ?show=${this.collapsible ? !this.collapsed : true}> ${template} </atlas-collapse>
            `;
        }

        renderCollapseButton(theme?: string, iconColor?: string): HTMLTemplateResult {
            return html`
                <atlas-collapse-button
                    icon-color=${iconColor || "secondary"}
                    theme=${theme || "primary"}
                    @click=${this.toggleCollapse}
                    ?collapsed=${this.collapsed}
                ></atlas-collapse-button>
            `;
        }
    }

    return WithCollapseClass as Constructor<WithCollapseInterface> & T;
};
