import Inputmask from "inputmask";
import { MaskAliasEnum, MaskAliasType, MASK_MAPPING } from "@/internals/mask-config";

const maskConfigMap = Object.values(MaskAliasEnum).reduce(
    (previous, current: MaskAliasType) => ({
        ...previous,
        [current]: MASK_MAPPING[current].maskConfig
    }),
    {}
);

Inputmask.extendAliases({
    float: {
        alias: "currency",
        groupSeparator: ".",
        radixPoint: ",",
        rightAlign: false,
        digits: 2,
        digitsOptional: true,
        numericInput: true,
        unmaskAsNumber: true
    },
    integer: {
        groupSeparator: ".",
        radixPoint: ",",
        rightAlign: false,
        unmaskAsNumber: true,
        shortcuts: null,
        showMaskOnHover: false,
        jitMasking: true
    },
    datepicker: {
        alias: "datetime",
        inputFormat: "dd/mm/yyyy",
        showMaskOnHover: false,
        jitMasking: true
    },
    ...maskConfigMap
});

export default Inputmask;
