import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { styleMap } from "lit/directives/style-map.js";

import { DisplaySize } from "./types";

import styles from "./atlas-display.scss";
import TextElement, { TextElementProps } from "../text-element";

export type DisplayProps = TextElementProps & {
    size: DisplaySize;
};

/**
 * Componente de tipografia relacionado aos tipos display que existem
 *
 * @prop {string} size - O tamanho de display desejado, dentre os tamanhos possiveis
 *
 * @tag atlas-display
 */
@customElement("atlas-display")
export default class AtlasDisplay extends TextElement {
    static styles = styles;

    @property({ type: String }) size: DisplaySize = "display4";

    renderSkeleton() {
        const skeletonClass = {
            "atlas-display": true,
            "skeleton": true,
            [`${this.size}`]: true
        };

        return html`<div class="${classMap(skeletonClass)}"></div>`;
    }

    renderElement() {
        const displayClass = {
            "atlas-display": true,
            [this.size]: !!this.size,
            ...this.getTextClasses()
        };

        return html`
            <div
                class=${classMap(displayClass)}
                style=${styleMap(this.getTextStyles())}
                data-atlas-tooltip="text-tooltip"
            >
                ${this.renderContentSlot()}
            </div>
            ${this.renderEllipsisTooltip()}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-display": AtlasDisplay;
    }
}
