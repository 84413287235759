import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";

import AtlasWizardStep from "@/components/wizard/atlas-wizard-step/atlas-wizard-step";
import styles from "./atlas-stepper.scss";

import "@/components/display/atlas-icon/atlas-icon";

/**
 * @dependency atlas-icon
 *
 * @prop {string} current-step - Nome do passo que está ativo
 * @prop {number} current-step-index - Indice do passo que está ativo
 * @prop {number} disable-return - Indica se o voltar está desabilitado
 * @prop {AtlasWizardStep[]} steps - Passos que serão exibidos
 *
 * @tag atlas-stepper
 */
@customElement("atlas-stepper")
export default class AtlasStepper extends LitElement {
    static styles = styles;

    @property({ type: String, attribute: "current-step" }) currentStep: string;

    @property({ type: Number, attribute: "current-step-index" }) currentStepIndex: number = 0;

    @property({ type: Boolean, attribute: "disable-return" }) disableReturn: boolean = false;

    @property({ type: Array, attribute: null }) steps: AtlasWizardStep[] = [];

    getRealStepIndex(step: AtlasWizardStep) {
        return this.steps.findIndex((wizardStep) => wizardStep.name === step.name);
    }

    renderStep(step: AtlasWizardStep, index: number) {
        const isCompleted = this.getRealStepIndex(step) < this.currentStepIndex;
        const canInteract = isCompleted && !this.disableReturn;

        const stepClass = {
            "atlas-stepper-step": true,
            "active": step.name === this.currentStep,
            "completed": isCompleted,
            "return-disabled": this.disableReturn
        };

        return html`
            <div class=${classMap(stepClass)}>
                <button
                    class="atlas-stepper-circle"
                    @click=${canInteract ? step.goToStep : undefined}
                    tabindex=${ifDefined(!canInteract ? "-1" : undefined)}
                >
                    <span class="atlas-stepper-number">${index + 1}</span>
                    <atlas-icon name="check" size="3x"></atlas-icon>
                </button>
                <span class="atlas-stepper-title">${step.header}</span>
            </div>
            <div class="atlas-stepper-line"></div>
        `;
    }

    render() {
        const visibleSteps = this.steps.filter((step) => !step.disabled && !step.isOffStep());

        return html`
            <nav class="atlas-stepper-container container">
                <div class="atlas-stepper col-12 col-lg-10">
                    ${visibleSteps.map((step, index) => this.renderStep(step, index))}
                </div>
            </nav>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-stepper": AtlasStepper;
    }
}
