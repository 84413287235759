import { BaseStepController, CustomStepButton } from "@/components/wizard/atlas-wizard/types";

import AtlasWizardStep from "@/components/wizard/atlas-wizard-step/atlas-wizard-step";
import { getFormValues, reportFormValidity } from "@/helpers/form";

export default class BaseController {
    stepState: { [key: string]: any } = {};

    stepReference: AtlasWizardStep;

    customController: BaseStepController;

    elementToScroll: string;

    init(): void {
        this.stepState = getFormValues(this.stepReference);

        this.customController?.init?.();
    }

    onShowStep(): void {
        if (this.elementToScroll) {
            document.querySelector(this.elementToScroll).scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "start"
            });

            this.elementToScroll = null;
        } else {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }

        this.customController?.onShowStep?.();
    }

    async onSubmitStep(): Promise<boolean> {
        const formValues = getFormValues(this.stepReference);

        this.stepState = { ...this.stepState, ...formValues };

        if (this.customController?.onSubmitStep) {
            return new Promise((resolve) => {
                this.customController.onSubmitStep(resolve);
            });
        }

        return true;
    }

    async validate(): Promise<boolean> {
        if (!this.stepReference.disableValidations) {
            if (!reportFormValidity(this.stepReference)) return false;
        }

        if (this.customController && this.customController.validate) {
            return new Promise((resolve) => {
                this.customController.validate(resolve);
            });
        }

        return true;
    }

    setStepReference(reference: AtlasWizardStep) {
        this.stepReference = reference;
    }

    setCustomController(customController: BaseStepController) {
        this.customController = customController;
    }

    setState(keyOrState: string | { [key: string]: any }, value?: any) {
        if (typeof keyOrState === "object") {
            this.stepState = { ...this.stepState, ...keyOrState };
        } else {
            this.stepState[keyOrState] = value;
        }
    }

    getState() {
        return this.stepState;
    }

    clearState(key: string | string[]) {
        const keys = !Array.isArray(key) ? [key] : key;

        keys.forEach((stepKey: string) => {
            this.stepState[stepKey] = "";
        });
    }

    setElementToScroll(elementToScroll: string) {
        this.elementToScroll = elementToScroll;
    }

    getExtraButtons(): CustomStepButton[] {
        return this.customController?.getExtraButtons?.();
    }
}
