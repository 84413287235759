import { LitElement, html } from "lit";
import { customElement, property, queryAssignedElements, state } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";

import sidebarMenuStyles from "./atlas-sidebar-menu-item.scss";

import "@/components/layout/atlas-collapse/atlas-collapse";
import "@/components/structure/atlas-sidebar-menu/atlas-sidebar-menu";
import "@/components/display/atlas-badge/atlas-badge";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-tooltip/atlas-tooltip";

/**
 * @dependency atlas-collapse
 * @dependency atlas-sidebar-menu
 * @dependency atlas-badge
 * @dependency atlas-icon
 * @dependency atlas-tooltip
 *
 * @slot - Usado para incluir os itens filhos como um submenu
 *
 * @prop {string} owner - O valor definido no item pai. É atribuido automaticamente nos itens filhos
 * @prop {string} value - Valor do item. É passado para o evento de clique do item
 * @prop {string} icon - Ícone que aparece ao lado esquerdo do nome
 * @prop {string} text - O nome do menu
 * @prop {string} href - Link que será aberto ao clicar no item
 * @prop {number} badge-number - Número que irá aparecer ao lado do texto do item
 * @prop {boolean} active - Booleano que informa se o item está ativo ou não
 * @prop {boolean} nested - Booleano que informa se o item faz parte de um submenu
 * @prop {boolean} shrinked - Booleano que informa se o item está em um menu encolhido
 *
 * @event {CustomEvent} atlas-menu-item-click - Evento disparado quando o item é clicado
 * @event {CustomEvent} atlas-menu-item-collapse - Evento disparado quando o item é collapsado
 *
 */
@customElement("atlas-sidebar-menu-item")
export default class AtlasSidebarMenuItem extends LitElement {
    static styles = sidebarMenuStyles;

    @property({ type: String }) owner!: string;

    @property({ type: String }) value: string;

    @property({ type: String }) icon: string;

    @property({ type: String }) text: string;

    @property({ type: String }) href: string;

    @property({ type: Number, attribute: "badge-number" }) badgeNumber: number;

    @property({ type: Boolean, reflect: true }) active = false;

    @property({ type: Boolean }) nested = false;

    @property({ type: Boolean }) shrinked = false;

    @property({ type: Boolean, attribute: "is-new" }) isNew = false;

    @state() private collapsed = true;

    @state() private hasChildren = false;

    @state() private hasNewChildren = false;

    @queryAssignedElements({ selector: "atlas-sidebar-menu-item", flatten: true })
    private slottedChildren!: Array<HTMLElement>;

    setCollapsed(collapsed: boolean) {
        this.collapsed = collapsed;
    }

    onClickItem() {
        if (this.hasChildren) {
            this.collapsed = !this.collapsed;

            emit(this, "atlas-menu-item-collapse", {
                detail: {
                    item: this.value,
                    collapsed: this.collapsed
                }
            });
        } else {
            emit(this, "atlas-menu-item-click", {
                detail: {
                    item: this.value,
                    owner: this.owner
                }
            });
        }
    }

    @Watch("active", true)
    onChangeActive() {
        if (this.active) {
            if (this.hasChildren) {
                this.setCollapsed(false);
            } else if (this.owner) {
                const rootNode = this.assignedSlot.getRootNode() as ShadowRoot;
                const host = rootNode.host as AtlasSidebarMenuItem;

                host.setAttribute("active", "");
            }
        } else if (!this.active && this.hasChildren) {
            this.setCollapsed(true);

            this.slottedChildren.forEach((element: AtlasSidebarMenuItem) => {
                element.removeAttribute("active");
            });
        }
    }

    @Watch("isNew")
    async onChangeIsNew() {
        await this.updateComplete;

        this.hasNewChildren = false;

        this.slottedChildren.forEach((element) => {
            if (element.hasAttribute("is-new")) {
                this.hasNewChildren = true;
            }
        });
    }

    async onChangeChildrens() {
        await this.updateComplete;

        this.hasChildren = this.slottedChildren && this.slottedChildren.length > 0;

        this.slottedChildren.forEach((element) => {
            element.setAttribute("owner", this.value);
        });

        if (this.active && this.hasChildren) {
            this.setCollapsed(false);
        }
    }

    renderCollapseIcon() {
        return when(
            this.hasChildren,
            () => html`<atlas-icon name="chevron-down" size="2x" class="collapse-icon"></atlas-icon>`
        );
    }

    renderBadge() {
        return when(this.badgeNumber > 0 || this.isNew || this.hasNewChildren, () => {
            let badgeContent = "";

            if (!this.shrinked) {
                if (this.isNew || this.hasChildren) {
                    badgeContent = "Novo";
                } else if (this.badgeNumber > 0) {
                    badgeContent = `${this.badgeNumber}`;
                }
            }

            return html`<atlas-badge text="${badgeContent}"></atlas-badge>`;
        });
    }

    render() {
        const itemClass = {
            "sidebar-menu-item": true,
            "collapsed": this.collapsed,
            "active": this.active,
            "nested": this.nested,
            "shrinked": this.shrinked
        };

        return html`
            <div class="${classMap(itemClass)}">
                <a
                    href=${ifDefined(this.href ? this.href : undefined)}
                    class="item-button"
                    role="button"
                    data-atlas-tooltip="menu-item-tooltip"
                    @click=${this.onClickItem}
                >
                    <atlas-icon name="${this.icon}" size="3x"></atlas-icon>
                    <span class="item-text">${this.text}</span>
                    ${this.renderBadge()} ${this.renderCollapseIcon()}
                </a>
                <atlas-tooltip id="menu-item-tooltip" placement="right" trigger="hover" ?disabled=${!this.shrinked}>
                    ${this.text}
                </atlas-tooltip>
                <atlas-collapse ?show=${!this.collapsed}>
                    <atlas-sidebar-menu ?shrinked=${this.shrinked} nested>
                        <slot @slotchange=${this.onChangeChildrens}></slot>
                    </atlas-sidebar-menu>
                </atlas-collapse>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-sidebar-menu-item": AtlasSidebarMenuItem;
    }
}
