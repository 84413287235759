import { html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";

import DeviceController from "@/controllers/device-controller";

import OverlayElement from "@/components/display/overlay-element";
import styles from "./atlas-popover.scss";

import "@/components/display/atlas-icon/atlas-icon";

/**
 * Descrição do componente
 *
 * @prop {string} header - Título do popover
 * @prop {number} width - Largura do popover.
 * @prop {number} max-height - Altura máxima do popover
 * @prop {boolean} auto-close - Booleano que indica se o comportamento de fechamento automático está habilitado
 * @prop {"any" | "inside" | "outside"} auto-close-trigger - String que indica onde deve ser clicado para fechar o overlay (Apenas quando a flag de auto-close está habilitada)
 *
 * @tag atlas-popover
 */
@customElement("atlas-popover")
export default class AtlasPopover extends OverlayElement {
    static styles = styles;

    @property({ type: String }) header: string;

    @property({ type: Number }) width: number;

    @property({ type: Number, attribute: "max-height" }) maxHeight: number;

    @property({ type: Boolean, attribute: "auto-close" }) autoClose = true;

    @property({ type: String, attribute: "auto-close-trigger" }) autoCloseTrigger: "any" | "inside" | "outside" =
        "outside";

    @state() private hasImage = false;

    @state() private hasButtonLink = false;

    @query(".popover")
    private _popover?: HTMLElement;

    private _deviceController = new DeviceController(this);

    constructor() {
        super("popover", "absolute", "click", "top");

        this.onDocumentClick = this.onDocumentClick.bind(this);
    }

    /**
     * @override
     */
    getOverlayElement(): HTMLElement {
        return this._popover;
    }

    /**
     * @override
     */
    getPopperModifiers(): Array<object> {
        const modifiers = super.getPopperModifiers();

        return [...modifiers, { name: "arrow", options: { element: ".popover-arrow" } }];
    }

    /**
     * @override
     */
    onOpenOverlay() {
        document.addEventListener("click", this.onDocumentClick);

        this._triggerElement.setAttribute("active", "");
        this._triggerElement.setAttribute("aria-expanded", "true");
    }

    /**
     * @override
     */
    onCloseOverlay() {
        document.removeEventListener("click", this.onDocumentClick);

        this._triggerElement.removeAttribute("active");
        this._triggerElement.setAttribute("aria-expanded", "false");
    }

    onDocumentClick(event: PointerEvent) {
        event.preventDefault();

        if (!this.autoClose || !this.open) {
            return;
        }

        const composedPath = event.composedPath();
        const isToggleTarget = composedPath.includes(this._triggerElement);
        const isMenuTarget = composedPath.includes(this._popover);

        if (
            isToggleTarget ||
            (this.autoCloseTrigger === "inside" && !isMenuTarget) ||
            (this.autoCloseTrigger === "outside" && isMenuTarget)
        ) {
            return;
        }

        this.hide();
    }

    renderImage() {
        this.hasImage = true;
    }

    renderButtonLink() {
        this.hasButtonLink = true;
    }

    render() {
        const maxHeight = this.maxHeight > 180 ? `${this.maxHeight}px` : "none";
        const width = this.width > 0 ? `${this.width}px` : "max-content";

        const popoverClasses = {
            "popover": true,
            "popover-auto": true,
            "has-image": this.hasImage,
            "has-button-link": this.hasButtonLink,
            "mobile-popover": this._deviceController.isMobile,
            "fade": true,
            "show": this.open
        };

        const bodyClasses = {
            "popover-body": true,
            "has-title": this.header !== ""
        };

        const backdropClass = {
            "atlas-popover-backdrop": true,
            "show": this.open && this._deviceController.isMobile
        };

        return html`
            <div class="${classMap(popoverClasses)}" style="max-height: ${maxHeight}; width: ${width}">
                <div class="popover-arrow"></div>
                <button class="popover-close" @click=${this.hide}>
                    <atlas-icon name="x" size="2x"></atlas-icon>
                </button>
                <div class="popover-content">
                    <div class="popover-header" ?hidden=${!this.header}>${this.header}</div>
                    <div class="popover-image">
                        <slot name="image" @slotchange="${this.renderImage}"></slot>
                    </div>
                    <div class="${classMap(bodyClasses)}">
                        <slot></slot>

                        <div class="popover-button-link">
                            <slot name="button-link" @slotchange="${this.renderButtonLink}"></slot>
                        </div>
                    </div>
                </div>
            </div>
            <div class="${classMap(backdropClass)}"></div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-popover": AtlasPopover;
    }
}
