import { LitElement, html } from "lit";
import { customElement, property, queryAssignedElements } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";

import sidebarMenuStyles from "./atlas-sidebar-menu.scss";
import AtlasSidebarMenuItem from "@/components/structure/atlas-sidebar-menu-item/atlas-sidebar-menu-item";

/**
 * @slot - Usado para incluir os itens do menu
 *
 * @prop {boolean} nested - Booleano que informa se componente é um submenu
 * @prop {boolean} shrinked - Booleano que informa se menu está exibindo uma versão encolhida
 *
 * @event {CustomEvent} atlas-menu-item-change - Evento disparado quando um item do menu é selecionado
 *
 */
@customElement("atlas-sidebar-menu")
export default class AtlasSidebarMenu extends LitElement {
    static styles = sidebarMenuStyles;

    @property({ type: Boolean }) nested = false;

    @property({ type: Boolean }) shrinked = false;

    @queryAssignedElements({ selector: "atlas-sidebar-menu-item", flatten: true })
    private slottedItems: Array<HTMLElement>;

    constructor() {
        super();

        this.onItemClick = this.onItemClick.bind(this);
        this.onItemCollapse = this.onItemCollapse.bind(this);

        this.addEventListener("atlas-menu-item-click", this.onItemClick);
        this.addEventListener("atlas-menu-item-collapse", this.onItemCollapse);
    }

    @Watch("nested", true)
    @Watch("shrinked", true)
    applyPropsInChildren() {
        this.slottedItems.forEach((element) => {
            element.toggleAttribute("nested", this.nested);
            element.toggleAttribute("shrinked", this.shrinked);
        });
    }

    onItemClick(event: CustomEvent) {
        const { item, owner } = event.detail;

        this.slottedItems.forEach((element: AtlasSidebarMenuItem) => {
            element.toggleAttribute("active", [item, owner].includes(element.value));
        });

        if (!this.nested) {
            emit(this, "atlas-menu-item-change", {
                detail: { item, owner }
            });
        }
    }

    onItemCollapse(event: CustomEvent) {
        const { item } = event.detail;

        this.slottedItems.forEach((element: AtlasSidebarMenuItem) => {
            if (element.value !== item && !element.active) {
                element.setCollapsed(true);
            }
        });
    }

    render() {
        const menuClass = {
            "sidebar-menu": true,
            "nested": this.nested,
            "shrinked": this.shrinked
        };

        return html`
            <div class=${classMap(menuClass)} part="menu-container">
                <slot @slotchange=${this.applyPropsInChildren}></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-sidebar-menu": AtlasSidebarMenu;
    }
}
