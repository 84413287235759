import "./atlas-loading";

const LOADING_BACKDROP_CLASS = "atlas-loading-backdrop";
const LOADING_BACKDROP_SELECTOR = `.${LOADING_BACKDROP_CLASS}`;

function removeLoadingBackdrop() {
    const loadingBackdrop = document.querySelector(LOADING_BACKDROP_SELECTOR);
    loadingBackdrop.remove();
}

function closeLoading() {
    const loadingElement = document.querySelector("atlas-loading");
    loadingElement.destroy();

    removeLoadingBackdrop();
}

function showLoading(message: string) {
    if (document.querySelector(LOADING_BACKDROP_SELECTOR)) {
        document.querySelector("atlas-loading").setAttribute("message", message || "");
    } else {
        const loadingBackdrop = document.createElement("div");
        loadingBackdrop.classList.add(LOADING_BACKDROP_CLASS);

        const loadingElement = document.createElement("atlas-loading");
        loadingElement.message = message;

        loadingBackdrop.prepend(loadingElement);
        document.body.prepend(loadingBackdrop);
    }
}

export { showLoading, closeLoading };
